/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'common/api/types';

interface Props
  {
  value: string | undefined;
  eventChange: (key: number, value: string) => void;
  definition: APITypedAttribute | undefined;
  disabled?: boolean;
  required?: boolean;
  }

function SelectBox(props:Props)
  {
  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  const label = props.definition?.attribute.name ?? null;
  const attID = props.definition?.attribute.id ?? null;
  const values = props.definition?.attribute.values ?? null;
  return (!label || !attID || !values || !props.eventChange)
    ? null
    : <FloatingLabel key={`select_${attID}`} label={label} controlId={`${attID}`} className="d-block position-relative w-100">
        <Form.Select
          value={props.value || undefined}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.eventChange(attID, e.target.value)}
          disabled={props.disabled ?? false}
          required={props.required ?? false}
          isValid={props.value ? true : !props.required}
          isInvalid={props.required && !props.value ? true : false}>
          <option value="">{""}</option>
          {values.map((a) => <option key={a.name} value={a.value}>{a.name}</option>)}
          </Form.Select>
        <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
        <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Input</Form.Control.Feedback>
        </FloatingLabel>
  }

export default SelectBox;