/* Node Packages */
import React from 'react';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';

//////////////////////////////////////////
// LOCAL STORAGE OBJECT
//////////////////////////////////////////

export const AuthToken = Object.freeze(
  {
  client: axios.create({headers: { 'Content-Type': 'application/json' }, }),
  storageKey: 'jakeSharedPassword',
  // headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${AuthToken.use()}`},
  store: (value: string) =>
    {
    window.localStorage.setItem(AuthToken.storageKey, value);
    AuthToken.client.defaults.headers.common['Authorization'] = 'Bearer ' + value;
    },
  use: () => window.localStorage.getItem(AuthToken.storageKey) ?? null,
  clear: () =>
    {
    window.localStorage.removeItem(AuthToken.storageKey);
    delete AuthToken.client.defaults.headers.common['Authorization'];
    },
  });


//////////////////////////////////////////
// RE-USABLE METHOD: requestAPI
//////////////////////////////////////////

export const requestAPI = (config:AxiosRequestConfig): Promise<any | null> =>
  {
  let trace : string = `${config.method} ${config.url}`;

  const eventResponse = (response: AxiosResponse<any, any>) =>
    {
    trace = `${config.method} ${config.url} » ${response.status}: ${response.statusText} = {${Object.keys(response.data) as Array<keyof typeof response.data>}}`;
    return Promise.resolve(response?.data ?? null);
    }

  const eventError = (error:AxiosError<any, any>) =>
    {
    trace = `${config.method} ${config.url} » ${error.toJSON()}`;
    if (error.response && error.response.status === 401) AuthToken.clear();
    return Promise.reject(null);
    }

  const eventTrace = () =>
    {
    console.log(trace);
    }

  return AuthToken.client.request<any>(config).then(eventResponse).catch(eventError).finally(eventTrace);
  }


//////////////////////////////////////////
// RE-USABLE CUSTOM HOOK: useAxios
//////////////////////////////////////////

function useAxios (config?:AxiosRequestConfig)
  {
  const [response , setResponse] = React.useState<any>(null);
  const [error    , setError   ] = React.useState<boolean>(false);
  const [loading  , setLoading ] = React.useState<boolean>(false);
  const [trace    , setTrace   ] = React.useState<string>(`${config?.method} ${config?.url}`);

  React.useEffect(() => requestAPI(config), []);

  const eventResponse = (response: AxiosResponse<any, any>) =>
    {
    setResponse(response?.data ?? null);
    setTrace(`${response.config.method} ${response.config.url} » ${response.status}: ${response.statusText} = {${Object.keys(response.data) as Array<keyof typeof response.data>}}`)
    }

  const eventError = (error:AxiosError<any, any>) =>
    {
    if (error.response && error.response.status === 401) AuthToken.clear();
    setError(true);
    setTrace(`${error.name} ${error.message} » ${error.toJSON()}`);
    }

  const eventExitRequest = () =>
    {
    setLoading(false);
    (error) ? console.error(trace) : console.info(trace);
    }

  const requestAPI = (request?:AxiosRequestConfig) =>
    {
    if (request === undefined) return;
    setLoading(true);
    AuthToken.client.request<any>(request).then(eventResponse).catch(eventError).finally(eventExitRequest);
    }

  return [response, error, loading, requestAPI] as const;
  };

export default useAxios;

