/* NODE PACKAGES */
import React from 'react';
import { set } from 'lodash';
import {Button, ButtonGroup, ButtonToolbar, Dropdown, Stack, Form, InputGroup, DropdownDivider, DropdownHeader, Modal, Container, Row, Col, ListGroup, ListGroupItem} from 'react-bootstrap';
/* TYPES */
import { APIDictionary, APIAttribute, APIElementGroup, APISuccess, APIError} from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';
/* CUSTOM COMPONENTS */
import ChecklistDropdown, {ChecklistItem, ChecklistHeader} from "components/molecules/Menu/Checklist";
import Trash from 'components/atoms/Buttons/Trash';

///////////////////////////////////////
// API FOCII DATA TYPES
///////////////////////////////////////

export interface APIPolicyFilter
  {
  id: number;
  name: string;
  description: string;
  filter: (APIElementGroup | APIAttribute)[];
  }

export type GetPolicyFocii =
  {
  status: "success";
  data: APIPolicyFilter[];
  } | APIError

const FILTERS: APIPolicyFilter[] =
  [
  { id: 0, name: "Filter A", description: "Filter A description", filter: [], },
  { id: 1, name: "Filter B", description: "Filter B description", filter: [], },
  { id: 2, name: "Filter C", description: "Filter C description", filter: [], },
  { id: 3, name: "Filter D", description: "Filter D description", filter: [], },
  { id: 4, name: "Filter E", description: "Filter E description", filter: [], },
  ];

const RESPONSE: GetPolicyFocii =
  {
  status: "success",
  data: FILTERS,
  }


///////////////////////////////////////
// DATA COMPONENT
///////////////////////////////////////

export type FilterListStore =
  {
  dictionary: APIDictionary;
  data: APIPolicyFilter[] | null;
  getFilter: (id: number) => APIPolicyFilter | null;
  setFilter: (newFilter: APIPolicyFilter) => void;
  addFilter: (newFilter: APIPolicyFilter) => void;
  removeFilter: (id: number) => void;
  getID: () => number;
  setID: (id: number) => void;
  getConfiguration: (id: number) => (APIElementGroup | APIAttribute)[];
  setConfiguration: (newFilter: (APIElementGroup | APIAttribute)[]) => void;
  updateHiddenThings: (thing: APIElementGroup | APIAttribute, isHidden: boolean) => (APIAttribute | APIElementGroup)[];
  isThingHidden: (thing: APIElementGroup | APIAttribute) => boolean;
  }

interface FilterDataToolProps
  {
  dictionary: APIDictionary;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  updateHiddenGroupsAttributes: (newHidden: (APIElementGroup | APIAttribute)[]) => void;
  }

function useFilterList (props: FilterDataToolProps): FilterListStore
  {
  //const [response, error, loading, callAxios] = useAxios({ method: 'GET', url: '/api/focii', });
  const [response, updateResponse] = React.useState<GetPolicyFocii | null>(RESPONSE ?? {status: "error", data: null});
  const [data, updateData] = React.useState<APIPolicyFilter[] | null>(null);
  const [filterID, updateFilterID] = React.useState<number>(0);
  const [configuration, updateConfiguration] = React.useState<(APIElementGroup | APIAttribute)[]>([]);

  React.useEffect(() =>
    {
    if (response && response.status === "success") updateData(response.data);
    }, [response]); // updates data on API response changes

  React.useEffect(() =>
    {
    updateConfiguration(getConfiguration(filterID));
    }, [filterID]); // updates hiddenGroupsAttributes on ID changes

  // changes: hiddenGroupsAttributes
  React.useEffect(() =>
    {
    props.updateHiddenGroupsAttributes(configuration); // update policy editor state
    setFilter({id: filterID, filter: configuration}); // update filter list state
    }, [configuration]);

  // call fetch data routine and re-initialize response
  // const refresh = React.useCallback(() => callAxios(), []);

  // Returns current filter ID, or -1 if no filter is selected.
  function getID ()
    {
    return filterID ?? -1;
    }

  // Returns ID of the filter to set, or -1 to clear the current filter.
  function setID (id: number = -1)
    {
    updateFilterID(id);
    }

  /**
   * Returns the configuration associated with the specified ID, or an empty array if no filter is found.
   * @param id - The ID of the filter to retrieve. If not provided, defaults to -1.
   * @returns The filter associated with the specified ID, or an empty array if no filter is found.
   */
  function getConfiguration (id: number = -1)
    {
    return data?.[id]?.filter ?? [];
    }

  /**
   * Updates the list of hidden groups and attributes.
   * @param newFilter - The new list of groups and attributes to hide.
   */
  function setConfiguration (newFilter:(APIElementGroup | APIAttribute)[] = [])
    {
    updateConfiguration(newFilter);
    }

  // gets the currently selected filter
  function getFilter (id: number = filterID)
    {
    return data?.[id] ?? null;
    }

  /**
   * Updates the policy filter with the provided partial filter object.
   * If the filter ID is not found in the existing data, a new filter is added.
   * Otherwise, the existing filter is updated with the new values.
   * @param newValues - A partial object containing the updated filter values;
   * @required Partial<APIPolicyFilter> object must include ID
   */
  function setFilter(newValues: Partial<APIPolicyFilter>)
    {
    const filterIndex: number = data?.findIndex(filter => filter?.id === newValues?.id) ?? -1;

    if (filterIndex === -1)
      {
      if (newValues.id && newValues?.id >= 0) updateData([...data ?? [], newValues as APIPolicyFilter]);
      }
    else
      {
      const newData: APIPolicyFilter[] = [...data ?? []];
      newData[filterIndex] = {...newData[filterIndex], ...newValues};
      updateData(newData);
      }
    }

  // Adds a new filter to the data.
  function addFilter (newFilter: APIPolicyFilter)
    {
    updateData([...data ?? [], newFilter]);
    }

  // Removes the filter with the specified ID from the data.
  function removeFilter (filterID: number)
    {
    updateData(data?.filter(f => f.id !== filterID) ?? []);
    }

  /**
   * Updates the list of hidden groups and attributes.
   * @param thing - The group or attribute to update the hidden state for.
   * @param isHidden - Whether the group or attribute should be hidden or not.
   * @returns The updated list of hidden groups and attributes.
   */
  function updateHiddenThings(thing: APIElementGroup | APIAttribute, isHidden: boolean): (APIAttribute | APIElementGroup)[]
    {
    let newHiddenThings: (APIAttribute | APIElementGroup)[] = configuration.filter((hiddenThing: APIAttribute | APIElementGroup) => hiddenThing !== thing);
    if (isHidden) newHiddenThings.push(thing);
    return newHiddenThings;
    }

  /**
   * Checks if the given group or attribute is currently hidden.
   * @param thing - The group or attribute to check the hidden state for.
   * @returns `true` if the given group or attribute is currently hidden, `false` otherwise.
   */
  const isThingHidden: (thing: APIElementGroup | APIAttribute) => boolean = React.useCallback((thing: APIElementGroup | APIAttribute): boolean => configuration.some((hiddenThing: APIAttribute | APIElementGroup) => hiddenThing === thing), [configuration]);

  return {dictionary: props.dictionary, data, addFilter, removeFilter, getID, setID, getFilter, setFilter, getConfiguration, setConfiguration, updateHiddenThings, isThingHidden};
  }

///////////////////////////////////////
// MANAGE FILTERS MODAL
///////////////////////////////////////

const DFLT_ID: number = -1;
const DFLT_NAME: string = "";
const DFLT_DESCRIPTION: string = "";
const DFLT_CONFIGURATION: (APIElementGroup | APIAttribute)[] = [];
const DFLT_FILTER: APIPolicyFilter = {id: DFLT_ID, name: DFLT_NAME, description: DFLT_DESCRIPTION, filter: DFLT_CONFIGURATION};

interface ManageFiltersModalProps
  {
  show: boolean;
  store: FilterListStore;
  onHide?: (() => void) | undefined
  }

function ManageFiltersModal (props: ManageFiltersModalProps)
  {
  const [filterID, setFilterID] = React.useState<number>(DFLT_ID);
  const [filterSelection, setFilterSelection] = React.useState<APIPolicyFilter>(props.store.getFilter(filterID) ?? DFLT_FILTER);
  const [dirty, setDirty] = React.useState<boolean>(false);

  React.useEffect(() =>
    {
    setFilterID(filterSelection?.id ?? -1);
    }, [filterSelection]);

  function eventSelect (filterID: number)
    {
    setFilterSelection(props.store.getFilter(filterID) ?? DFLT_FILTER);
    }

  function eventUpdateName (event: React.ChangeEvent<HTMLInputElement>)
    {
    setFilterSelection({...filterSelection, name: event.target.value});
    setDirty(true);
    }

  function eventUpdateDescription (event: React.ChangeEvent<HTMLInputElement>)
    {
    setFilterSelection({...filterSelection, description: event.target.value});
    setDirty(true);
    }

  function eventAddFocus ()
    {
    const newFilterID = props.store.data?.length ?? 0;
    const newFilter = {id: newFilterID, name: `Focus ${newFilterID}`, description: "", filter: []};
    props.store.addFilter(newFilter);
    setFilterSelection(newFilter);
    }

  // pre-calculated renders
  const cssFlexRow = "d-flex flex-row justify-content-between align-items-center gap-2";
  //const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return (<Modal show={props.show} onHide={props.onHide} centered={true} size="xl" scrollable={true}>
    <Modal.Header closeButton>
      <Modal.Title>Manage Focii</Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <Container className="m-0 p-0 border-0">
        <Row>
          <Col>
            <ListGroup className="overflow-y-scroll" style={{maxHeight: "400px"}}>
              {props.store.data?.map((filter: APIPolicyFilter) => <ListGroupItem key={`filterID-${filter.id}`} eventKey={filter.id} variant="light" action={true} active={filterID === filter?.id} onClick={() => eventSelect(filter.id)} className={cssFlexRow}><small>{filter.name}</small></ListGroupItem>)}
              </ListGroup>
            </Col>
          <Col>
            {((filterSelection && filterSelection?.id !== -1)
              ? <Container id="selected-focus">
                  <Row>
                    <Col className={cssFlexRow}><Form.Control type="text" placeholder={"Focus Name"} value={filterSelection?.name ?? ""} onChange={eventUpdateName} /></Col>
                    </Row>
                  <Row className="my-3">
                    <Col><Form.Control as="textarea" rows={4} placeholder={"Focus Description"} value={filterSelection?.description ?? ""} onChange={eventUpdateDescription} /></Col>
                    </Row>
                  <Row>
                    <Col align="start"><Button disabled={!dirty} variant="outline-dark" className="m-0 px-2 py-0" size="sm" onClick={() => {props.store.setFilter(filterSelection); setDirty(false);}}>{"Save Changes"}</Button></Col>
                    <Col align="end"><Trash text={"Remove Focus"} onClick={() => props.store.removeFilter(filterID)} /></Col>
                    </Row>
                  </Container>
              : <Container id="default">
                  <Row>
                    <Col className="text-center">
                      <h3><span onClick={() => eventAddFocus()} className="bg-info bg-opacity-50 pointer m-0 px-2 py-1">Create a new focus filter</span></h3>
                      <h3 className="text-muted">- or -</h3>
                      <h3>Select an existing focus filter from the scrollable list to the left for editing.</h3>
                      </Col>
                    </Row>
                  </Container>)}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    <Modal.Footer>
      <Container>
        <Row>
          <Col align="end">
            <ButtonToolbar className={cssFlexRow}>
              <Button variant="outline-dark" onClick={() => eventAddFocus()}><i className="bi bi-plus-lg"></i> {"New Focus"}</Button>
              <Button variant="outline-dark" onClick={props.onHide}>{"Done"}</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>);
  }

///////////////////////////////////////
// FILTER OPTIONS MENU
///////////////////////////////////////

interface FilterOptionsMenuProps
  {
  store: FilterListStore;
  }

interface FilterOptionsMenuItem
  {
  key: string;
  label: string;
  state: boolean;
  action: () => void;
  }

function FilterOptionsMenu (props: FilterOptionsMenuProps)
  {
  const [showManageFiltersModal, setShowManageFiltersModal] = React.useState(false);

  const handleShowManageFiltersModal = () => setShowManageFiltersModal(true);
  const handleCloseManageFiltersModal = () => setShowManageFiltersModal(false);

  const filterColumnsCheckboxes: FilterOptionsMenuItem[] = props.store.dictionary.rule_attributes.map((att: APIAttribute) =>
    ({
    key    : `attFilter${att.id}`,
    label  : att.name,
    state  : !props.store.isThingHidden(att),
    action : () => props.store.setConfiguration(props.store.updateHiddenThings(att, !props.store.isThingHidden(att)))
    }));

  const filterRowCheckboxes: FilterOptionsMenuItem[] = props.store.dictionary.element_groups.map((eg: APIElementGroup) =>
    ({
    key    : `attFilter${eg.id}`,
    label  : eg.name,
    state  : !props.store.isThingHidden(eg),
    action : () => props.store.setConfiguration(props.store.updateHiddenThings(eg, !props.store.isThingHidden(eg)))
    }));

  return (<React.Fragment>
    <ChecklistDropdown title="Filter" tooltip='Filter the data to only include the selected columns, rows, and comparisons'>
      <Dropdown.Item eventKey={"filterModal"} onClick={handleShowManageFiltersModal}>{"Manage Focii"}</Dropdown.Item>
      <DropdownDivider />
      <ChecklistHeader>{"Configure Filter Columns"}</ChecklistHeader>
      {filterColumnsCheckboxes.map((item, index) => <ChecklistItem key={item.key} eventKey={item.key} checked={item.state} text={item.label} onChange={item.action} />)}
      <ChecklistHeader>{"Configure Filter Rows"}</ChecklistHeader>
      {filterRowCheckboxes.map((item, index) => <ChecklistItem key={item.key} eventKey={item.key} checked={item.state} text={item.label} onChange={item.action} />)}
      </ChecklistDropdown>
    <ManageFiltersModal show={showManageFiltersModal} store={props.store} onHide={handleCloseManageFiltersModal} />
    </React.Fragment>);
  };

///////////////////////////////////////
// FILTER SELECT INPUT
///////////////////////////////////////

interface SelectFilterProps
  {
  store: FilterListStore;
  }

function SelectFilter (props: SelectFilterProps)
  {
  return <Form.Select value={props.store.getID()} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.store.setID(parseInt(e.target.value))}>
    <option value={-1}>{"Choose a filter..."}</option>
    {props.store.data?.map((filter: APIPolicyFilter) => <option value={filter.id} key={`filterID-${filter.id}`}>{filter.name}</option>)}
    </Form.Select>
  }

///////////////////////////////////////
// DATA FILTERS TOOLBAR
///////////////////////////////////////

interface FilterDataToolProps
  {
  dictionary: APIDictionary;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  updateHiddenGroupsAttributes: (newHidden: (APIElementGroup | APIAttribute)[]) => void;
  }

function FilterDataTool (props: FilterDataToolProps)
  {
  const filterListStore: FilterListStore = useFilterList(props);

  return <InputGroup>
    <FilterOptionsMenu store={filterListStore} />
    <SelectFilter store={filterListStore} />
    </InputGroup>
  };

export default FilterDataTool;