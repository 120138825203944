/* NODE PACKAGES */
import React from'react';

const cssPrint = "d-none d-print-block m-0 p-0";

function Print (props: {children?: React.ReactNode})
  {
  return <div className={cssPrint}>{props.children}</div>
  }

export default Print;
