/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Card, Container, Row, Col, Stack, Form, InputGroup, Button, ToggleButtonGroup, ToggleButton, ListGroup, ListGroupItem} from 'react-bootstrap';
/* API */
import { APIAttribute, APIDictionary, APIElement, APIElementGroup, APIPolicy, APIRule, APIRuleAttribute, APIRegistrar, APIRequestTemplate, APIRegistryList, PolicyListItem, APIRequester, APIRegistrarGroup, APIRequesterGroup, APIRegistration} from 'common/api/types';
import {createNewRegistration, createNewRequestTemplate, createNewRequester, createRequesterGroup, createNewRegistrarGroup, createPolicy, createRegistryList} from 'common/api/requests';
// COMMON
import { redirect } from "common/utility/window";
import { timeDifference } from "common/utility/time";
// HOOKS
import {useAxios, RegistrarStore, useRegistrarGroupMembersStore, useRegistrationListStore, RegistrationListStore, useRequestTemplateListStore, useRegistryListStore, usePolicyListStore, PolicyListStore} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, Footer, Metadata} from "components/atoms/Templates";

//////////////////////////////////////////
// HERO
//////////////////////////////////////////

const cssHeaderBlock = "d-block text-center mx-auto my-0 p-0";
const cssHeaderTitle = "fw-normal mx-auto my-3 p-0";
const cssHeaderSubtitle = "fst-italic fw-light text-dark text-opacity-75 mx-auto my-3 p-0";

function DashboardHero ()
  {
  return <div className={cssHeaderBlock}>
    <h2 className={cssHeaderTitle}>Project Jake: Rethinking Domain Registration Data</h2>
    <h4 className={cssHeaderSubtitle}>Developing voluntary technical solutions to complex data-related public policy challenges.</h4>
    </div>
  }

//////////////////////////////////////////
// SUB-COMPONENTS
//////////////////////////////////////////

const cssCard = "d-block position-relative w-100 m-0 p-0 pt-4 mb-4 border border-2 border-secondary bg-light shadow-sm fs-5 lh-base";
const cssIcon = "position-absolute top-0 start-50 translate-middle text-nowrap m-0 p-3 border border-2 border-secondary rounded-circle bg-light shadow-sm text-secondary fs-3 lh-1 fw-light text-center";
const cssCardBody = "m-0 px-3 py-4 text-center";
const cssCardFooter = "d-flex flex-row justify-content-between align-items-center gap-2 border-0";

function CollectionPolicyInfo ()
  {
  const policyData = usePolicyListStore();
  return (
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-diagram-3"></i></span>
        <Card.Title>Collection Policies</Card.Title>
        <Card.Subtitle> Create, view, and configure request forms to be submitted to a registrar. </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/policies/">Index List {`(${policyData.count})`}</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#policy.md">Manual: Policy Editor</Card.Link>
        </Card.Footer>
      </Card>
    );
  }

function RequestTemplateInfo ()
  {
  const requestTemplates = useRequestTemplateListStore();
  return (
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-pencil-square"></i></span>
        <Card.Title>Request Templates</Card.Title>
        <Card.Subtitle> Configure request templates ({requestTemplates.count}) to be submitted to a registrar. </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/requests/">Index List</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#requests.md">Manual: Template Editor</Card.Link>
        </Card.Footer>
      </Card>
    );
  }

function RegistrationsInfo ()
  {
  const registrations = useRegistrationListStore();
  return (
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-list-check"></i></span>
        <Card.Title>Registrations</Card.Title>
        <Card.Subtitle> View registrations ({registrations.count}). Create and configure registrant data. Filter registrant data using collection policies. </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/registrations/">Index List</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registration.md">Manual: Registration Editor</Card.Link>
        </Card.Footer>
      </Card>
    );
  }

function RegistriesInfo ()
  {
  const registryList = useRegistryListStore();
  return (
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-globe"></i></span>
        <Card.Title>Registry Lists</Card.Title>
        <Card.Subtitle> Create, view and configure sets of Registry Lists ({registryList.count}). </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/registries/">Index List</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#psl.md">Manual: Registry List Editor</Card.Link>
        </Card.Footer>
      </Card>
    );
  }

function RequestersInfo ()
  {
  const registrarGroupMembers = useRegistrarGroupMembersStore();
  return (<>
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-card-list"></i></span>
        <Card.Title>Requesters</Card.Title>
        <Card.Subtitle> Create requesters ({registrarGroupMembers.requesterCount}). Organize requester groups ({registrarGroupMembers.requesterGroupCount}). </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/registrars/">Index List</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Manual: Registrar Editors</Card.Link>
        </Card.Footer>
      </Card>
    </>);
  }

function RegistrarsInfo ()
  {
  const registrarGroupMembers = useRegistrarGroupMembersStore();
  return (<>
    <Card className={cssCard}>
      <Card.Body className={cssCardBody}>
        <span className={cssIcon}><i className="bi bi-card-list"></i></span>
        <Card.Title>Registrars</Card.Title>
        <Card.Subtitle> Create registrars ({registrarGroupMembers.registrarCount}). Organize registrar groups ({registrarGroupMembers.registrarGroupCount}). </Card.Subtitle>
        </Card.Body>
      <Card.Footer className={cssCardFooter}>
        <Card.Link className="link-dark" href="#/registrars/">Index List</Card.Link>
        <Card.Link className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Manual: Registrar Editors</Card.Link>
        </Card.Footer>
      </Card>
    </>);
  }

function Dashboard ()
  {
  return (
    <Page>
      <Header>
        <DashboardHero />
        </Header>
      <Main>
        <Section>
          <Row xs={1} sm={1} md={2} xl={2} xxl={3} className="g-4">
            <Col><CollectionPolicyInfo /></Col>
            <Col><RequestTemplateInfo /></Col>
            <Col><RegistrationsInfo /></Col>
            <Col><RegistriesInfo /></Col>
            <Col><RequestersInfo /></Col>
            <Col><RegistrarsInfo /></Col>
            </Row>
          </Section>
        </Main>
      <Footer />
      </Page>
    );
  }

export default Dashboard;

/*
<Section>
  <Container className="m-0 p-0">
    <Row xs={1} md={3} className="g-4">
      <Col><Button variant="outline-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#about.md" className="d-flex flex-column justify-content-start align-items-center gap-2 m-0 p-3"><i className="fs-2 lh-1 bi bi-info-circle"></i> About</Button></Col>
      <Col><Button variant="outline-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/" className="d-flex flex-column justify-content-start align-items-center gap-2 m-0 p-3"><i className="fs-2 lh-1 bi bi-book"></i> Manual</Button></Col>
      <Col><Button variant="outline-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#dashboard.md" className="d-flex flex-column justify-content-start align-items-center gap-2 m-0 p-3"><i className="fs-2 lh-1 bi bi-question-circle"></i> Dashboard</Button></Col>
      </Row>
    </Container>
  </Section>
<Section>
  <Container className="m-0 p-0">
    <Row className="g-2">
      <Col>
        <ListGroup variant="flush">
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/">Help Guide: Browse help topics from the User Guide</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#about.md">About Project Jake</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#dashboard.md">Dashboard</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#policy.md">Policy Editor</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#requests.md">Request Templates Editor</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registration.md">Registration Editor</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Registrar Editors</ListGroup.Item>
          <ListGroup.Item variant="light" action={true} target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#psl.md">Registry List Editor</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </Section>
*/