/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { APIRequester, APIRequesterGroup, APIRegistrar, APIRegistrarGroup, APIRegistrarGroupMembers} from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';

const GetRegisrarGroupMembers: AxiosRequestConfig =
  {
  method: 'GET',
  url: '/api/registrar_group_member_query_all',
  };

export type RegistrarStore =
  {
  loading: boolean,
  error: boolean,
  requesters: APIRequester[],
  requesterCount: number,
  requesterGroups: APIRequesterGroup[],
  requesterGroupCount: number,
  registrars: APIRegistrar[],
  registrarCount: number,
  registrarGroups: APIRegistrarGroup[],
  registrarGroupCount: number,
  refresh: () => void
  getRequesterGroup(rqID: number): APIRequesterGroup;
  };

function useRegistrarGroupMembersStore(): RegistrarStore
  {
  const [requestConfig, setRequestConfig] = React.useState<AxiosRequestConfig>(GetRegisrarGroupMembers);
  const [data, error, loading, callAxios] = useAxios(requestConfig);
  const [requesters, setRequesters] = React.useState<APIRequester[]>([]);
  const [requesterGroups, setRequesterGroups] = React.useState<APIRequesterGroup[]>([]);
  const [registrars, setRegistrars] = React.useState<APIRegistrar[]>([]);
  const [registrarGroups, setRegistrarGroups] = React.useState<APIRegistrarGroup[]>([]);

  React.useEffect(() =>
    {
    if (!data) return;
    setRequesters(data.registrar_group_members.requesters.sort((a:APIRequester, b:APIRequester) => a.name.localeCompare(b.name)));
    setRequesterGroups(data.registrar_group_members.requester_groups.sort((a:APIRequesterGroup, b:APIRequesterGroup) => a.name.localeCompare(b.name)));
    setRegistrars(data.registrar_group_members.requesters.sort((a:APIRegistrar, b:APIRegistrar) => a.name.localeCompare(b.name)));
    setRegistrarGroups(data.registrar_group_members.registrar_groups.sort((a:APIRegistrarGroup, b:APIRegistrarGroup) => a.description.localeCompare(b.description)));
    }, [data]);

  const refresh = () => callAxios();

  function getRequesterGroup (rqID: number)
    {
    return requesterGroups.filter((rqG:APIRequesterGroup) => rqG.id === rqID)[0] ?? {};
    }

  return {requesters, requesterCount: (requesters?.length ?? 0), requesterGroups, requesterGroupCount: (requesterGroups?.length ?? 0), registrars, registrarCount: (registrars?.length ?? 0), registrarGroups, registrarGroupCount: (registrarGroups?.length ?? 0), error, loading, refresh, getRequesterGroup};
  }

export default useRegistrarGroupMembersStore;
