/* NODE PACKAGES */
import React from "react";
import {Spinner, Alert, Container, Form, Stack, FloatingLabel, ButtonGroup, Button, ListGroup} from 'react-bootstrap';
import { all } from "axios";
/* API */
import { APIRequester, APIRequesterGroup, APIRegistrar, APIRequestTemplate, APIRegistrarGroup} from "common/api/types";
import {saveRqG, deleteRqG, leaveRqG, getRegistrarGroups} from "common/api/requests";
/* HOOKS */
import {RequesterGroupStore, useRequesterGroupEditor} from "hooks";
/* UTILITY */
import { redirect } from "common/utility/window";
/* CUSTOM COMPONENTS */
import GroupTable, {TableRowData} from "pages/Registrars/modules/GroupTable";
import GroupCreator from "pages/Registrars/modules/GroupCreator";
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import FileMenu, {FileMenuDivider, FileMenuHelp, FileMenuSave, FileMenuDelete} from 'components/molecules/Menu/File';
import {Page, Header, Main, Section, FlexBox, Footer, Metadata} from "components/atoms/Templates";

//////////////////////////////////////////////////////////
// CCONSTANTS
//////////////////////////////////////////////////////////

const currentObjType = "RqG";
const emptyReqGroup: APIRequesterGroup = {id: 0, name: "", obligations: "", members: []};

//////////////////////////////////////////////////////////
// Requester List Selector
//////////////////////////////////////////////////////////

export const RequesterListSelector: React.FC<{ rqGID: number; members: APIRequester[]; onSelect: (requester: APIRequester) => void; }> = (props) =>
  {
  return ((props.members?.length === 0)
    ? null
    : <ListGroup className="d-print-none d-block w-100 m-0 p-0">
        {props.members?.map(req => <ListGroup.Item key={`req-${req.id}`} className="d-flex justify-content-between align-items-center gap-2 m-0 px-2 py-2"><span>{req.name}</span> <span className="btn btn-sm btn-outline-danger m-0 ms-auto p-1 lh-1" onClick={() => props.onSelect(req)}><i className="bi bi-trash"></i></span></ListGroup.Item>) ?? <ListGroup.Item>No Requesters</ListGroup.Item>}
        </ListGroup>)
  }

//////////////////////////////////////////////////////////
// Requester Group Editor
//////////////////////////////////////////////////////////

interface RequesterGroupEditorProps
  {
  selectedRqGID: number;
  unsavedChanges: (flag: boolean) => void;
  }

function RequesterGroupEditor (props: RequesterGroupEditorProps)
  {
  const reqGroupEditor: RequesterGroupStore = useRequesterGroupEditor({selectedID: props.selectedRqGID});
  const [showGroupCreator, setShowGroupCreator] = React.useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(unsavedChanges);
    }, [unsavedChanges]);

  // EVENT HANDLERS

  const eventShowGroupCreator = () => setShowGroupCreator(true);
  const eventHideGroupCreator = () => setShowGroupCreator(false);

  const eventSave = () =>
    {
    if (reqGroupEditor.requesterGroup.id) saveRqG(reqGroupEditor.requesterGroup).then((response:boolean) => reqGroupEditor.refresh()).finally(() => setUnsavedChanges(false));
    }

  const eventDelete = () =>
    {
    if (reqGroupEditor && window.confirm('Are you sure you wish to delete this item?')) deleteRqG(reqGroupEditor.requesterGroup.id).then(() => redirect('/registrars/'));
    };

  const eventRemoveMember = (requester: APIRequester) =>
    {
    if (reqGroupEditor.requesterGroup && requester) leaveRqG (requester.id, reqGroupEditor.requesterGroup.id).then((response:boolean) => reqGroupEditor.refresh()).finally(() => setUnsavedChanges(true));
    }

  const eventEditTitle = (newTitle: string) =>
    {
    reqGroupEditor.updateTitle(newTitle);
    setUnsavedChanges(true);
    };

  const eventEditObligations = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    {
    reqGroupEditor.updateObligations(e.target.value);
    setUnsavedChanges(true);
    }

  const eventCreateSuccess = () =>
    {
    reqGroupEditor.refresh();
    setShowGroupCreator(false);
    setUnsavedChanges(true);
    }

  // RENDER
  return ((!reqGroupEditor.requesterGroup)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <EditableLabel value={reqGroupEditor.requesterGroup.name ?? ""} onValueChange={eventEditTitle} />
          <div className="ms-auto"></div>
          <Button variant="outline-dark" onClick={eventShowGroupCreator}><i className="bi bi-plus-lg"></i> Subscription</Button>
          <FileMenu dirtyFlag={unsavedChanges}>
            <FileMenuSave onClick={eventSave} />
            <FileMenuDelete onClick={eventDelete} />
            <FileMenuDivider />
            <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#registrars.md" />
            </FileMenu>
          </Header>
        <Main>
          <Section>
            <FlexBox direction="column">
              <h4 className="d-block mx-auto my-0 p-0 text-center fw-normal lh-1">{reqGroupEditor.TableData.length} Subscription(s)</h4>
              <GroupTable currentObjID={reqGroupEditor.requesterGroup.id} currentObjType={currentObjType} tableData={reqGroupEditor.TableData} />
              </FlexBox>
            </Section>
          <Section>
            <FlexBox direction="column">
              <h4 className="d-block mx-auto my-0 p-0 text-center fw-normal lh-1">{reqGroupEditor.requesterGroup.members?.length} Membership(s)</h4>
              <RequesterListSelector rqGID={reqGroupEditor.requesterGroup.id} members={reqGroupEditor.requesterGroup.members} onSelect={eventRemoveMember} />
              </FlexBox>
            </Section>
          <Section>
            <FlexBox direction="column">
              <h4 className="d-block mx-auto my-0 p-0 text-center fw-normal lh-1">Documentation</h4>
              <FloatingLabel controlId="obligationsDocument" label="Obligations Document:">
                <Form.Control as="textarea" style={{ height: '200px' }} placeholder="Leave a comment here..." value={reqGroupEditor.requesterGroup.obligations ?? ""} onChange={eventEditObligations} />
                </FloatingLabel>
              </FlexBox>
            </Section>
          </Main>
        <Footer />
        <GroupCreator
          showModal={showGroupCreator && Object.keys(reqGroupEditor.requesterGroup).length > 0}
          onCreateSuccess={eventCreateSuccess}
          onCloseModal={eventHideGroupCreator}
          currentObjID={reqGroupEditor.requesterGroup.id}
          currentObjType={currentObjType}
          requesterGroups={reqGroupEditor.requesterGroups ?? []}
          registrars={reqGroupEditor.registrars ?? []}
          requestTemplates={reqGroupEditor.requestTemplates ?? []} />
        </Page>);
  }

export default RequesterGroupEditor;

/* Development Notes:



*/
