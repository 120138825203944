/* NODE PACKAGES */
import React from 'react';
import _ from 'lodash';
import {Alert, Button, Card, Accordion, Table, ButtonGroup} from 'react-bootstrap';
/* TYPES */
import {APIDictionary, APIRequestTemplate, APIMatchedRegistration, APIPolicy, APIRegistrantFacts, APIRegistration, APIRegistrantFactItem, APIRule} from 'common/api/types';
import {executeRequestQuery, saveRequestTemplate, deleteRequestTemplate} from 'common/api/requests';
/* UTILITY */
import {TemplateAttributes, useApplicationContext, AppContextType} from 'hooks';
import {getPolicy, getRegistrantFacts, getAttributeFromRule, getRuleForElement} from 'common/api/requests';

///////////////////////////////////////
// CONSTANTS & HELPERS
///////////////////////////////////////

const SearchFields = new Map<string, number[]>([['domain', [1]], ['contact', [27, 42, 56, 70]], ['email', [24, 38, 53, 67]]]);

///////////////////////////////////////
// RESULTS TABLE (REQUEST FORMS)
///////////////////////////////////////

interface ResultsTableProps
  {
  regFact: APIRegistrantFacts;
  policy: APIPolicy;
  registration: APIRegistration;
  matchedElIds: Set<number>;
  getField: (id: number) => string | undefined;
  }

function RequestResultsTable (props:ResultsTableProps)
  {
  const appContext: AppContextType = useApplicationContext();

  return (!props || !appContext)
    ? null
    : <Table size='sm' style={{font:'normal 14px/1.3em monospace'}}>
      <thead>
        <tr>
        <th>Group</th>
        <th>Element Name</th>
        <th>Element Value</th>
        <th>Validation</th>
        <th>Sensitivity</th>
        </tr>
        </thead>
      <tbody>
        {
        appContext.dictionary.data?.element_groups.map((eg) => eg.elements.map((el, i) =>
          {
          let fact = props.regFact.facts.find((f) => f.element_id === el.id);
          let coll: number = 0;
          let validation: string = "";
          let V3RQ: number = 0;
          let sensitivity: string = "";
          let DG: number = 0;
          let reg_sens_id: number = 0;
          let templ_sens_id: number = 0;

          if (props.policy)
            {
            const rule = getRuleForElement(props.policy, el.id);
            if (rule)
              {
              coll          = appContext.dictionary.getRuleAttributeOption(1, rule)?.value || 0;
              validation    = appContext.dictionary.getRuleAttributeOption(2, rule)?.name  || "";
              V3RQ          = appContext.dictionary.getRuleAttributeOption(3, rule)?.value || 0;
              sensitivity   = appContext.dictionary.getRuleAttributeOption(4, rule)?.name  || "";
              reg_sens_id   = appContext.dictionary.getRuleAttributeOption(4, rule)?.value ?? 0;
              DG            = appContext.dictionary.getRuleAttributeOption(5, rule)?.value || 0;
              reg_sens_id   = fact?.DG === "DG please" && DG === 2 ? 2 : reg_sens_id;
              templ_sens_id = parseInt(props.getField(15) || "0");
              }
            }

          let groupCell: React.ReactNode = null;
          if (i === 0) groupCell = <td key={`groupcell-${eg.id}`} rowSpan={eg.elements.length}>{eg.name}</td>;

          const cssMatchedTLD = { backgroundColor: props.matchedElIds.has(el.id) ? "#afa" : "none", }
          return (templ_sens_id >= reg_sens_id)
            ? <tr key={`resultsRow-${el.name}-${el.id}`}>
              {groupCell}
              <td style={cssMatchedTLD}> {el.name}</td>
              <td style={cssMatchedTLD}> {filterFact(fact, coll)} </td>
              <td> {fact?.V3RQ === "V3 please" && V3RQ === 2 ? "V3" : validation} </td>
              <td> {fact?.DG === "DG please" && DG === 2 ? "S1" : sensitivity} </td>
              </tr>
            : <tr key={`emptyRow-${el.name}-${el.id}`}>
              {groupCell}
              <td>{el.name}</td>
              <td></td>
              <td></td>
              <td></td>
              </tr>
          }))
        }
        </tbody>
      </Table>

  /**
   * Filters the fact value based on the given attribute value; Returns the fact value, empty string, or "ø" placeholder depending on attribute value;
   * TODO: build other cases
   * 0: {value: 0, name: 'ø'}
   * 1: {value: 1, name: 'Collect'}
   * 2: {value: 2, name: "Don't Collect"}
   * 3: {value: 3, name: "Coll or Don't"}
   * 4: {value: 4, name: 'Optional'}
   * 5: {value: 5, name: 'Coll or Opt'}
   * 6: {value: 6, name: "Opt or Don't"}
   * 7: {value: 7, name: 'Any'}
   */
  function filterFact(fact: APIRegistrantFactItem | undefined, attributeVal: number)
    {
    if (!fact) return "";
    switch (attributeVal)
      {
      case 0: return "ø";
      case 1: return fact.value;
      case 2: return "";
      default: return "";
      }
    }
  }

///////////////////////////////////////
// CUSTOM HOOK - Request Data Store
///////////////////////////////////////

interface RequestProps
  {
  getField: (id: number) => string | undefined;
  }

function useRequest (props: RequestProps)
  {
  // STATES:

  const [registrations, setRegistrations] = React.useState<APIMatchedRegistration[] | null>(null);
  const [registrantFactsList, setRegistrantFactsList] = React.useState<APIRegistrantFacts[] | null>(null);
  const [policies, setPolicies] = React.useState<APIPolicy[] | null>(null);
  const appContext: AppContextType = useApplicationContext();

  // EFFECTS:

  React.useEffect(() =>
    {
    initializeRequest();
    }, []);

  React.useEffect(() =>
    {
    if (!registrations) return;
    Promise.all(registrations.map(reg => getPolicy(reg.registration.policy_id)) || []).then(list => setPolicies(list.filter(p => p !== null) as APIPolicy[]));
    Promise.all(registrations.map(reg => getRegistrantFacts(reg.registration.registrant_facts_id)) || []).then(setRegistrantFactsList);
    }, [registrations]);

  // MEMOS:

  const isMissingData = React.useMemo (() => Boolean(!registrations || !registrantFactsList || !policies), [registrations, registrantFactsList, policies]);

  // HELPERS:

  function initializeRequest ()
    {
    setRegistrations(null);
    setRegistrantFactsList(null);
    setPolicies(null);
    }

  // CALLBACKS:

  const executeRequest = () =>
    {
    initializeRequest();
    executeRequestQuery(props.getField(TemplateAttributes.UI_Domain) ?? "", props.getField(TemplateAttributes.UI_Contact) ?? "", props.getField(TemplateAttributes.UI_Email) ?? "").then(setRegistrations);
    }

  // EXPORTS

  return {hasData: !isMissingData, initialize: initializeRequest, execute: executeRequest, registrationList: registrations, factsList: registrantFactsList, policyList: policies};
  }

export default useRequest;

///////////////////////////////////////
// RESULTS - SCREEN UI
///////////////////////////////////////

interface ResultsProps
  {
  registrationList: APIMatchedRegistration[] | null;
  factsList: APIRegistrantFacts[] | null;
  policyList: APIPolicy[] | null;
  hasData: boolean;
  execute: () => void;
  initialize: () => void;
  getField: (id: number) => string | undefined;
  }

export function ResultsUI (props: ResultsProps)
  {
  const resultsList = React.useMemo(() => props.registrationList?.filter(reg => reg !== undefined).map((reg, i) =>
    {
    let matchedElIds = new Set(reg.matches);
    const key = `results_${reg.registration.id}${i}`;
    const regFact = props.factsList?.at(i);
    const policy = props.policyList?.at(i);
    return (!regFact || !policy) ? null : <Accordion.Item key={key} eventKey={`${i}`}>
      <Accordion.Header>{reg.registration.name} — Match: {['domain', 'contact', 'email'].filter(field => SearchFields.get(field)?.some(id => matchedElIds.has(id))).join(",")}</Accordion.Header>
      <Accordion.Body>
        <Button variant="success" href={`/#/registration/${reg.registration.id}`} className="mb-3">Go To Registration</Button>
        <RequestResultsTable getField={props.getField} regFact={regFact} policy={policy} registration={reg.registration} matchedElIds={matchedElIds} />
        </Accordion.Body>
      </Accordion.Item>
    }).filter(item => item !== null), [props.registrationList, props.factsList, props.policyList, props.getField]);

  // RENDER:

  const cssCardHeader = "bg-dark text-light";
  const cssCardFooter = "d-flex justify-content-between align-items-center gap-2 bg-transparent border-light m-0 p-2";
  const cssCardButtons = "d-block border-0 shadow-sm";
  return (!props.registrationList || !props.factsList || !props.policyList) ? <div><Button className={cssCardButtons} disabled={props.hasData} variant="dark" onClick={props.execute}>Execute</Button></div> :
    <div className="d-flex flex-column gap-2">
      <h4 className="mb-2">Results {`(${resultsList?.length ?? 0})`}</h4>
      <Accordion alwaysOpen>{resultsList}</Accordion>
      <div className={cssCardFooter}>
        <Button className={cssCardButtons} variant="dark" onClick={props.execute}>Execute</Button>
        <div className="ms-auto"></div>
        <Button className={cssCardButtons} variant="dark" onClick={props.initialize}>Reset</Button>
        <Button className={cssCardButtons} variant="dark" onClick={() => window.print()}>Print</Button>
        </div>
      </div>
  }

export function ResultsPDF (props: ResultsProps): JSX.Element | null
  {
  const ResultsList = (!props.registrationList || !props.factsList || !props.policyList) ? null : props.registrationList?.filter(reg => reg !== undefined).map((reg, i) =>
      {
      let matchedElIds = new Set(reg.matches);
      const regFact = props.factsList?.at(i);
      const policy = props.policyList?.at(i);
      return (!regFact || !policy) ? null :
        <div key={`results_${reg.registration.id}${i}`}>
          <h4 className="mx-0 my-4">{reg.registration.name} — Match: {['domain', 'contact', 'email'].filter(field => SearchFields.get(field)?.some(id => matchedElIds.has(id))).join(",")}</h4>
          <RequestResultsTable getField={props.getField} regFact={regFact} policy={policy} registration={reg.registration} matchedElIds={matchedElIds} />
          </div>
      });

  return ((!props.registrationList || !props.factsList || !props.policyList)
    ? null
    : <>{ResultsList}</>);
  }

/*
Development Notes:

*/
