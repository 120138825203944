/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Container, Row, Col, ListGroup, ListGroupItem, DropdownItem, DropdownButton, Stack, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap';
import _ from 'lodash';
/* API */
import {APIRule, APIDictionary, APIPolicy, PolicyListItem, PolicyVersionReference } from 'common/api/types';
import {getAttributeFromRule, getRuleForElement, getPolicy, getPolicyList } from 'common/api/requests';
/* HOOKS */
import {usePolicyListStore, PolicyListStore} from 'hooks';
/* UTILITIES */
import { redirect } from "common/utility/window";
/* CUSTOM COMPONENTS */
import Checklist, {ChecklistItem, ChecklistHeader} from "components/molecules/Menu/Checklist";
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";

///////////////////////////////////////
// SWITCH BUTTON
///////////////////////////////////////

export enum SWITCH_MODE
  {
  SWITCH = "Switch",
  UNSWITCH = "Unswitch",
  }

interface SwitchButtonProps
  {
  disabled: boolean;
  mode: SWITCH_MODE,
  eventClick: () => void;
  }

function SwitchButton (props: SwitchButtonProps)
  {
  return (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{"Switch Comparison to Edit Mode and view Policy as Comparison."}</Tooltip>}><Button variant="dark" disabled={props.disabled} onClick={props.eventClick}>{props.mode}</Button></OverlayTrigger>);
  }

///////////////////////////////////////
// COMPARE BUTTON
///////////////////////////////////////

export enum COMPARE_MODE
  {
  COMPARE = "Compare",
  UNCOMPARE = "Uncompare",
  }

interface CompareButtonProps
  {
  disabled: boolean;
  comparisonPolicy: APIPolicy | null;
  eventClick: () => void;
  }

function CompareButton (props: CompareButtonProps)
  {
  const buttonName: COMPARE_MODE = props.comparisonPolicy ? COMPARE_MODE.UNCOMPARE : COMPARE_MODE.COMPARE;
  return (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{"Compare selected policies"}</Tooltip>}><Button variant="dark" disabled={props.disabled} onClick={props.eventClick}>{buttonName}</Button></OverlayTrigger>);
  }

///////////////////////////////////////
// SELECT SUBJECTS BUTTON
///////////////////////////////////////

interface SubjectsCheckListProps
  {
  policy: APIPolicy;
  comparisonPolicy: APIPolicy | null;
  policyList: PolicyListItem[] | null;
  selectedSubjects: number [];
  policyMetadataCache: {[key: number]: PolicyListItem};
  disabled: boolean;
  updateSelectedSubjects: (newSubjects: number[]) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  updateMetaDataCache: (newPolicy: PolicyListItem) => void;
  }

function SelectSubjectsButton (props: SubjectsCheckListProps)
  {
  const [latest, setLatest] = React.useState<Record<number, boolean>>({});
  const [versions, setVersions] = React.useState<Record<number, number>>({});

  React.useEffect(() =>
    {
    const fetchVersionStatuses = async () =>
      {
      const statuses: Record<number, boolean> = {};
      const recents: Record<number, number> = {};

      for (const pID of props.policy.subject_to?.filter(pID => !props.policyMetadataCache[pID]?.deleted) ?? [])
        {
        const subject = await getPolicy(pID).then((p: APIPolicy | null) => p);
        if (!subject) continue;
        statuses[pID] = subject.versions?.length <= props.policyMetadataCache[pID]?.version;
        recents[pID] = subject.versions?.[0]?.id;
        }

      setLatest(statuses);
      setVersions(recents);
      };

    fetchVersionStatuses();
    }, [props.policy.subject_to, props.policyMetadataCache]);

  const upgradeSubject = (pID: number, newPolicy: PolicyListItem | null) =>
    {
    if (!newPolicy) return;
    const subjects = props.policy.subject_to?.filter(i => i !== pID) ?? [];
    subjects?.push(newPolicy.id);
    props.updatePolicy({subject_to: subjects}); // add subject
    props.updateMetaDataCache(newPolicy);
    }

  const deleteSubject = (pID: number) =>
    {
    if (props.policy && props.policy.subject_to.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.filter(i => i !== pID)});
    }

  // adds a policy ID to the selected comparison list
  const checkSubject = (pID: number) =>
    {
    props.updateSelectedSubjects(props.selectedSubjects.concat([pID]));
    }

  // removes a policy ID from the selected comparison list
  const uncheckSubject = (pID: number) =>
    {
    props.updateSelectedSubjects(props.selectedSubjects.filter(i => i !== pID));
    }

  // toggle policy ID from selected comparison list
  const toggleCheck = (pID: number) =>
    {
    if (props.policy) props.selectedSubjects.includes(pID) ? uncheckSubject(pID) : checkSubject(pID);
    }

  const comparisonSubjects = props.policy.subject_to?.filter(pID => !props.policyMetadataCache[pID]?.deleted);

  const checkListItems = (!comparisonSubjects)
    ? []
    : comparisonSubjects.map((pID: number) =>
        {
        const key    : string     = _.uniqueId(`comparison_subject_`);
        const state  : boolean    = props.selectedSubjects?.includes(pID) ? true : false;
        const name   : string     = props.policyMetadataCache[pID]?.name ? `${props.policyMetadataCache[pID]?.name} (v.${props.policyMetadataCache[pID]?.version})` : `<Unknown policyID=${pID}>`;

        const upgradeAction = (latest[pID] ?? false)
          ? undefined
          : () => upgradeSubject(pID, props.policyList?.find(p => p.id === versions[pID]) ?? null);

        return <ChecklistItem key={key} eventKey={key} checked={state} text={name} onChange={() => toggleCheck(pID)} upgrade={upgradeAction} remove={() => deleteSubject(pID)} disabled={Boolean(props.comparisonPolicy !== null)} />;
        });

  return <Checklist title="Select" tooltip='Select subjects for the comparison and click Compare'>{checkListItems.length ? checkListItems : <DropdownItem disabled>No policies available</DropdownItem>} </Checklist>;
  }

///////////////////////////////////////
// EDIT SUBJECTS: BUTTON & MODAL
///////////////////////////////////////

interface MultiSelectPolicyListProps
  {
  policy: APIPolicy;
  policyList: PolicyListItem[] | null;
  selectedPolicies?: number[];
  showModal: boolean;
  updateMetaDataCache: (policy: PolicyListItem) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  toggle: () => void;
  }

function MultiSelectPolicyModal (props: MultiSelectPolicyListProps)
  {
  // add policy ID from subject list
  const addSubject = (pID: number) =>
    {
    if (!props.policy?.subject_to?.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.concat([pID])});
    }

  // remove policy ID from subject list
  const removeSubject = (pID: number) =>
    {
    if (props.policy?.subject_to?.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.filter(i => i !== pID)});
    }

  // toggle policy ID from subject list
  const toggleSubject = (pID: number) => (props.policy && props.policy.subject_to.includes(pID)) ? removeSubject(pID) : addSubject(pID);

  // Updates the comparison policy list when a new policy is selected: Checks if the policy metadata is cached, and caches it if not; adds/removes policy ID subject_to list
  const updateSubjects = (newPolicy: PolicyListItem) =>
    {
    if (!props.policy || !newPolicy) return;
    props.updateMetaDataCache(newPolicy);
    toggleSubject(newPolicy.id);
    }

  const subjects = props.policyList?.filter(policy => !policy.deleted)?.map(policy =>
    ({
    key: `policy-${policy.id}`,
    state: props.selectedPolicies?.includes(policy.id) ? true : false,
    text: `${policy.name} (v.${policy.version})`,
    category: policy.org_type,
    action: () => updateSubjects(policy)
    }));

  const empty_list : JSX.Element = <ListGroupItem>{"No Policies Found"}</ListGroupItem>;
  const policy_authority : JSX.Element | JSX.Element[] = subjects?.filter(subject => subject.category === "policy_authority")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.state} onClick={item.action}>{item.text}</ListGroupItem>) ?? empty_list;
  const registry : JSX.Element | JSX.Element[] = subjects?.filter(subject => subject.category === "registry")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.state} onClick={item.action}>{item.text}</ListGroupItem>) ?? empty_list;
  const registrar : JSX.Element | JSX.Element[] = subjects?.filter(subject => subject.category === "registrar")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.state} onClick={item.action}>{item.text}</ListGroupItem>) ?? empty_list;

  const cssHeader        : string = "text-center text-muted";
  return <Modal size="xl" centered scrollable show={props.showModal} onHide={props.toggle}>
    <Modal.Header closeButton>
      <Modal.Title>{"Edit Subjects for Policy Comparisons"}</Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <Container fluid>
        <Row>
          <Col>
            <h4 className={cssHeader}>{"Policy Authority"}</h4>
            <ListGroup>{policy_authority}</ListGroup>
            </Col>
          <Col>
            <h4 className={cssHeader}>{"Registry"}</h4>
            <ListGroup>{registry}</ListGroup>
            </Col>
          <Col>
            <h4 className={cssHeader}>{"Registrar"}</h4>
            <ListGroup>{registrar}</ListGroup>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    <Modal.Footer><Button variant="dark" onClick={props.toggle}>Done</Button></Modal.Footer>
    </Modal>
  }

interface EditSubjectsProps
  {
  policy: APIPolicy;
  policyList: PolicyListItem[] | null;
  selectedPolicies?: number[];
  disabled: boolean;
  updateMetaDataCache: (policy: PolicyListItem) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  }

function EditSubjectsButton (props: EditSubjectsProps)
  {
  const [showModal, setShowModal] = React.useState(false);
  const hide = () => setShowModal(false);
  const show = () => setShowModal(true);
  const toggle = () => setShowModal(!showModal);

  return <React.Fragment>
    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{"Edit list of subjects for policy comparisons."}</Tooltip>}><Button variant="dark" onClick={() => show()} disabled={props.disabled}>{"Edit Subjects"}</Button></OverlayTrigger>
    <MultiSelectPolicyModal policy={props.policy} policyList={props.policyList} selectedPolicies={props.policy.subject_to} updateMetaDataCache={props.updateMetaDataCache} updatePolicy={props.updatePolicy} showModal={showModal} toggle={toggle} />
    </React.Fragment>
  }

///////////////////////////////////////
// COMPARISON TOOLBAR
///////////////////////////////////////

interface PolicyComparisonToolProps
  {
  dictionary: APIDictionary;
  policy: APIPolicy;
  comparisonPolicy: APIPolicy | null;
  selectedSubjects: number [];
  policyMetadataCache: {[key: number]: PolicyListItem};
  updateSelectedSubjects: (newSubjects: number[]) => void;
  updateComparisonPolicy: (p: APIPolicy | null) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  updateMetaDataCache: (newPolicy: PolicyListItem) => void;
  eventFlip: (newPolicy: APIPolicy, newComparisonPolicy: APIPolicy | null) => void;
  }

function ComparisonToolbar (props: PolicyComparisonToolProps)
  {
  const [switchMode, setSwitchMode] = React.useState<SWITCH_MODE>(SWITCH_MODE.SWITCH);
  const policyList: PolicyListStore = usePolicyListStore();

  // Returns a list of policy IDs to use for comparison, filtered from the policy's subject_to list; Removes any policies that: Are in the ignoredSubjectToPolicies list; Have been deleted (via the policyMetadataCache);
  const selectedComparisonPolicies: number [] = props.policy?.subject_to?.filter(p => props.selectedSubjects.includes(p) && props.policyMetadataCache[p]?.deleted !== true) ?? [];

  // Downloads and computes the intersection of policies for comparison.
  async function downloadComparisonPolicies (policies: number[], setPolicyLoadingProgress: (progress: number) => void, dataDictionary: APIDictionary)
    {
    if (policies.length === 0) return null; // Takes the user out of comparison mode
    let policyIntersection: APIPolicy | null = null;
    setPolicyLoadingProgress(0);

    for (let i = 0; i < policies.length; i++)
      {
      const newPolicy = await getPolicy(policies[i]);
      if (newPolicy === null) return null; // Takes the user out of comparison mode
      policyIntersection = (policyIntersection === null) ? newPolicy : policyAnd(dataDictionary, policyIntersection, newPolicy);
      setPolicyLoadingProgress((i + 1) / policies.length * 100);
      }

    setPolicyLoadingProgress(100);
    return policyIntersection as APIPolicy;
    }

  // Download and set the comparison policy based on selected policies
  function compare ()
    {
    try
      {
      const comparisons: number[] = (props.comparisonPolicy) ? [] : selectedComparisonPolicies; // if we are already in comparison mode, unset the comparison for toggle effect
      downloadComparisonPolicies(comparisons, (progress) => null, props.dictionary).then(props.updateComparisonPolicy);
      }
    catch (error: any)
      {
      console.error(error);
      }
    }

  // Downloads policy and sets it as the comparison, then redirects Policy Editor to the comparison as the primary active policy
  function flipComparisons ()
    {
    try
      {
      if (props.policy && (props.comparisonPolicy !== null) && props.dictionary)
        {
        const comparisonPolicy: APIPolicy = props.comparisonPolicy;
        downloadComparisonPolicies([props.policy.id], (progress) => null, props.dictionary)
          //.then(props.updateComparisonPolicy)
          .then((p: APIPolicy | null) => props.eventFlip(comparisonPolicy, p))
          .finally(() =>
            {
            //redirect(`/policy/${props.comparisonPolicy?.id}`); // old logic
            //props.updatePolicy(comparisonPolicy); // old logic
            setSwitchMode(prev => (prev === SWITCH_MODE.SWITCH) ? SWITCH_MODE.UNSWITCH : SWITCH_MODE.SWITCH);
            });
        }
      }
    catch (error: any)
      {
      console.error(error);
      }
    }

  const disableSwitch: boolean = Boolean(!props.comparisonPolicy || props.selectedSubjects?.length !== 1);
  const disableCompare: boolean = (switchMode === SWITCH_MODE.UNSWITCH);
  const disableSubjects: boolean = (switchMode === SWITCH_MODE.UNSWITCH);
  return (<ButtonGroup vertical={false} className="shadow-sm">
    <EditSubjectsButton
      policy={props.policy}
      policyList={policyList.data ?? null}
      selectedPolicies={props.policy.subject_to}
      disabled={(switchMode === SWITCH_MODE.UNSWITCH || props.comparisonPolicy !== null)}
      updateMetaDataCache={props.updateMetaDataCache}
      updatePolicy={props.updatePolicy}
      />
    <SelectSubjectsButton
      policy={props.policy}
      comparisonPolicy={props.comparisonPolicy}
      policyList={policyList.data ?? null}
      selectedSubjects={props.selectedSubjects}
      policyMetadataCache={props.policyMetadataCache}
      updateSelectedSubjects={props.updateSelectedSubjects}
      updatePolicy={props.updatePolicy}
      updateMetaDataCache={props.updateMetaDataCache}
      disabled={disableSubjects}
      />
    <CompareButton
      comparisonPolicy={props.comparisonPolicy}
      disabled={disableCompare}
      eventClick={compare}
      />
    <SwitchButton
      disabled={disableSwitch}
      mode={switchMode}
      eventClick={flipComparisons}
      />
    </ButtonGroup>);
  }

export default ComparisonToolbar;

///////////////////////////////////////
// HELPERS
///////////////////////////////////////

// Compute the intersection of two policies, creating a new policy with rules that represent the bitwise AND of corresponding rules.
// - if a rule is missing in either policy, the attributes are treated as all zeros.
// TODO: Consider returning an array so that .split() is not needed to format the list of names
function policyAnd(dict: APIDictionary, pa: APIPolicy, pb: APIPolicy): APIPolicy
  {
  // Concatenate rules for each element in each element group
  const newRules = ([] as APIRule[]).concat(...dict.element_groups.map(eg =>
    {
    return eg.elements.map(el =>
      {
      const newRule: APIRule = { id: 0, element_id: el.id, attributes: [] }; // Initialize a new rule for the current element
      const paRule = getRuleForElement(pa, el.id); // Get rules for the current element from each input policy
      const pbRule = getRuleForElement(pb, el.id); // Get rules for the current element from each input policy
      // Check if both rules exist; if an attribute is missing in either rule, treat it as 0; compute the bitwise AND of attributes from both rules
      if (paRule && pbRule)
        {
        newRule.attributes = dict.rule_attributes.map(att =>
          {
          const paAtt = getAttributeFromRule(paRule, att.id);
          const pbAtt = getAttributeFromRule(pbRule, att.id);
          if (!paAtt || !pbAtt) return { attribute_id: att.id, value: 0 };
          return { attribute_id: att.id, value: paAtt.value & pbAtt.value };
          });
        }

      return newRule;
      });
    }));

  // Create a new APIPolicy object with the computed rules
  return Object.assign(pa, { name: pa.name + " ∩ " + pb.name, rules: newRules });
  }
