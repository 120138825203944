/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Container, Row, Col, Button, ButtonGroup, Dropdown, DropdownButton, FloatingLabel, Form} from 'react-bootstrap';
/* API */
import { APIRequestTemplate, APIDictionary, APIRequestTemplateItem, APIRequestTemplateRole, APIMatchedRegistration, APIPolicy, APIRegistrantFacts, APITypedAttribute, APIAttributeOption, APIAttribute} from 'common/api/types';
import {executeRequestQuery, saveRequestTemplate, deleteRequestTemplate } from 'common/api/requests';
/* UTILITY */
import { redirect } from "common/utility/window";
/* HOOKS */
import {useRequestTemplateStore, TemplateAttributes, useApplicationContext, AppContextType} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, FlexBox, Footer, Metadata} from "components/atoms/Templates";
/* CUSTOM COMPONENTS */
import FileMenu, {FileMenuItem, FileMenuSave, FileMenuDelete, FileMenuDivider, FileMenuHelp} from 'components/molecules/Menu/File';
import EditableLabel from 'components/atoms/EditableText/EditableLabel';
import {TextBox, NoteBox, SelectBox, CheckBox} from 'components/atoms/Inputs';
import Droppable from 'components/molecules/Drag&Drop/Droppable';
import Draggable, {DragItem} from 'components/molecules/Drag&Drop/Draggable';
import Checklist, {ChecklistItem, ChecklistHeader} from 'components/molecules/Menu/Checklist';

/////////////////////////////////////
// TYPE DEFINITIONS
/////////////////////////////////////

export enum RoleCategory
  {
  USER = "user",
  ADMIN = "administrator",
  }

/////////////////////////////////////
// HELPERS
/////////////////////////////////////

function calculateTextBoxType (itemID:number)
  {
  switch (itemID)
    {
    case TemplateAttributes.UI_Contact: return "name";
    case TemplateAttributes.UI_Email: return "email";
    case TemplateAttributes.Rule_RequesterID: return "uniqueID";
    case TemplateAttributes.UI_Organization: return "organization";
    case TemplateAttributes.UI_ProcessingNotes: return "text";
    default: return "text";
    }
  }

/////////////////////////////////////
// REQUEST TEMPLATE EDITOR
/////////////////////////////////////

interface Props
  {
  selectedRequestTemplateID: number;
  unsavedChanges: (flag: boolean) => void;
  }

function TemplateEditor (props: Props)
  {
  // CUSTOM HOOKS: STATE MANAGEMENT
  const [draggables, setDraggables] = React.useState<DragItem[] | null>(null);
  const [draggedItem, setDraggedItem] = React.useState<number | null>(null);
  const requestTemplate = useRequestTemplateStore({selectedTemplateID: props.selectedRequestTemplateID});

  React.useEffect(() =>
    {
    if (!requestTemplate.data) return;
    let draggableList:DragItem[] = requestTemplate.optionalFields?.map((definition) =>
      {
      let attributeID = definition ? definition.attribute.id : 0;
      let attributeName = definition?.attribute.name ?? "";
      let attributeRole = requestTemplate.getRole(attributeID) ?? RoleCategory.USER;
      let item: DragItem = { ID: attributeID, title: attributeName, category: attributeRole, }
      return item;
      });

    setDraggables(draggableList);
    }, [requestTemplate.data]);

  // CALLBACKS: DRAG & DROP EVENTS

  function updateCategory (status:string)
    {
    if (draggables === null || draggedItem === null) return;
    requestTemplate.setRole(draggedItem, status as APIRequestTemplateRole);
    setDraggables(draggables.map((task:DragItem) => task.ID === draggedItem ? {ID: draggedItem, title: requestTemplate.getDefinitionByID(task.ID)?.attribute.name, category: status} as DragItem : task));
    setDraggedItem(null);
    }

  function eventDragged (id:number)
    {
    setDraggedItem(id);
    }

  const requestOptions = draggables?.filter(i => i.category === RoleCategory.USER) ?? [];
  const templateOptions = draggables?.filter(i => i.category === RoleCategory.ADMIN) ?? [];

  // MEMOS: DATA MANAGEMENT

  const memoRequestOptions = requestOptions.map((item:DragItem) =>
    {
    const generateRandomID = Math.floor(100000 + Math.random() * 900000).toString();
    const checkRuleUniqueIDField = requestTemplate.getField(TemplateAttributes.Rule_UniqueID) === "1" ? true : false;
    const attributeValue: string | undefined = (item.ID !== TemplateAttributes.Rule_RequesterID) ? requestTemplate.getField(item.ID) : checkRuleUniqueIDField ? generateRandomID : requestTemplate.getField(item.ID);
    const definition: APITypedAttribute | undefined = requestTemplate.getDefinitionByID(item.ID);
    const isSelectBox = Boolean(definition?.attribute?.values?.length);

    if (isSelectBox)
      {
      return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
        <SelectBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID)} />
        <div><CheckBox checked={requestTemplate.getRequire(item.ID) ?? false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, !check)} /></div>
        </Draggable>
      }
    else
      {
      let type: string = calculateTextBoxType(item.ID);
      return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
        <TextBox type={type} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID)} />
        <div><CheckBox checked={requestTemplate.getRequire(item.ID) ?? false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, !check)} /></div>
        </Draggable>
      }
    });

  const memoTemplateOptions = templateOptions.map((item:DragItem) =>
    {
    const attributeValue: string | undefined = requestTemplate.getField(item.ID);
    const definition: APITypedAttribute | undefined = requestTemplate.getDefinitionByID(item.ID);
    const isSelectBox = Boolean(definition?.attribute?.values?.length);

    if (isSelectBox)
      {
      return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
        <SelectBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID)} />
        <CheckBox checked={requestTemplate.getRequire(item.ID) ?? false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, !check)} />
        </Draggable>
      }
    else
      {
      let type: string = calculateTextBoxType(item.ID);
      return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
        <TextBox type={type} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID)} />
        <CheckBox checked={requestTemplate.getRequire(item.ID) ?? false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, !check)} />
        </Draggable>
      }
    });

  const rules_search = [TemplateAttributes.Rule_DNS, TemplateAttributes.Rule_Email, TemplateAttributes.Rule_Forensic, TemplateAttributes.Rule_Name, TemplateAttributes.Rule_Org, TemplateAttributes.Rule_Phone, TemplateAttributes.Rule_RegOp, TemplateAttributes.Rule_Location];
  const rules_requirements = [TemplateAttributes.Rule_UniqueID];

  const rulesInclude: APIAttribute[] = rules_search.map((item:TemplateAttributes) => requestTemplate.getDefinitionByID(item)?.attribute)?.filter((i: APIAttribute | undefined): i is APIAttribute => i !== undefined);
  const rulesRequirements: APIAttribute[] = rules_requirements.map((item:TemplateAttributes) => requestTemplate.getDefinitionByID(item)?.attribute).filter((i: APIAttribute | undefined): i is APIAttribute => i !== undefined);

  // RENDER
  const gridColumn = "d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0";
  return ((!requestTemplate.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <EditableLabel value={requestTemplate.title} onValueChange={requestTemplate.setTitle} />
          <div className="ms-auto"></div>
          <Alert variant="warning" className="d-block m-0 ms-auto my-0 px-3 py-2 fs-6 lh-base"> Under Construction </Alert>
          <Button variant='dark' onClick={() => redirect(`#/request_execution/${props.selectedRequestTemplateID}`)}><i className="bi bi-app-indicator"></i> Execute</Button>
          <FileMenu dirtyFlag={requestTemplate.unsavedChanges}>
            <FileMenuSave onClick={requestTemplate.eventSave} />
            <FileMenuDelete onClick={requestTemplate.eventDelete} />
            <FileMenuDivider />
            <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#requests.md" />
            </FileMenu>
          </Header>
        <Main>
          <Section>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h4> Build: </h4>
                </Col>
              <Col className={gridColumn}>
                <h4> Configure: </h4>
                </Col>
              </Row>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6> Required: Request Fields </h6>
                <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.UI_Date)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.UI_Date)} required={true} />
                <TextBox type="url" value={requestTemplate.getField(TemplateAttributes.UI_Domain)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.UI_Domain)} required={true} />
                </Col>
              <Col className={gridColumn}>
                <h6> Requirements: Rules </h6>
                <ButtonGroup id="RequirementRules" className="rounded-0 border-0 m-0 p-0">
                  <TemplateConfigurationTool
                    title="Require"
                    attributes={rulesRequirements}
                    getField={requestTemplate.getField}
                    setField={requestTemplate.setField}
                    />
                  </ButtonGroup>
                </Col>
              </Row>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6> Optional Fields: User Control </h6>
                <Droppable category={RoleCategory.USER} updateCategory={updateCategory}>
                  {memoRequestOptions}
                  </Droppable>
                </Col>
              <Col className={gridColumn}>
                <h6> Optional Fields: Administrator Controls </h6>
                <Droppable category={RoleCategory.ADMIN} updateCategory={updateCategory}>
                  {memoTemplateOptions}
                  </Droppable>
                </Col>
              </Row>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6> Data included in search results: </h6>
                <SearchFilterTags
                  rulesInclude={rulesInclude}
                  getField={requestTemplate.getField}
                  />
                <form className="needs-validation" noValidate>
                  <div><button disabled={true} className="btn btn-primary" type="submit">Submit form</button></div>
                  </form>
                </Col>
              <Col className={gridColumn}>
                <h6> Filter: Search Results </h6>
                <ButtonGroup id="FilterSearchResults" className="rounded-0 border-0 m-0 p-0">
                  <TemplateConfigurationTool
                    title="Filter"
                    attributes={rulesInclude}
                    getField={requestTemplate.getField}
                    setField={requestTemplate.setField}
                    />
                  </ButtonGroup>
                </Col>
              </Row>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6> Request Headers: </h6>
                <TextBox value={requestTemplate.getField(TemplateAttributes.Header_Description)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Header_Description)} />
                <TextBox value={requestTemplate.getField(TemplateAttributes.Header_AccredAuth)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Header_AccredAuth)} />
                </Col>
              <Col className={gridColumn}>
                <h6> Template Descriptions: </h6>
                <TextBox value={requestTemplate.getField(TemplateAttributes.Expected_Fields)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Expected_Fields)} />
                <TextBox value={requestTemplate.getField(TemplateAttributes.Identity_Service)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Identity_Service)} />
                <TextBox value={requestTemplate.getField(TemplateAttributes.Accredting_Enforcement)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Accredting_Enforcement)} />
                <TextBox value={requestTemplate.getField(TemplateAttributes.Purposes_Protection)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Purposes_Protection)} />
                <TextBox value={requestTemplate.getField(TemplateAttributes.Jurisdictions)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Jurisdictions)} />
                </Col>
              </Row>
            </Section>
          <Metadata>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6>Organization:</h6>
                <TextBox type="organization" value={requestTemplate.getField(TemplateAttributes.Metadata_OrgName)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_OrgName)} />
                <SelectBox value={requestTemplate.getField(TemplateAttributes.Metadata_OrgType)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_OrgType)} />
                </Col>
              <Col className={gridColumn}>
                <h6>Primary Contact:</h6>
                <TextBox type="name" value={requestTemplate.getField(TemplateAttributes.Metadata_PrimePOC)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_PrimePOC)} />
                <TextBox type="email" value={requestTemplate.getField(TemplateAttributes.Metadata_PrimeEmail) ?? ""} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_PrimeEmail)} />
                </Col>
              <Col className={gridColumn}>
                <h6>Alternate Contact:</h6>
                <TextBox type="name" value={requestTemplate.getField(TemplateAttributes.Metadata_AltPOC)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_AltPOC)} />
                <TextBox type="email" value={requestTemplate.getField(TemplateAttributes.Metadata_AltEmail) ?? ""} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_AltEmail)} />
                </Col>
              </Row>
            <Row className="m-0 p-0 gap-3 mb-3">
              <Col className={gridColumn}>
                <h6>Version:</h6>
                <TextBox type="text" value={requestTemplate.getField(TemplateAttributes.Metadata_Version)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Version)} />
                <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.Metadata_Updated) ?? ""} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Updated)} />
                <TextBox type="text" value={requestTemplate.getField(TemplateAttributes.Metadata_Completion)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Completion)} />
                </Col>
              <Col className={gridColumn}>
                <h6>Publication:</h6>
                <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.Metadata_Date) ?? ""} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Date)} />
                <TextBox type="text" value={requestTemplate.getField(TemplateAttributes.Metadata_Status)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Status)} />
                <TextBox type="text" value={requestTemplate.getField(TemplateAttributes.Metadata_Distribution)} eventChange={requestTemplate.setField} definition={requestTemplate.getDefinitionByID(TemplateAttributes.Metadata_Distribution)} />
                </Col>
              <Col className={gridColumn}>&nbsp;</Col>
              </Row>
            </Metadata>
          </Main>
          <Footer />
        </Page>);
  }

export default TemplateEditor;



/////////////////////////////////////
// TEMPLATE CONFIGURATION TOOL
/////////////////////////////////////

interface TemplateEditorToolButton
  {
  title: string;
  attributes: APIAttribute[];
  getField: (attributeID: number) => string | undefined;
  setField: (key: number, value: string) => void;
  }

type ConfigureToolItem =
  {
  attrID: number;
  key: string;
  checked: boolean;
  label: string;
  options: APIAttributeOption[] | undefined;
  type: "checkbox" | "select";
  }

// manages template attributes as dropdown menu list
function TemplateConfigurationTool (props: TemplateEditorToolButton)
  {
  const check = "1";
  const uncheck = "0";

  const extracts = props.attributes.map((attr: APIAttribute) =>
    {
    const attrID: number = attr.id;
    const key: string = `rule_${attr}`;
    const checked: boolean = props.getField(attrID) === "1";
    const label: string = attr?.name ?? "Unknown";
    const options: APIAttributeOption[] | undefined = attr?.values;
    const type = (options?.length === 2 && options?.find(v => v.name === "Include")) ? "checkbox" : "select"; // is this a binary option? is at least one option === "Include"??
    return {attrID, label, checked, key, options, type} as ConfigureToolItem;
    });

  return (
    <Checklist title={props.title}>
      {extracts.map((item) => (item.type === "checkbox")
        ? <ChecklistItem key={item.key} eventKey={item.key} checked={item.checked} text={item.label} onChange={() => props.setField(item.attrID, item.checked ? uncheck : check)} />
        : <React.Fragment key={item.key}>
            <ChecklistHeader>{item.label}</ChecklistHeader>
            {item.options?.map((opt: APIAttributeOption) => <Dropdown.Item key={`rule_${item.key}_${opt.value}`} eventKey={item.key} active={(props.getField(item.attrID) === `${opt.value}`)} onClick={() => props.setField(item.attrID, `${opt?.value ?? ""}`)} className="m-0 px-3 py-2">{opt.name}</Dropdown.Item>) ?? null}
            </React.Fragment>)}
      </Checklist>
    )
  }



/////////////////////////////////////
// SEARCH FILTER TAGS
/////////////////////////////////////

interface SearchFilterTagsProps
  {
  rulesInclude: APIAttribute[],
  getField: (attributeID: number) => string | undefined,
  }

// maintains a set of tags to show what is included in the search results
export function SearchFilterTags (props: SearchFilterTagsProps)
  {
  function filterLocationRuleOption (a: APIAttributeOption) : boolean
    {
    const rule: string = props.getField(TemplateAttributes.Rule_Location) ?? "0";
    return (a.value !== 0 && a.value <= parseInt(rule) || (a.value === 0 && parseInt(rule) === a.value));
    }

  function getTag (attr: APIAttribute): string | undefined
    {
    const checked: boolean = props.getField(attr.id) === "1";
    return checked ? attr?.name : undefined;
    }

  const extractOptionName = (i: APIAttributeOption) => i.name;
  const filterEmptyTags = (i: string | undefined): i is string => i !== undefined;

  const includeTags: string[] = props.rulesInclude.filter((i:APIAttribute) => (i.id !== TemplateAttributes.Rule_Location)).map(getTag)?.filter(filterEmptyTags);
  const locationTags: string [] | undefined = props.rulesInclude.find((i:APIAttribute) => i.id === TemplateAttributes.Rule_Location)?.values?.filter(filterLocationRuleOption)?.map(extractOptionName)?.filter(filterEmptyTags);

  const cssTag: string = "d-inline-block m-0 px-3 py-2 rounded-3 bg-secondary bg-opacity-25 text-dark shadow-sm fw-bold text-center lh-1";
  return <ul className="d-flex flex-wrap align-content-start gap-2 m-0 p-0">
    {includeTags?.map((tag: string, index: number) => <li key={`searchResults-${index}`} className={cssTag}>{tag}</li>)}
    {locationTags?.map((tag: string, index: number) => <li key={`searchResults-${index}`} className={cssTag}>{tag}</li>)}
    </ul>
  }



/////////////////////////////////////
// DEVELOPMENT NOTES
/////////////////////////////////////

/*

The code @web/src/pages/Requests/TemplateEditor.tsx:1-415 is a React component that renders a user interface for editing a request template. The purpose of this code is to provide a way for users to view and modify various fields and attributes associated with a request template.

The component takes an input prop called props, which is an object containing data related to the request template being edited. This includes a dataDictionary object that likely contains definitions and metadata for the different fields and attributes.

The main output of this component is the rendered user interface, which consists of various input fields, dropdowns, and other UI elements for displaying and modifying the request template data.

To achieve its purpose, the code follows a typical React component structure. It imports necessary dependencies and defines a functional component called TemplateEditor. Inside the component, there is a series of nested JSX elements that represent the different sections and UI elements of the template editor.

The component renders several DataCard components, which seem to be custom components for displaying data in a card-like format. Within these DataCard components, there are various input fields and dropdowns for editing different attributes of the request template.

For example, there is a section for editing the "Version" attributes, which includes input fields for "Version," "Updated," and "Completion." Another section is for editing the "Publication" attributes, with input fields for "Date," "Status," and "Distribution."

The component also includes a Print component, which likely handles printing or exporting the request template data in some way.

The logic flow of the component involves rendering the appropriate UI elements based on the data in the props object. It uses functions like getAttributeDefinition to retrieve metadata for specific attributes, and eventUpdateField to handle updates to the input fields.

Overall, this code provides a user interface for viewing and modifying various fields and attributes related to a request template. It takes in data about the template, renders input fields and dropdowns for editing different sections of the template, and likely has functionality for saving or exporting the modified template data.

*/
