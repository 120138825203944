/* NODE PACKAGES */
import React from 'react';
import _, { get, set, update } from 'lodash';
import { Axios } from 'axios';
/* TYPES */
import {APIDictionary, APIRequestTemplate, APIRequestTemplateItem, APIRequestTemplateRole, APITypedAttribute, APIAttributeOption, APIMatchedRegistration} from 'common/api/types';
import {executeRequestQuery, saveRequestTemplate, deleteRequestTemplate} from 'common/api/requests';
/* HOOKS */
import {useAxios, TemplateAttributes, useApplicationContext, AppContextType} from 'hooks';
/* UTILITY */
import { redirect } from "common/utility/window";

/////////////////////////////////////
// CONSTANTS
/////////////////////////////////////

const optionalAttributes: TemplateAttributes[] =
  [
  TemplateAttributes.UI_Contact,
  TemplateAttributes.UI_Email,
  TemplateAttributes.UI_RequesterID,
  TemplateAttributes.UI_Organization,
  TemplateAttributes.UI_ProcessingNotes,
  TemplateAttributes.Rule_Location,
  TemplateAttributes.Rule_Sensitivity,
  TemplateAttributes.Rule_Logging,
  TemplateAttributes.Rule_Processing,
  ];

const __TEMPLATE_NAME: string = "Untitled Template";
const __VALUE: string = "";
const __REQUIRED: boolean = false;
const __ROLE: APIRequestTemplateRole = "user";

export type RequestTemplateStore =
  {
  data: APIRequestTemplate | null;
  optionalFields: APIRequestTemplateItem[] | null;
  getField: (id: number) => string | undefined;
  getRole: (id: number) => APIRequestTemplateRole | undefined;
  getDefinitionByID: (id: number) => APITypedAttribute | undefined;
  setRole: (id: number, role: APIRequestTemplateRole) => void;
  setField: (id: number, value: string) => void;
  setRequired: (id: number, required: boolean) => void;
  save: () => void;
  delete: () => void;
  unsavedChanges: boolean;
  }

interface RequestTemplateProps
  {
  selectedTemplateID: number;
  }

function useRequestTemplateStore (props: RequestTemplateProps)
  {
  const [axiosData, axiosError, axiosLoading] = useAxios({ url: `/api/request_template/${props.selectedTemplateID}`, method: 'GET', });
  const [data, setData] = React.useState<APIRequestTemplate | null>(null);
  const [dataFields, setDataFields] = React.useState<Map<number, string>>(new Map<number, string>());
  const [dataRequired, setDataRequired] = React.useState<Map<number, boolean>>(new Map<number, boolean>());
  const [dataRoles, setDataRoles] = React.useState<Map<number, "user" | "administrator">>(new Map<number, "user" | "administrator">());
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const appContext: AppContextType = useApplicationContext();

  React.useEffect(() =>
    {
    if (!axiosData) return;
    setData(axiosData.request_template);
    setDataFields(new Map<number, string>(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.value ?? __VALUE]) ?? []));
    setDataRequired(new Map<number, boolean>(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.required ?? __REQUIRED]) ?? []));
    setDataRoles(new Map<number, "user" | "administrator">(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.role ?? __ROLE]) ?? []));
    setUnsavedChanges(false);
    }, [axiosData]);

  React.useEffect (() =>
    {
    updateData({attributes: compileData()});
    setUnsavedChanges(true);
    }, [dataFields, dataRequired, dataRoles]);

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    if (appContext) appContext.unsavedChanges.current = unsavedChanges;
    }, [unsavedChanges]);

  const compileData = () =>
    {
    const uniqueKeys = new Set<number>([ ...dataFields.keys(), ...dataRequired.keys(), ...dataRoles.keys()]);
    return Array.from(uniqueKeys).map((key) => ({ attribute_id: key, value: dataFields.get(key) ?? __VALUE, required: dataRequired.get(key) ?? false, role: dataRoles.get(key) ?? __ROLE}));
    }

  const updateData = (values: Partial<APIRequestTemplate>) =>
    {
    if (!data) return;
    const template = Object.assign({}, data, values);
    setData(template as APIRequestTemplate);
    setUnsavedChanges(true);
    }

  function eventSave ()
    {
    if (data) saveRequestTemplate(data).then(response => console.log("Saved: ", response ? data : "Oops, something went wrong.")).catch(error => console.error(error)).finally(() => setUnsavedChanges(false));
    }

  function eventDelete ()
    {
    if (data && window.confirm('Are you sure you wish to delete this item?')) deleteRequestTemplate(data.id).finally(() => redirect('/requests/'));
    }

  return ({
    data: data ?? null,
    unsavedChanges: unsavedChanges,
    title: data?.name ?? __TEMPLATE_NAME,
    optionalFields: Array.from(optionalAttributes).filter((item) => appContext?.dictionary.getRequestAttributeByID(item) !== undefined).map((item) => appContext?.dictionary.getRequestAttributeByID(item)),
    eventSave,
    eventDelete,
    setTitle: (value:string) => updateData({name: value.trim()}),
    getField: (id:number) => dataFields.get(id),
    getRequire: (attributeID: number) => dataRequired.get(attributeID),
    getRole: (attributeID: number) => dataRoles.get(attributeID),
    setField: (key: number,value: string) => setDataFields(new Map(dataFields.set(key,value))),
    setRequired: (key: number, value: boolean) => setDataRequired(new Map(dataRequired.set(key, value))),
    setRole: (key: number, value: APIRequestTemplateRole) => setDataRoles(new Map(dataRoles.set(key, value))),
    getDefinitionByID: (attrID: number): APITypedAttribute | undefined => appContext?.dictionary.getRequestAttributeByID(attrID),
    });
  };

export default useRequestTemplateStore;
