/* NODE PACKAGES */
import React from 'react';
import {Spinner, Badge, Stack, Container, Row, Col} from 'react-bootstrap';
/* TYPES */
import { APIRequester, APIRequesterGroup, APIRegistrar, APIRegistrarGroup,} from 'common/api/types';
/* HOOKS */
import {RegistrarStore, useRegistrarGroupMembersStore } from 'hooks';
/* UTILITY */
import {createNewRequester, createRequesterGroup, createNewRegistrarGroup} from 'common/api/requests';
/* CUSTOM COMPONENTS */
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";
import {Page, Header, Main, Section, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// REGISTRARS INDEX LISTINGS
///////////////////////////////////////

const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";

function RegistrarGroupMembers ()
  {
  const registrarGroupMembers:RegistrarStore = useRegistrarGroupMembersStore();

  const requesters = React.useMemo<IndexListItem[]>(() => registrarGroupMembers.requesters?.map((requester: APIRequester) => ({href: `/#/requester/${requester.id}`, name: requester.name})) ?? [], [registrarGroupMembers.requesters]);
  const requester_groups = React.useMemo<IndexListItem[]>(() => registrarGroupMembers.requesterGroups?.map((group: APIRequesterGroup) => ({href: `/#/requester_group/${group.id}`, name: group.name})) ?? [], [registrarGroupMembers.requesterGroups]);
  const registrars = React.useMemo<IndexListItem[]>(() => registrarGroupMembers.registrars?.map((registrar: APIRegistrar) => ({href: `/#/registrar/${registrar.id}`, name: registrar.name})) ?? [], [registrarGroupMembers.registrars]);
  const registrar_groups = React.useMemo<IndexListItem[]>(() => registrarGroupMembers.registrarGroups?.map((group: APIRegistrarGroup) => ({href: `/#/registrar_group/${group.id}`, name: group.description})) ?? [], [registrarGroupMembers.registrarGroups]);

  const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return ((!requesters || !requester_groups || !registrars || !registrar_groups)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{`Registrars`}</span>
          </Header>
        <Main>
          <Section>
            <Stack direction='horizontal' gap={2} className="mb-3">
            <h4>{`Requesters`} <small>{`(${requesters?.length ?? 0})`}</small></h4>
            <div className="ms-auto"></div>
            <span className={cssIcon} onClick={createNewRequester}><i className="bi bi-plus-lg"></i></span>
            </Stack>
            <IndexListing data={requesters} />
            </Section>
          <Section>
            <Stack direction='horizontal' gap={2} className="mb-3">
            <h4>{`Requester Groups`} <small className="text-muted">{`(${requester_groups?.length ?? 0})`}</small></h4>
            <div className="ms-auto"></div>
            <span className={cssIcon} onClick={createRequesterGroup}><i className="bi bi-plus-lg"></i></span>
            </Stack>
            <IndexListing data={requester_groups} />
            </Section>
          <Section>
            <Stack direction='horizontal' gap={2} className="mb-3">
            <h4>{`Registrars`} <small>{`(${registrars?.length ?? 0})`}</small></h4>
            </Stack>
            <IndexListing data={registrars} />
            </Section>
          <Section>
            <Stack direction='horizontal' gap={2} className="mb-3">
            <h4>{`Registrar Groups`} <small>{`(${registrar_groups?.length ?? 0})`}</small></h4>
            <div className="ms-auto"></div>
            <span className={cssIcon} onClick={createNewRegistrarGroup}><i className="bi bi-plus-lg"></i></span>
            </Stack>
            <IndexListing data={registrar_groups} />
            </Section>
          </Main>
        <Footer />
        </Page>);
  }

export default React.memo(RegistrarGroupMembers);


/* Developer Notes:

@web/src/pages/Registrars/index.tsx:1-48 is a React component that serves as a container or a parent component for managing and displaying various sub-components related to Registrars within the application.

The purpose of this code is to provide a centralized place where different components and functionalities related to Registrars can be organized and rendered based on the current state or route of the application.

The component does not take any direct input props. Instead, it relies on the current URL or route of the application to determine which sub-component or functionality to render.

The output of this component is a rendered user interface that displays the appropriate sub-component or functionality based on the current route or state of the application.

Here's a breakdown of how the code achieves its purpose:

The code imports various sub-components and utility functions from other files within the project. These sub-components and functions are likely responsible for handling different aspects of Registrar management, such as editing Registrars, managing Registrar Groups, or displaying lists of Registrars.

The main component is defined as a functional component using the const RegistrarsPage: React.FC = () => { ... } syntax.

Inside the component function, the code uses the useLocation hook from the react-router-dom library to get the current location or URL of the application.

The code then uses a switch statement to check the pathname property of the current location and render different sub-components or functionalities based on the matched route.

For example, if the current route is /registrars, the code renders the RegistrarList component, which is likely responsible for displaying a list of Registrars.

If the current route is /registrar/:id, the code renders the RegistrarEditor component and passes the id parameter from the URL as a prop to the component. This component is likely responsible for editing or viewing details of a specific Registrar.

If the current route is /registrar_group/:id, the code renders the RegistrarGroupEditor component and passes the id parameter from the URL as a prop to the component. This component is likely responsible for editing or viewing details of a specific Registrar Group.

If the current route is /requester/:id, the code renders the RequesterEditor component and passes the id parameter from the URL as a prop to the component. This component is likely responsible for editing or viewing details of a specific Requester (an entity that can make requests within the application's domain).

If the current route is /requester_group/:id, the code renders the RequesterGroupEditor component and passes the id parameter from the URL as a prop to the component. This component is likely responsible for editing or viewing details of a specific Requester Group.

If none of the routes match, the code renders a fallback component called NotFound, which is likely a placeholder or an error message for unmatched routes.

The code does not involve any complex logic or data transformations. It primarily acts as a routing mechanism, rendering different sub-components based on the current URL or route of the application. The sub-components themselves are likely responsible for handling the actual logic and functionality related to Registrar management.

 */