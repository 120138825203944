/* NODE PACKAGES */
import React from'react';

const cssPage = "d-block m-0 p-0 bg-transparent text-dark";

function Page (props: {children?: React.ReactNode})
  {
  return <div className={cssPage}>{props.children}</div>
  }

export default Page;
