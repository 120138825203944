/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Stack, Container, Row, Col, Button, FloatingLabel, Form} from 'react-bootstrap';
/* TYPES */
import { APIRequestTemplate, APIDictionary, APIRequestTemplateItem, APIMatchedRegistration, APIPolicy, APIRegistrantFacts, APITypedAttribute, APIAttribute, APIAttributeOption} from 'common/api/types';
/* UTILITY */
import { redirect } from "common/utility/window";
/* HOOKS */
import {useRequestTemplateStore, TemplateAttributes, useApplicationContext, AppContextType} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, FlexBox, Footer, Metadata, Print} from "components/atoms/Templates";
/* CUSTOM COMPONENTS */
import useRequest, {ResultsPDF, ResultsUI} from 'pages/Requests/modules/Request';
import { SearchFilterTags, RoleCategory} from 'pages/Requests/TemplateEditor';
import FileMenu, {FileMenuDivider, FileMenuHelp, FileMenuSave, FileMenuDelete} from 'components/molecules/Menu/File';
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import Draggable, {DragItem} from 'components/molecules/Drag&Drop/Draggable';
import {TextBox, NoteBox, SelectBox} from 'components/atoms/Inputs';


////////////////////////////
// REQUEST FORM EXECUTION
////////////////////////////

interface Props
  {
  selectedRequestTemplateID: number;
  unsavedChanges: (flag: boolean) => void;
  }

const RequestForm: React.FC<Props> = (props) =>
  {
  const appContext: AppContextType = useApplicationContext();
  const requestTemplate = useRequestTemplateStore({selectedTemplateID: props.selectedRequestTemplateID});
  const request = useRequest({getField: requestTemplate.getField});
  const [attributes, setAttributes] = React.useState<DragItem[] | null>(null);
  const optionalAttributes = [TemplateAttributes.UI_Contact, TemplateAttributes.UI_Email, TemplateAttributes.UI_RequesterID, TemplateAttributes.UI_Organization, TemplateAttributes.Rule_Location, TemplateAttributes.Rule_Sensitivity, TemplateAttributes.Rule_Logging, TemplateAttributes.Rule_Processing, TemplateAttributes.UI_ProcessingNotes];

  // EFFECTS: DATA MANAGEMENT
  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(requestTemplate.unsavedChanges);
    }, [requestTemplate.unsavedChanges]);

  React.useEffect(() =>
    {
    if (!requestTemplate.data) return;
    let list: DragItem[] = Array.from(optionalAttributes).filter((item: TemplateAttributes) => appContext?.dictionary.getRequestAttributeByID(item) !== undefined).map((item: TemplateAttributes) => appContext?.dictionary.getRequestAttributeByID(item))?.map((definition: APITypedAttribute | undefined) =>
      {
      let attributeID = definition?.attribute?.id ?? 0;
      return ({ID: attributeID, title: definition?.attribute.name ?? "", category: requestTemplate.getRole(attributeID) ?? RoleCategory.USER})
      });
    setAttributes(list);
    }, [requestTemplate.data]);

  // MEMOS

  const requestOptions = React.useMemo(() => attributes?.filter(i => i.category === RoleCategory.USER) ?? [], [attributes]);
  const templateOptions = React.useMemo(() => attributes?.filter(i => i.category === RoleCategory.ADMIN) ?? [], [attributes]);

  const memoRequestOptions = requestOptions.map((item:DragItem) =>
    {
    const generateRandomID = Math.floor(100000 + Math.random() * 900000).toString();
    const checkRuleUniqueIDField = requestTemplate.getField(TemplateAttributes.Rule_UniqueID) === "1" ? true : false;
    const attributeValue: string | undefined = (item.ID !== TemplateAttributes.Rule_RequesterID) ? requestTemplate.getField(item.ID) : checkRuleUniqueIDField ? generateRandomID : requestTemplate.getField(item.ID);
    const definition: APITypedAttribute = appContext?.dictionary.getRequestAttributeByID(item.ID) ?? {} as APITypedAttribute;
    const hasOptions = Boolean(definition.attribute.values.length);
    switch (true)
      {
      case (hasOptions): return <SelectBox key={`task-${item.ID}`} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ?? false} />;
      case (item.ID === TemplateAttributes.UI_Email): return <TextBox key={`task-${item.ID}`} type="email" value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ?? false} />;
      default: return <TextBox key={`task-${item.ID}`} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ?? false} />;
      }
    });

  const memoTemplateOptions = templateOptions.map((item:DragItem) =>
    {
    const attributeValue: string | undefined = requestTemplate.getField(item.ID);
    const definition: APITypedAttribute = appContext?.dictionary.getRequestAttributeByID(item.ID) ?? {} as APITypedAttribute;
    const hasOptions = Boolean(definition.attribute.values.length);
    switch (true)
      {
      case (hasOptions): return <SelectBox key={`task-${item.ID}`} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={true} required={requestTemplate.getRequire(item.ID) ?? false} />;
      case (item.ID === TemplateAttributes.UI_Email): return <TextBox key={`task-${item.ID}`} type="email" value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={true} required={requestTemplate.getRequire(item.ID) ?? false} />;
      default: return <TextBox key={`task-${item.ID}`} value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={true} required={requestTemplate.getRequire(item.ID) ?? false} />;
      }
    });

  const rules_search = [TemplateAttributes.Rule_DNS, TemplateAttributes.Rule_Email, TemplateAttributes.Rule_Forensic, TemplateAttributes.Rule_Name, TemplateAttributes.Rule_Org, TemplateAttributes.Rule_Phone, TemplateAttributes.Rule_RegOp, TemplateAttributes.Rule_Location];
  const rules_requirements = [TemplateAttributes.Rule_UniqueID];

  const rulesInclude: APIAttribute[] = rules_search.map((item:TemplateAttributes) => requestTemplate.getDefinitionByID(item)?.attribute)?.filter((i: APIAttribute | undefined): i is APIAttribute => i !== undefined);
  const rulesRequirements: APIAttribute[] = rules_requirements.map((item:TemplateAttributes) => requestTemplate.getDefinitionByID(item)?.attribute).filter((i: APIAttribute | undefined): i is APIAttribute => i !== undefined);

  // RENDER
  const gridColumn = "d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-3";

  return (!requestTemplate.data ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" /> : <Page>
    <Header>
      <EditableLabel value={requestTemplate.title} onValueChange={requestTemplate.setTitle} />
      <div className="ms-auto"></div>
      <Alert variant="warning" className="d-block m-0 ms-auto my-0 px-3 py-2 fs-6 lh-base"> Under Construction </Alert>
      <Button variant='dark' onClick={() => redirect(`#/request_template/${props.selectedRequestTemplateID}`)}><i className="bi bi-pencil-square"></i> Edit</Button>
      <FileMenu dirtyFlag={requestTemplate.unsavedChanges}>
        <FileMenuSave onClick={requestTemplate.eventSave} />
        <FileMenuDelete onClick={requestTemplate.eventDelete} />
        <FileMenuDivider />
        <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#requests.md" />
        </FileMenu>
      </Header>
    <Main>
      <Section>
        <Row className="mb-3">
          <Col className={gridColumn}>
            <h4> Request </h4>
            </Col>
          <Col className={gridColumn}>
            <h4> Reference </h4>
            </Col>
          </Row>
        <Row>
          <Col className={gridColumn}>
            <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.UI_Date) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.UI_Date)} required={true} />
            <TextBox type="url" value={requestTemplate.getField(TemplateAttributes.UI_Domain) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.UI_Domain)} required={true} />
            {memoRequestOptions}
            <div className="mt-3"></div>
            <h6> Data included in search results:</h6>
            <SearchFilterTags
              rulesInclude={rulesInclude}
              getField={requestTemplate.getField}
              />
            </Col>
          <Col className={gridColumn}>
            <TextBox value={requestTemplate.getField(TemplateAttributes.Header_Description)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Header_Description)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Header_Description) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Header_AccredAuth)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Header_AccredAuth)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Header_AccredAuth) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Expected_Fields)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Expected_Fields)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Expected_Fields) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Identity_Service)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Identity_Service)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Identity_Service) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Accredting_Enforcement)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Accredting_Enforcement)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Accredting_Enforcement) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Purposes_Protection)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Purposes_Protection)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Purposes_Protection) ?? false} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Jurisdictions)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Jurisdictions)} disabled={true} required={requestTemplate.getRequire(TemplateAttributes.Jurisdictions) ?? false} />
            {memoTemplateOptions}
            </Col>
          </Row>
        </Section>
      <Section>
        <Row>
          <Col className={gridColumn}>
            <ResultsUI getField={requestTemplate.getField} registrationList={request.registrationList} factsList={request.factsList} policyList={request.policyList} hasData={request.hasData} initialize={request.initialize} execute={request.execute} />
            </Col>
          </Row>
        </Section>
      <Metadata>
        <Row className="mb-3">
          <Col className={gridColumn}>
            <h6>Organization:</h6>
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_OrgName)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_OrgName)} disabled={true} />
            <SelectBox value={requestTemplate.getField(TemplateAttributes.Metadata_OrgType)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_OrgType)} disabled={true} />
            </Col>
          <Col className={gridColumn}>
            <h6>Primary Contact:</h6>
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_PrimePOC)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_PrimePOC)} disabled={true} />
            <TextBox type="email" value={requestTemplate.getField(TemplateAttributes.Metadata_PrimeEmail) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_PrimeEmail)} disabled={true} />
            </Col>
          <Col className={gridColumn}>
            <h6>Alternate Contact:</h6>
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_AltPOC)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_AltPOC)} disabled={true} />
            <TextBox type="email" value={requestTemplate.getField(TemplateAttributes.Metadata_AltEmail) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_AltEmail)} disabled={true} />
            </Col>
          </Row>
        <Row>
          <Col className={gridColumn}>
            <h6>Version:</h6>
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Version)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Version)} disabled={true} />
            <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.Metadata_Updated) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Updated)} disabled={true} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Completion)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Completion)} disabled={true} />
            </Col>
          <Col className={gridColumn}>
            <h6>Publication:</h6>
            <TextBox type="date" value={requestTemplate.getField(TemplateAttributes.Metadata_Date) ?? ""} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Date)} disabled={true} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Status)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Status)} disabled={true} />
            <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Distribution)} eventChange={requestTemplate.setField} definition={appContext?.dictionary.getRequestAttributeByID(TemplateAttributes.Metadata_Distribution)} disabled={true} />
            </Col>
          <Col className={gridColumn}>&nbsp;</Col>
          </Row>
        </Metadata>
      </Main>
      <Print>
        <ResultsPDF getField={requestTemplate.getField} registrationList={request.registrationList} factsList={request.factsList} policyList={request.policyList} hasData={request.hasData} initialize={request.initialize} execute={request.execute} />
        </Print>
      <Footer />
    </Page>);
  }

export default RequestForm;


/* Developer Notes:

@web/src/pages/Requests/RequestForm.tsx:1-249 is a React component that renders a form for creating or editing a request template. The component takes two inputs: selectedRequestTemplateID (the ID of the request template to be edited) and dataDictionary (an object containing definitions for various attributes used in the form).

The purpose of this code is to provide a user interface for managing request templates. A request template is a predefined set of fields and rules that can be used to generate a request for accessing certain data or services. The component fetches the selected request template from the server and displays its fields in a form. The user can modify the values of these fields and save the changes back to the server.

The component achieves its purpose through the following logic and algorithm:

 * It fetches the selected request template from the server using an API call (/api/request_template/${props.selectedRequestTemplateID}).
 * It initializes various state variables to store the template data, field values, and other related information.
 * It defines several helper functions and callbacks to handle user interactions, such as updating field values, executing the request, saving the template, and exporting the template as a PDF.
 * It renders the form UI using various input components (e.g., TextInput, SelectInput, DateInput) and displays the template fields based on the data dictionary.
 * When the user interacts with the form (e.g., modifies a field value), the corresponding state variables are updated, and the component re-renders with the new data.
 * When the user clicks the "Submit" button, the eventExecuteRequest callback is triggered, which fetches and displays the search results based on the form data.
 * The user can save the template by clicking the "Save" button, which triggers the eventSaveTemplate callback and sends the updated template data to the server.
 * The user can also export the template as a PDF by clicking the "Print" button, which triggers the eventExportPDF callback and generates a PDF file based on the template data and search results.

Important logic flows and data transformations happening in the code include:

 * Initializing the component state based on the fetched template data and data dictionary.
 * Updating the component state when the user modifies form fields.
 * Transforming the state data into the format expected by the server API for saving and fetching templates.
 * Fetching and displaying search results based on the form data.
 * Generating a PDF file from the template data and search results.

Overall, this code provides a user-friendly interface for managing request templates, allowing users to create, edit, and export templates with various fields and rules.

*/


