/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { APIRequester, APIRequesterGroup, APIRegistrarGroup, APIRequestTemplate, APIRegistrar, APIRequesterGroupMembership} from 'common/api/types';
import {TableRowData} from "pages/Registrars/modules/GroupTable";
/* HOOKS */
import {useAxios} from 'hooks';

const emptyTable: TableRowData = {key: 0, description: "", objectData: "", templates: ""};

export type RequesterGroupStore =
  {
  requesterGroup: APIRequesterGroup;
  registrarGroups: APIRegistrarGroup[];
  requestTemplates: APIRequestTemplate[];
  registrars: APIRegistrar[];
  requesterGroups: APIRequesterGroup[];
  TableData: TableRowData[];
  refresh: () => void;
  updateTitle: (newTitle: string) => void;
  updateObligations: (newObligations: string) => void;
  };

function useRequesterGroupEditor(props:{selectedID:number}): RequesterGroupStore
  {
  // read-only API request responses
  const [resRegistrarGroupMembers, errorRegistrarGroupMembers, loadingRegistrarGroupMembers, callAxiosRegistrarGroupMembers] = useAxios({method: 'GET', url: "/api/registrar_group_member_query_all"});
  const [resTemplates, errorTemplates, loadingTemplates, callAxiosTemplates] = useAxios({ method: 'GET', url: "/api/request_templates", });
  const [resRegistrarGroups, errorRegistrarGroups, loadingRegistrarGroups, callAxiosRegistrarGroups] = useAxios({method: "GET", url: `/api/requester_group/${props.selectedID}/registrar_groups`});
  // states
  const [registrarGroups, setRegistrarGroups] = React.useState<APIRegistrarGroup[]>([]);
  const [requestTemplates, setRequestTemplates] = React.useState<APIRequestTemplate[]>([]);
  const [registrars, setRegistrars] = React.useState<APIRegistrar[]>([]);
  const [requesterGroups, setRequesterGroups] = React.useState<APIRequesterGroup[]>([]);
  const [requesterGroup, setRequesterGroup] = React.useState<APIRequesterGroup>({} as APIRequesterGroup);

  React.useEffect(() =>
    {
    if (resRegistrarGroups) setRegistrarGroups(resRegistrarGroups.registrar_groups?.sort((a:APIRegistrarGroup, b:APIRegistrarGroup) => a.description.localeCompare(b.description)) ?? []);
    }, [resRegistrarGroups]);

  React.useEffect(() =>
    {
    if (resRegistrarGroupMembers)
      {
      setRequesterGroups(resRegistrarGroupMembers.registrar_group_members.requester_groups?.sort((a:APIRequesterGroup, b:APIRequesterGroup) => a.name.localeCompare(b.name)) ?? []);
      setRegistrars(resRegistrarGroupMembers.registrar_group_members.registrars?.sort((a:APIRegistrar, b:APIRegistrar) => a.name.localeCompare(b.name)) ?? []);
      setRequesterGroup(resRegistrarGroupMembers.registrar_group_members.requester_groups?.filter((rqG:APIRequesterGroup) => rqG.id === props.selectedID)[0] ?? {});
      }
    }, [resRegistrarGroupMembers]);

  React.useEffect(() =>
    {
    if (resTemplates) setRequestTemplates(resTemplates.request_templates.sort((a:APIRequestTemplate, b:APIRequestTemplate) => a.name.localeCompare(b.name)) ?? []);
    }, [resTemplates]);

  const TableData: TableRowData[] = registrarGroups.map((rrG) =>
    {
    const key = rrG.id;
    const description = rrG.description;
    const regisrars = rrG.registrar_ids.length ? rrG.registrar_ids.map((rrID) => registrars.find((rr:APIRegistrar) => rr.id === rrID)?.name).join(", ") : "[None]";
    const templates = rrG.request_template_ids.length ? rrG.request_template_ids?.map((reqTempID) => requestTemplates.find((reqTemp: APIRequestTemplate) => reqTemp.id === reqTempID)?.name).join(", ") : "[None]";
    return ({key: key, description: description, objectData: regisrars, templates: templates});
    }) ?? [emptyTable];

  const refresh = () =>
    {
    callAxiosRegistrarGroupMembers();
    callAxiosTemplates();
    callAxiosRegistrarGroups();
    }

  // function updateRequesterGroup(data: APIRequesterGroup)
  //   {
  //   setRequesterGroup(data);
  //   }

  // function updateRegistrarGroups(data: APIRegistrarGroup[])
  //   {
  //   setRegistrarGroups(data);
  //   }

  function updateTitle (newTitle: string)
    {
    setRequesterGroup((prev: APIRequesterGroup) => {const rqG = {...prev}; rqG.name = newTitle; return rqG;});
    }

  function updateObligations (newObligations: string)
    {
    setRequesterGroup((prev: APIRequesterGroup) => {const rqG = {...prev}; rqG.obligations = newObligations; return rqG;});
    }

  return {requesterGroup, registrarGroups, requestTemplates, registrars, requesterGroups, TableData, refresh, updateTitle, updateObligations} as RequesterGroupStore;
  }

export default useRequesterGroupEditor;