/* NODE PACKAGES */
import React from "react";
import {Spinner, Alert, Table, Container, Row, Col, Form, Button, Modal, ListGroup, ListGroupItem} from 'react-bootstrap';
/* TYPES */
import { APIRequesterGroup, APIRegistrar, APIRequestTemplate } from "common/api/types";
/* UTILITY */
import {createRegistrarGroup} from 'common/api/requests';
/* CUSTOM COMPONENTS */
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import ChecklistDropdown, {ChecklistItem} from "components/molecules/Menu/Checklist";

//////////////////////////////////////////////////////////
// VALIDATION FEEDBACK
//////////////////////////////////////////////////////////

function RequirementsFeedback (props: {validate: number;})
  {
  const cssClass = "d-block m-0 px-3 py-2";
  return (props.validate)
    ? <Alert variant="success" className={cssClass}><div><strong>Requirements:</strong> Selection successfully completes the specified requirements.</div></Alert>
    : <Alert variant="warning" className={cssClass}><div><strong>Requirements:</strong> Must select at least (1) requester group, (1) registrar, and (1) request template.</div></Alert>;
  };

//////////////////////////////////////////////////////////
// GROUP CREATOR
//////////////////////////////////////////////////////////

interface RegistrarGroupCreatorProps
  {
  currentObjID: number; // The ID of the current object (either a requester group or a registrar).
  currentObjType: "RqG" | "Rr"; // The type of the current object, either "RqG" (requester group) or "Rr" (registrar).
  showModal: boolean; // Whether the modal should be shown.
  onCreateSuccess: () => void; // A callback function to be called when a new registrar group is successfully created.
  onCloseModal: () => void; // A callback function to be called when the modal is closed.
  requesterGroups: APIRequesterGroup[]; // An array of requester groups to display in the modal.
  registrars: APIRegistrar[]; // An array of registrars to display in the modal.
  requestTemplates: APIRequestTemplate[]; // An array of request templates to display in the modal.
  }

/* Renders a modal dialog for creating a new registrar group. The modal allows the user to select requester groups, registrars, and request templates to include in the new registrar group. It also allows the user to edit the description of the new registrar group. When the user clicks the "Create Registrar Group" button, the `createRegistrarGroup` function is called with the selected options, and the `onCreateSuccess` callback is invoked. When the user clicks the "Cancel" button or closes the modal, the `onCloseModal` callback is invoked. */
export function GroupCreator (props: RegistrarGroupCreatorProps)
  {
  // STATES

  const [description, setDescription] = React.useState<string>("Untitled Group");
  const [selectedRqGs, setSelectedRqGs] = React.useState<number[]>([]);
  const [selectedRrs, setSelectedRrs] = React.useState<number[]>([]);
  const [selectedReqTemps, setSelectedReqTemps] = React.useState<number[]>([]);

  // EFFECTS

  React.useEffect(() =>
    {
    setSelectedRqGs(props.currentObjType === "RqG" ? [props.currentObjID] : []);
    setSelectedRrs(props.currentObjType === "Rr" ? [props.currentObjID] : []);
    setSelectedReqTemps([]);
    }, [props]);

  // EVENT HANDLERS

  // Updates the selectedRqGs state variable by adding or removing the given requester group's ID based on whether it already exists in the array.
  function eventUpdateRequesterGroupCheckbox (rqG: APIRequesterGroup)
    {
    const newSelectedRqGs = [...selectedRqGs];
    const rqGPosInSelected = newSelectedRqGs.indexOf(rqG.id);
    rqGPosInSelected === -1 ? newSelectedRqGs.push(rqG.id) : newSelectedRqGs.splice(rqGPosInSelected, 1);
    setSelectedRqGs(newSelectedRqGs);
    };

  // Updates the selectedRrs state variable by adding or removing the given registrar's ID based on whether it already exists in the array.
  function eventUpdateRegistrarGroupCheckbox (rr: APIRegistrar)
    {
    const newSelectedRrs = [...selectedRrs];
    const rrPosInSelected = newSelectedRrs.indexOf(rr.id);
    rrPosInSelected === -1 ? newSelectedRrs.push(rr.id) : newSelectedRrs.splice(rrPosInSelected, 1);
    setSelectedRrs(newSelectedRrs);
    };

  // Updates the selectedReqTemps state variable by adding or removing the given request template's ID based on whether it already exists in the array.
  function eventUpdateRequestTemplateCheckbox (reqTemp: APIRequestTemplate)
    {
    const newSelectedReqTemps = [...selectedReqTemps];
    const reqTempPosInSelected = newSelectedReqTemps.indexOf(reqTemp.id);
    reqTempPosInSelected === -1 ? newSelectedReqTemps.push(reqTemp.id) : newSelectedReqTemps.splice(reqTempPosInSelected, 1);
    setSelectedReqTemps(newSelectedReqTemps);
    };

  // Exits the modal by resetting the selected requester groups, registrars, and request templates to their initial state, and then calling the `onCloseModal` callback provided by the parent component.
  function exitModal ()
    {
    setSelectedRqGs(props.currentObjType === "RqG" ? [props.currentObjID] : []);
    setSelectedRrs(props.currentObjType === "Rr" ? [props.currentObjID] : []);
    setSelectedReqTemps([]);
    props.onCloseModal();
    }

  // Handles the creation of a new registrar group.
  // This function is called when the user clicks the "Create Registrar Group" button in the GroupCreator modal.
  // It first checks if the required selections (requester groups, registrars, and request templates) have been made.
  // If the selections are valid, it calls the `createRegistrarGroup` function with the selected data, and then calls the `onCreateSuccess` callback provided by the parent component.
  // Finally, it calls the `exitModal` function to close the modal.
  const eventCreateRegistrarGroup = () =>
    {
    if (!selectionHasRequirements()) return;
    createRegistrarGroup(description, selectedRqGs, selectedRrs, selectedReqTemps)
      .then(response => props.onCreateSuccess())
      .finally(() => exitModal());
    }

  // Checks if the required selections (requester groups, registrars, and request templates) have been made.
  const selectionHasRequirements = () => (selectedRqGs.length && selectedRrs.length && selectedReqTemps.length);

  // RENDER

  return ((!props.registrars || !props.requestTemplates || !props.requesterGroups)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Modal scrollable={true} fullscreen={true} keyboard={true} centered show={props.showModal} onHide={exitModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Registrar Group: <EditableLabel value={"Group Name..."} onValueChange={(value:string) => setDescription (value)} /></Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h4 className="text-center"> Requester Groups </h4>
                <ListGroup>{props.requesterGroups.map((rqG) => <ListGroupItem variant="light" key={`rqG-${rqG.id}`} eventKey={`rqG-${rqG.id}`} active={selectedRqGs.some(sRqG => sRqG === rqG.id)} action={true} onClick={() => eventUpdateRequesterGroupCheckbox(rqG)}>{rqG.name}</ListGroupItem>)}</ListGroup>
                </Col>
              <Col>
                <h4 className="text-center"> Registrars </h4>
                <ListGroup>{props.registrars.map((rr) => <ListGroupItem variant="light" key={`rqG-${rr.id}`} eventKey={`rqG-${rr.id}`} active={selectedRrs.some(sRr => sRr === rr.id)} action={true} onClick={() => eventUpdateRegistrarGroupCheckbox(rr)}>{rr.name}</ListGroupItem>)}</ListGroup>
                </Col>
              <Col>
                <h4 className="text-center"> Request Templates </h4>
                <ListGroup>{props.requestTemplates.map((reqTemp) => <ListGroupItem variant="light" key={`rqG-${reqTemp.id}`} eventKey={`rqG-${reqTemp.id}`} active={selectedReqTemps.some(sReqTemp => sReqTemp === reqTemp.id)} action={true} onClick={() => eventUpdateRequestTemplateCheckbox(reqTemp)}>{reqTemp.name}</ListGroupItem>)}</ListGroup>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-end align-items-center gap-3">
          <RequirementsFeedback validate={selectionHasRequirements()} />
          <Button variant="dark" disabled={(!selectedRqGs.length || !selectedRrs.length || !selectedReqTemps.length)} onClick={() => eventCreateRegistrarGroup()}>Create Registrar Group</Button>
          <Button variant="dark" onClick={() => exitModal()}>Cancel</Button>
          </Modal.Footer>
        </Modal>);
  }

export default GroupCreator;

