/* NODE PACKAGES */
import React from'react';

const cssMain = "d-print-none d-block m-0 p-0 bg-transparent";

function Main (props: {children?: React.ReactNode})
  {
  return <main className={cssMain}>{props.children}</main>
  }

export default Main;
