/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const cssMetadata = "d-print-none d-block m-0 px-0 py-5 text-dark";
const cssStyle = {background: 'linear-gradient(0deg, #f8f9faff, #E3E4E5ff)'}

function Metadata (props: {children?: React.ReactNode})
  {
  return <section className={cssMetadata} style={cssStyle}><Container>{props.children}</Container></section>
  }

export default Metadata;
