/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { PolicyListItem, APIPolicy} from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';

// CONSTANT: Defines request configuration to fetch policy list
const GetPolicies:AxiosRequestConfig = { method: 'GET', url: '/api/policies', }

// CONSTANT: Defines the ordering of policy organization types for sorting the policy list. Lower numbers appear earlier in the sorted list.
export const ORG_TYPE_ORDERING: {[key in APIPolicy['org_type']]: number} =
  {
  "policy_authority": 1,
  "registry": 2,
  "registrar": 3
  };

export type PolicyListStore =
  {
  loading: boolean;
  error:any;
  data: PolicyListItem[] | null;
  count: number;
  filterByPolicyAuthority: PolicyListItem[] | undefined;
  filterByRegistry: PolicyListItem[] | undefined;
  filterByRegistrar: PolicyListItem[] | undefined;
  refresh: () => void;
  getByID: (policyID: number) => PolicyListItem | undefined;
  };

function usePolicyListStore(): PolicyListStore
  {
  const [response, error, loading, requestAPI] = useAxios(GetPolicies);
  const [data, setData] = React.useState<PolicyListItem[] | null>(null);

  React.useEffect(() =>
    {
    if (response) setData(response.policies?.sort(sortPolicyList) ?? []);
    }, [response]);

  const filterDeleted = (p: PolicyListItem): p is PolicyListItem => !p.deleted;

  const sortPolicyList = (a:PolicyListItem, b:PolicyListItem) => (a.org_type === b.org_type) ? a.name.localeCompare(b.name) : ORG_TYPE_ORDERING[a.org_type] - ORG_TYPE_ORDERING[b.org_type];

  return ({
    loading,
    error,
    data: data ?? null,
    count: (data?.length ?? 0),
    filterByPolicyAuthority: data?.filter((p:PolicyListItem): p is PolicyListItem => p.org_type === "policy_authority"),
    filterByRegistry: data?.filter((p:PolicyListItem): p is PolicyListItem => p.org_type === "registry"),
    filterByRegistrar: data?.filter((p:PolicyListItem): p is PolicyListItem => p.org_type === "registrar"),
    refresh: () => requestAPI(),
    getByID: (policyID: number = -1) => data?.find((p:PolicyListItem): p is PolicyListItem => p.id === policyID),
    });
  }

export default usePolicyListStore;

