/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { NamedItem } from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';

const GetRegistrantFacts:AxiosRequestConfig = { method: 'GET', url: '/api/registrant_facts_index', }

export type RegistrantFactsListStore =
  {
  loading: boolean;
  error:any;
  data: NamedItem[] | null;
  count: number;
  refresh: () => void;
  getByID: (id: number) => NamedItem | undefined;
  };

function useRegistrantFactsListStore(): RegistrantFactsListStore
  {
  const [response, error, loading, requestAPI] = useAxios(GetRegistrantFacts);
  const [data, setData] = React.useState<NamedItem[] | null>(null);

  React.useEffect(() =>
    {
    setData(response?.registrants?.sort((a:NamedItem, b:NamedItem) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  return ({
    loading,
    error,
    data,
    count: (data?.length ?? 0),
    refresh: () => requestAPI(),
    getByID: (id: number) => data?.find((item: NamedItem) => item.id === id),
    });
  }

export default useRegistrantFactsListStore;

