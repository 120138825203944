/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const cssHeader = "d-print-none d-block m-0 px-0 py-5 fs-3 lh-1";
const cssContainer = "d-flex justify-content-between align-items-center gap-3";
const cssStyle = {background: 'linear-gradient(0deg, #E3E4E5ff, #f8f9faff)'};

function Header (props: {children?: React.ReactNode})
  {
  return <header className={cssHeader} style={cssStyle}><Container className={cssContainer}>{props.children}</Container></header>
  }

export default Header;
