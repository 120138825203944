/* NODE PACKAGES */
import _ from "lodash";
import React from "react";
import {Spinner, Container, Row, Col, Alert, Form, Card, Modal, Table, FloatingLabel, Button, ButtonGroup, ListGroup, ListGroupItem} from 'react-bootstrap';
/* API */
import {APIRequesterGroup, APIRegistrarGroup, APIRegistrar, APIRequestTemplate, APIRegistrarGroupMembers} from "common/api/types";
import {getRegistrarGroupMembers, saveRegistrarGroup, deleteRegistrarGroup, addMembers, removeMembers} from 'common/api/requests';
/* UTILITY */
import { redirect } from "common/utility/window";
/* CUSTOM COMPONENTS */
//import SelectRrGListModal from "pages/Registrars/modules/SelectRrGListModal";
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import FileMenu, {FileMenuDivider, FileMenuHelp, FileMenuSave, FileMenuDelete} from 'components/molecules/Menu/File';
import {Page, Header, Main, Section, FlexBox, Footer, Metadata} from "components/atoms/Templates";
import { useRequestTemplateListStore } from "hooks";

/*
//////////////////////////////////////////////////////////
GLOBALS
//////////////////////////////////////////////////////////
*/

const currentObjType = "RqG";

export enum SELECT_MODE {ADD = 1, REMOVE = -1, INACTIVE = 0}

/*
//////////////////////////////////////////////////////////
Select RrGList Modal
//////////////////////////////////////////////////////////
*/

interface RrGListSelectorProps
  {
  rrGID: number;
  description: string;
  obligations: string;
  requesterGroups:APIRequesterGroup[];
  registrars:APIRegistrar[];
  requestTemplates:APIRequestTemplate[];
  selectMode: SELECT_MODE;
  onSubmit: (selection: APIRegistrarGroup, mode: SELECT_MODE) => void;
  showModal: boolean;
  hideModal: () => void;
  }

const SelectRrGListModal: React.FC<RrGListSelectorProps> = (props) =>
  {
  const [changedMembers, setChangedMembers] = React.useState<APIRegistrarGroup>(
    {
    id: props.rrGID,
    description: props.description,
    obligations: props.obligations,
    requester_group_ids: [],
    registrar_ids: [],
    request_template_ids: [],
    });

  const handleCheckboxClick = (id: number, type: "requester_group_ids" | "registrar_ids" | "request_template_ids", checked: boolean) =>
    {
    const list = changedMembers[type];
    const selectedPosition = list.indexOf(id);

    if (checked && selectedPosition === -1)
      {
      list.push(id);
      }
    else if (!checked && selectedPosition !== -1)
      {
      list.splice(selectedPosition, 1);
      }

    setChangedMembers({...changedMembers, [type]: list});
    };

  const renderTable = (data: (APIRequesterGroup | APIRegistrar | APIRequestTemplate)[] | null, type: "requester_group_ids" | "registrar_ids" | "request_template_ids") =>
    {
    return (<Table responsive striped bordered hover size="sm">
      <thead><tr><th className="text-bg-dark">{type === "requester_group_ids" ? "Requester Groups" : type === "registrar_ids" ? "Registrars" : "Request Templates"}</th></tr></thead>
      <tbody>
        {data?.map((item) => <tr key={`${type}_${item.id}`}><td><Form.Check type="checkbox" checked={changedMembers[type].indexOf(item.id) !== -1} label={item.name} onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleCheckboxClick(item.id, type, e.currentTarget.checked)} id={`checkbox-${item.id}`} /></td></tr>)}
        </tbody>
      </Table>);
    };

  return (!props.requesterGroups || !props.registrars || !props.requestTemplates) ? <Alert variant="warning">{"Data is not available at this time."}</Alert> : <Modal size="xl" centered scrollable show={props.showModal} onHide={props.hideModal}>
    <Modal.Header closeButton><Modal.Title>{props.selectMode === SELECT_MODE.ADD ? "Add Members" : "Remove Members"}</Modal.Title></Modal.Header>
    <Modal.Body>
      <Container fluid>
        <Row>
          <Col>{renderTable(props.requesterGroups, "requester_group_ids")}</Col>
          <Col>{renderTable(props.registrars, "registrar_ids")}</Col>
          <Col>{renderTable(props.requestTemplates, "request_template_ids")}</Col>
          </Row>
        </Container>
      </Modal.Body>
    <Modal.Footer><Button variant="dark" onClick={() => {props.onSubmit(changedMembers, props.selectMode); setChangedMembers({...changedMembers, requester_group_ids: [], registrar_ids: [], request_template_ids: []});}}>Update</Button></Modal.Footer>
    </Modal>
  }

/*
//////////////////////////////////////////////////////////
Registrar Group Editor
//////////////////////////////////////////////////////////
*/

interface RegistrarGroupEditorProps
  {
  selectedID: number | null;
  unsavedChanges: (flag: boolean) => void;
  }

export const RegistrarGroupEditor: React.FC<RegistrarGroupEditorProps> = (props) =>
  {
  // CALLBACKS
  const queryRegistrarGroupMembers = React.useCallback(() => getRegistrarGroupMembers().then(setRegistrarGroupMembers), []);
  const queryRequestTemplates = useRequestTemplateListStore();
  // STATES
  const [registrarGroupMembers, setRegistrarGroupMembers] = React.useState<APIRegistrarGroupMembers>();
  const [requestTemplates, setRequestTemplates] = React.useState<APIRequestTemplate[]>();
  const [registrarGroup, setRegistrarGroup] = React.useState<APIRegistrarGroup>();
  const [selectMode, setSelectMode] = React.useState<SELECT_MODE>(SELECT_MODE.INACTIVE);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  // MEMOS
  const selectedRegistrarGroup = React.useMemo<APIRegistrarGroup>(() => registrarGroupMembers?.registrar_groups.find((group:APIRegistrarGroup) => group.id === props.selectedID) ?? {} as APIRegistrarGroup, [registrarGroupMembers, props.selectedID]);
  const allRegistrars = React.useMemo<APIRegistrar[]>(() => registrarGroupMembers?.registrars ?? [], [registrarGroupMembers]);
  const allRequesterGroups = React.useMemo<APIRequesterGroup[]>(() => registrarGroupMembers?.requester_groups ?? [], [registrarGroupMembers]);
  const allRequestTemplates = React.useMemo<APIRequestTemplate[]>(() => requestTemplates ?? [], [requestTemplates]);
  const memberRegistrars = React.useMemo<APIRegistrar[]>(() => allRegistrars.filter((item:APIRegistrar) => registrarGroup?.registrar_ids.includes(item.id)), [registrarGroup, allRegistrars]);
  const memberRequesterGroups = React.useMemo<APIRequesterGroup[]>(() => allRequesterGroups.filter((item:APIRequesterGroup) => registrarGroup?.requester_group_ids.includes(item.id)), [registrarGroup, allRequesterGroups]);
  const memberRequestTemplates = React.useMemo<APIRequestTemplate[]>(() => allRequestTemplates.filter((item:APIRequestTemplate) => registrarGroup?.request_template_ids.includes(item.id)), [registrarGroup, allRequestTemplates]);
  const nonMemberRegistrars = React.useMemo<APIRegistrar[]>(() => allRegistrars.filter((item:APIRegistrar) => !registrarGroup?.registrar_ids.includes(item.id)), [registrarGroup, allRegistrars]);
  const nonMemberRequesterGroups = React.useMemo<APIRequesterGroup[]>(() => allRequesterGroups.filter((item:APIRequesterGroup) => !registrarGroup?.requester_group_ids.includes(item.id)), [registrarGroup, allRequesterGroups]);
  const nonMemberRequestTemplates = React.useMemo<APIRequestTemplate[]>(() => allRequestTemplates.filter((item:APIRequestTemplate) => !registrarGroup?.request_template_ids.includes(item.id)), [registrarGroup, allRequestTemplates]);

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(unsavedChanges);
    }, [unsavedChanges]);

  // EFFECT » execute the cached API request callbacks
  React.useEffect(() =>
    {
    queryRegistrarGroupMembers();
    }, [queryRegistrarGroupMembers]);

  // EFFECT » set component state data extracted from useMemo
  React.useEffect(() =>
    {
    if (!selectedRegistrarGroup || (Object.keys(selectedRegistrarGroup).length === 0)) return;
    setRegistrarGroup(selectedRegistrarGroup);
    }, [selectedRegistrarGroup]);

  // EFFECT » update property: title
  const eventUpdateTitle = React.useCallback((value: string) =>
    {
    if (!registrarGroup) return;
    setRegistrarGroup({...registrarGroup, description: value});
    setUnsavedChanges(true);
    }, [registrarGroup]);

  // EFFECT » update property: obligations
  const eventUpdateObligations = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    {
    if (!registrarGroup) return;
    setRegistrarGroup({...registrarGroup, obligations: e.target.value});
    setUnsavedChanges(true);
    }, [registrarGroup]);

  // EFFECT » update selected data to the database
  const eventSaveData = React.useCallback(() =>
    {
    if (!registrarGroup) return;
    saveRegistrarGroup(registrarGroup).finally(() => setUnsavedChanges(false));
    }, [registrarGroup]);

  // EFFECT » delete selected data from the database
  const eventDeleteData = React.useCallback(() =>
    {
    if (!registrarGroup) return;
    if (window.confirm('Are you sure you wish to delete this item?'))
    deleteRegistrarGroup(registrarGroup.id).then(() => redirect('/registrars/'));
    }, [registrarGroup]);

  const eventSubmitSelection = React.useCallback((selection: APIRegistrarGroup, mode: SELECT_MODE): void =>
    {
    // TYPE GUARDS
    if (!registrarGroup) return;
    // EXECUTE ADD/REMOVE UPDATES
    if (mode === SELECT_MODE.ADD) addMembers(selection, registrarGroup);
    if (mode === SELECT_MODE.REMOVE) removeMembers(selection);
    // QUERY DATABASE TO REFRESH COMPONENT WITH LATEST DATA
    queryRegistrarGroupMembers();
    queryRequestTemplates.refresh();
    // SET SELECTION MODE TO INACTIVE
    setSelectMode(SELECT_MODE.INACTIVE);
    }, [registrarGroup]);

  const eventAddMembers = React.useCallback(() =>
    {
    setSelectMode(SELECT_MODE.ADD);
    }, []);

  const eventRemoveMembers = React.useCallback(() =>
    {
    setSelectMode(SELECT_MODE.REMOVE);
    }, []);

  return ((!registrarGroup || !memberRequesterGroups)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <EditableLabel value={registrarGroup.description} onValueChange={eventUpdateTitle} />
          <div className="ms-auto"></div>
          <span className="d-block btn btn-outline-secondary m-0 p-1 border-0 rounded-circle fs-2 lh-1 fw-normal" onClick={eventAddMembers}><i className="bi bi-plus-lg"></i></span>
          <FileMenu dirtyFlag={unsavedChanges}>
            <FileMenuSave onClick={eventSaveData} />
            <FileMenuDelete onClick={eventDeleteData} />
            <FileMenuDivider />
            <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#registrars.md" />
            </FileMenu>
          </Header>
        <Main>
          <Section>
            <h4 className="mx-auto my-0 mb-4 p-0 fs-4 lh-1 text-center">Members</h4>
            <Container>
              <Row>
                <Col><Card>
                  <Card.Header className="d-flex justify-content-between align-items-center gap-2 m-0 px-2 py-2">{`Requesters (${memberRequesterGroups.length})`} <span className={memberRequesterGroups.length ? "btn btn-sm btn-outline-danger m-0 p-1 lh-1" : "d-none"} onClick={eventRemoveMembers}><i className="bi bi-trash"></i></span></Card.Header>
                    <ListGroup variant="flush">
                      {memberRequesterGroups.length ? memberRequesterGroups.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>) : <ListGroupItem>No members</ListGroupItem>}
                      </ListGroup>
                    {/*
                    <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({nonMemberRequesterGroups.length}) Non-members</th></tr></thead>
                      <tbody>{nonMemberRequesterGroups.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table>
                    <ListGroup variant="flush">
                      {nonMemberRequesterGroups.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>)}
                      </ListGroup>
                    <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({allRequesterGroups.length}) All</th></tr></thead>
                      <tbody>{allRequesterGroups.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table>
                    <ListGroup variant="flush">
                      {allRequesterGroups.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>)}
                      </ListGroup>
                    */}
                  </Card></Col>
                <Col><Card>
                  <Card.Header className="d-flex justify-content-between align-items-center gap-2 m-0 px-2 py-2">{`Registrars (${memberRegistrars.length})`} <span className={memberRegistrars.length ? "btn btn-sm btn-outline-danger m-0 p-1 lh-1" : "d-none"} onClick={eventRemoveMembers}><i className="bi bi-trash"></i></span></Card.Header>
                    <ListGroup variant="flush">
                      {memberRegistrars.length ? memberRegistrars.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>) : <ListGroupItem>No members</ListGroupItem>}
                      </ListGroup>
                    {/*
                    <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({nonMemberRegistrars.length}) Non-Members</th></tr></thead>
                      <tbody>{nonMemberRegistrars.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table>
                    <ListGroup variant="flush">
                      {nonMemberRegistrars.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>)}
                      </ListGroup>
                    <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({allRegistrars.length}) All</th></tr></thead>
                      <tbody>{allRegistrars.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table>
                    <ListGroup variant="flush">
                      {allRegistrars.map(item => <ListGroupItem key={`rqGID-${item.id}`}>{item.name}</ListGroupItem>)}
                      </ListGroup>
                    */}
                  </Card></Col>
                <Col><Card>
                  <Card.Header className="d-flex justify-content-between align-items-center gap-2 m-0 px-2 py-2">{`Request Templates (${memberRequestTemplates.length})`} <span className={memberRequestTemplates.length ? "btn btn-sm btn-outline-danger m-0 p-1 lh-1" : "d-none"} onClick={eventRemoveMembers}><i className="bi bi-trash"></i></span></Card.Header>
                    <ListGroup variant="flush">
                      {memberRequestTemplates.length ? memberRequestTemplates.map(item => <ListGroupItem key={`rqGID-${item.id}`} className="d-flex justify-content-between align-items-center gap-2 m-0 px-2 py-2">{item.name}</ListGroupItem>) : <ListGroupItem>No members</ListGroupItem>}
                      </ListGroup>
                    {/* <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({nonMemberRequestTemplates.length}) Non-Members</th></tr></thead>
                      <tbody>{nonMemberRequestTemplates.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table> */}
                    {/* <Table size="sm" responsive borderless className="m-0 p-0 mb-3">
                      <thead><tr><th>({allRequestTemplates.length}) All</th></tr></thead>
                      <tbody>{allRequestTemplates.map(item => <tr key={`rqGID-${item.id}`}><td>{item.name}</td></tr>)}</tbody>
                      </Table> */}
                  </Card></Col>
                </Row>
              </Container>
            </Section>
          <Metadata>
            <FloatingLabel controlId="obligationsDocument" label="Obligations Document" className="m-0 p-0">
              <Form.Control as="textarea" style={{ height: '200px' }} placeholder="Leave a comment here..." value={registrarGroup.obligations ?? ""} onChange={eventUpdateObligations} />
              </FloatingLabel>
            </Metadata>
          </Main>
        <Footer />
        <SelectRrGListModal
          showModal={selectMode !== SELECT_MODE.INACTIVE}
          hideModal={() => setSelectMode(SELECT_MODE.INACTIVE)}
          rrGID={registrarGroup.id}
          description={registrarGroup.description}
          obligations={registrarGroup.obligations}
          requesterGroups={selectMode === SELECT_MODE.ADD ? nonMemberRequesterGroups : memberRequesterGroups}
          registrars={selectMode === SELECT_MODE.ADD ? nonMemberRegistrars : memberRegistrars}
          requestTemplates={selectMode === SELECT_MODE.ADD ? nonMemberRequestTemplates : memberRequestTemplates}
          selectMode={selectMode}
          onSubmit={eventSubmitSelection} />
        </Page>);
  }

export default RegistrarGroupEditor;

/* Development Notes:

@web/src/pages/Registrars/RegistrarGroupEditor.tsx:1-257 is a React component that provides a user interface for managing and editing a "Registrar Group" entity. A Registrar Group is likely a collection or group of individual Registrars (which are organizations responsible for managing domain registrations) within the application's domain.

The component takes an input prop called selectedRegistrarGroupID, which is expected to be a number or null value. This prop is used to identify the specific Registrar Group that should be loaded and displayed in the component.

The purpose of this code is to fetch data related to the selected Registrar Group from an API, display that data in a form-like interface, and allow the user to make changes to the Registrar Group's properties. The component also provides functionality to save the changes back to the API, create a new Registrar Group if no existing one was selected, and manage the members (Requester Groups, Registrars, and Request Templates) that belong to the Registrar Group.

Here's a breakdown of the logic flow:

The component initializes several state variables using the useState hook. These state variables will store data fetched from the API, loading indicators, and other relevant information related to the Registrar Group and its members.

When the component mounts, it checks if a selectedRegistrarGroupID prop was provided. If so, it fetches the corresponding Registrar Group data from the API using a function called getRegistrarGroup.

The fetched Registrar Group data is stored in the registrarGroup state variable.

The component also fetches data related to the Registrar Group's members, such as Requester Groups, Registrars, and Request Templates, and stores them in their respective state variables (allRqGs, allRrs, and allReqTemps).

The component renders a user interface based on the fetched data. This interface likely includes input fields or forms where the user can modify the Registrar Group's properties, such as its description or obligations document.

The component also renders tables or lists displaying the members (Requester Groups, Registrars, and Request Templates) that belong to the selected Registrar Group.

The user can interact with the interface to modify the Registrar Group's properties or add/remove members from the group.

When the user makes changes to the input fields or group membership, the component updates its state accordingly using the useState hook's update functions.

The component provides buttons or other UI elements that allow the user to save the changes or create a new Registrar Group. When the user interacts with these elements, the component calls functions (likely defined elsewhere in the codebase) to send the updated Registrar Group data back to the API.

The component also includes functionality to add or remove members (Requester Groups, Registrars, and Request Templates) from the Registrar Group. This functionality is likely triggered by buttons or modals, and it involves calling other components or functions to handle the addition or removal process.

Throughout the process, the component manages loading indicators and error states to provide feedback to the user about the status of API requests and data fetching operations.

The code also includes a feature to check for unsaved changes before allowing the user to navigate away from the page, using the checkUnsavedBeforeUnload function.

The code achieves its purpose by leveraging React's component-based architecture, hooks for state management and lifecycle methods, and integration with an API for fetching and updating data. It follows a common pattern of fetching data on component mount, rendering a user interface based on that data, allowing user input to modify the data, and providing mechanisms to persist those changes back to the API or manage related entities (in this case, the members of the Registrar Group).

Finally, the last line of the code (export default RegistrarGroupEditor;) exports the RegistrarGroupEditor component as the default export of the module, allowing it to be imported and used elsewhere in the codebase.

*/
