/* NODE PACKAGES */
import React from 'react';
import _ from 'lodash';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
/* API */
import {APIDictionary, APIPolicy, APIRuleAttribute, APIRule, PolicyListItem } from "common/api/types";
import {getRuleForElement, savePolicy} from "common/api/requests";
/* HOOKS */
import {requestAPI, useAxios, DataDictionaryStore, useApplicationContext, AppContextType} from 'hooks';
/* UTILITIES */
import { redirect } from "common/utility/window";
import  { sortPolicyList } from "common/api/requests";

/////////////////////////////////////////////////
// POLICIES
/////////////////////////////////////////////////

export type PolicyStore =
  {
  data: APIPolicy | null;
  unsavedChanges: boolean;
  updatePolicy: (policyID: number) => void;
  updateData: (newValues: Partial<APIPolicy>) => void;
  clearData: () => void;
  saveData: () => void;
  getRule: (elementID: number) => APIRule | undefined;
  getScopeAttribute: (attID: number) => APIRuleAttribute | undefined;
  getRuleForElement: (elementID: number) => APIRule | undefined;
  };

interface PolicyDataProps
  {
  selectedPolicyID: number;
  }

function usePolicyStore(props: PolicyDataProps): PolicyStore
  {
  const [response, error, loading, requestAPI] = useAxios();
  const [data, setData] = React.useState<APIPolicy | null>(null);
  const application = useApplicationContext();

  React.useEffect(() => updatePolicy(props.selectedPolicyID), [props.selectedPolicyID]);

  React.useEffect(() => (response) ? setData(response.policy) : setData(null), [response]);

  React.useEffect(() => {application.unsavedChanges.current = true}, [data]);

  function updatePolicy (policyID: number = props.selectedPolicyID)
    {
    (policyID === -1) ? setData(null) : requestAPI({ method: 'GET' , url: `/api/policy/${policyID}`});
    }

  function updateData (newValues:Partial<APIPolicy>)
    {
    if (!data) return;
    const newPolicy = Object.assign({}, data, newValues);
    setData(newPolicy as APIPolicy);
    }

  function clearData ()
    {
    setData(null);
    }

  function saveData ()
    {
    if (data && application) savePolicy(data).finally(() => {application.unsavedChanges.current = false;});
    }

  function getRule (elementID:number)
    {
    return data?.rules?.find((r: APIRule) => r.element_id === elementID);
    }

  function getScopeAttribute (attID:number): APIRuleAttribute | undefined
    {
    return data?.scope_attributes?.filter((policyAtt:APIRuleAttribute) => policyAtt.attribute_id === attID).at(0);
    }

  function getRuleForElement (elementID: number): APIRule | undefined
    {
    return data?.rules?.find((r: APIRule) => r.element_id === elementID);
    }

  return ({
    data,
    unsavedChanges: application?.unsavedChanges.current ?? false,
    updatePolicy,
    updateData,
    clearData,
    saveData,
    getRule,
    getScopeAttribute,
    getRuleForElement
    });
  }

export default usePolicyStore;
