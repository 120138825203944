import React from 'react';
import ReactDOM from 'react-dom';
import {Spinner, Alert, Button, ButtonToolbar} from 'react-bootstrap';

import styles from './ConfirmDialog.module.css';

////////////////////////////////
// Confirm Dialog
////////////////////////////////

interface ConfirmDialogProps
  {
  header?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
  }

const cssFlexRow = "d-flex justify-content-center align--items-center gap-2 m-0 px-0 py-2";
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ header, message, onConfirm, onCancel }) =>
  {
  return ReactDOM.createPortal(<div className={styles['confirmDialogOverlay']}>
    <Alert variant="light" dismissible onClose={onCancel} className={styles['confirmDialog']}>
      {header ? <Alert.Heading>{header}</Alert.Heading> : null}
      {message ? <div className="m-0 px-0 py-2 border-0 fs-6">{message}</div> : null}
      <ButtonToolbar className={cssFlexRow}>
        <Button variant="outline-dark" onClick={onConfirm}>OK</Button>
        <Button variant="outline-dark" onClick={onCancel}>Cancel</Button>
        </ButtonToolbar>
        </Alert>
    </div>, document.body);
  };

export default ConfirmDialog;
