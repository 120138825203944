/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const cssSection = "d-print-none d-block m-0 px-0 py-5 text-dark";
const cssStyle = {background: 'linear-gradient(0deg, #f8f9faff, #ffffffff)'};

function Section (props: {className?: string, children?: React.ReactNode})
  {
  return <section className={props.className ?? cssSection} style={cssStyle}><Container>{props.children}</Container></section>
  }

export default Section;
