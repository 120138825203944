/* NODE PACKAGES */
import React from 'react';
import { Button, Table, Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
/* TYPES */
import { APIPolicy, PolicyVersionReference } from 'common/api/types';
/* UTILITY */
import { redirect } from "common/utility/window";
/* CUSTOM COMPONENTS */
import {TextBox, NoteBox, SelectBox, CheckBox} from 'components/atoms/Inputs';

interface PolicyMetaDataPrinterProps
  {
  policy: APIPolicy;
  }

export const PolicyMetaDataPrinter: React.FC<PolicyMetaDataPrinterProps> = (props) =>
  {
  const cssColumnA = "fs-5 col-2 text-end";
  const cssColumnB = "fs-5 col-10 text-start";
  return (!props.policy) ? null : <Table borderless size="sm" className="font-monospace m-0 p-0">
    <tbody>
    <tr>
      <td className={cssColumnA}>Policy Name:</td>
      <td className={cssColumnB}>{props.policy.name}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Status:</td>
      <td className={cssColumnB}>{props.policy.status}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Effective Date:</td>
      <td className={cssColumnB}>{props.policy.effective_date}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Distribution:</td>
      <td className={cssColumnB}>{props.policy.distribution}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Completion:</td>
      <td className={cssColumnB}>{props.policy.completion}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Version:</td>
      <td className={cssColumnB}>{props.policy.version}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Organization: </td>
      <td className={cssColumnB}></td>
      </tr>
    <tr>
      <td className={cssColumnA}>Organization Name: </td>
      <td className={cssColumnB}>{props.policy.org_name}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Organization Type:</td>
      <td className={cssColumnB}>{props.policy.org_type}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Prime Contact: </td>
      <td className={cssColumnB}></td>
      </tr>
    <tr>
      <td className={cssColumnA}>Name: </td>
      <td className={cssColumnB}>{props.policy.prime_poc}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Email: </td>
      <td className={cssColumnB}>{props.policy.prime_email}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Alternate Contact:</td>
      <td className={cssColumnB}></td>
      </tr>
    <tr>
      <td className={cssColumnA}>Name:</td>
      <td className={cssColumnB}>{props.policy.alt_poc} </td>
      </tr>
    <tr>
      <td className={cssColumnA}>Email:</td>
      <td className={cssColumnB}>{props.policy.alt_email}</td>
      </tr>
    <tr>
      <td className={cssColumnA}>Notes:</td>
      <td className={cssColumnB}>{props.policy.notes}</td>
      </tr>
    </tbody>
    </Table>
  }

interface PolicyMetaDataProps
  {
  policy: APIPolicy;
  eventCopyAsNewVersion: () => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  }

const PolicyMetaData: React.FC<PolicyMetaDataProps> = (props) =>
  {
  const onChangeOrganizationName = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "org_name": e.target.value });
  const onChangePrimePOC = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "prime_poc": e.target.value });
  const onChangePrimeEmail = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "prime_email": e.target.value });
  const onChangeAlternatePOC = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "alt_poc": e.target.value });
  const onChangeAlternateEmail = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "alt_email": e.target.value });
  const onChangePolicyNotes = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "notes": e.target.value });
  const onChangeEffectiveDate = (e: React.ChangeEvent<HTMLInputElement>) => props.updatePolicy({ "effective_date": e.target.value });
  const onChangePolicyDistribution = (e: React.ChangeEvent<HTMLSelectElement>) => props.updatePolicy({ "distribution": e.target.value as typeof props.policy.distribution });
  const onChangePolicyCompletion = (e: React.ChangeEvent<HTMLSelectElement>) => props.updatePolicy({ "completion": e.target.value === "final" ? "final" : "draft" });

  const onChangeOrganizationType = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
    const newValue = e.target.value;
    if (newValue !== "registry" && newValue !== "registrar" && newValue !== "policy_authority") return;
    props.updatePolicy({ "org_type": newValue });
    };

  const onChangePolicyStatus = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
    switch (true)
      {
      case e.target.value === "proposed": props.updatePolicy({ "status": "proposed" }); break;
      case e.target.value === "planned": props.updatePolicy({ "status": "planned" }); break;
      case e.target.value === "actual": props.updatePolicy({ "status": "actual" }); break;
      case e.target.value === "certified": props.updatePolicy({ "status": "certified" }); break;
      default: break;
      }
    };

  const onChangePolicyVersion = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
    const selectedPolicyID = parseInt(e.target.value);
    if (selectedPolicyID !== props.policy.id) redirect(`/policy/${selectedPolicyID}`);
    };

  return (!props.policy) ? null : <Container>
    <Row className="m-0 p-0 mb-4 gap-3">
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>Organization</h6>
        <FloatingLabel controlId="policy_org_name" label="Organization Name">
          <Form.Control type="text" placeholder="Name" value={props.policy.org_name} onChange={onChangeOrganizationName} />
          </FloatingLabel>
        <FloatingLabel controlId='policy_org_type' label="Organization Type">
          <Form.Select aria-label="Default select example" value={props.policy.org_type} onChange={onChangeOrganizationType}>
            <option value="policy_authority">Policy Authority</option>
            <option value="registry">Registry</option>
            <option value="registrar">Registrar</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>Primary Contact</h6>
        <FloatingLabel controlId="policy_prime_poc" label="First and Last Name">
          <Form.Control type="text" placeholder="Contact's Name" value={props.policy.prime_poc} onChange={onChangePrimePOC} />
          </FloatingLabel>
        <FloatingLabel controlId="policy_prime_email" label="Email Address">
          <Form.Control type="email" placeholder="contact@domain.com" value={props.policy.prime_email} onChange={onChangePrimeEmail} />
          </FloatingLabel>
        </Col>
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>Alternate Contact</h6>
        <FloatingLabel controlId="policy_alt_poc" label="First and Last Name">
          <Form.Control type="text" placeholder="First and Last Name" value={props.policy.alt_poc} onChange={onChangeAlternatePOC} />
          </FloatingLabel>
        <FloatingLabel controlId="policy_alt_email" label="Email Address">
          <Form.Control type="email" placeholder="Email Address" value={props.policy.alt_email} onChange={onChangeAlternateEmail} />
          </FloatingLabel>
        </Col>
      </Row>
    <Row className="m-0 p-0 gap-3">
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>Publication</h6>
        <FloatingLabel controlId="policy_status" label="Intended Use:">
          <Form.Select aria-label="policy status" value={props.policy.status} onChange={onChangePolicyStatus}>
            <option value="proposed">Proposed</option>
            <option value="planned">Planned</option>
            <option value="actual">Actual</option>
            <option value="certified">Certified</option>
            </Form.Select>
          </FloatingLabel>
        <FloatingLabel controlId="effective_date" label="Effective Date:">
          <Form.Control type="date" value={props.policy.effective_date} onChange={onChangeEffectiveDate} />
          </FloatingLabel>
        <FloatingLabel controlId="policy_distribution" label="Distribution">
          <Form.Select aria-label="distribution" value={props.policy.distribution} onChange={onChangePolicyDistribution}>
            <option value="ø">ø</option>
            <option value="Internal">Internal</option>
            <option value="BBQ">BBQ</option>
            <option value="No attrib">No attrib</option>
            <option value="Public">Public</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>Notes</h6>
        <FloatingLabel controlId='policy_notes' label="Add comments here:">
          <Form.Control as="textarea" style={{ minHeight: '12.5em' }} placeholder="Lorem ipsum dolor sit amet, consectetur adip" value={props.policy.notes || ""} onChange={onChangePolicyNotes} />
          </FloatingLabel>
        </Col>
      <Col className="d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0">
        <h6>{`Version - ${new Date(props.policy.updated_date).toLocaleString()}`}</h6>
        <FloatingLabel controlId="policy_completion" label="Completion:">
          <Form.Select aria-label="Default select example" value={props.policy.completion} onChange={onChangePolicyCompletion}>
            <option value="draft">Draft</option>
            <option value="final">Final</option>
            </Form.Select>
          </FloatingLabel>
        <FloatingLabel controlId="policy_version" label={`Version: ${props.policy.version}`}>
          <Form.Select aria-label="version" defaultValue={props.policy?.id} onChange={onChangePolicyVersion}>
            {props.policy?.versions?.map((v: PolicyVersionReference) => <option key={v.id} value={v.id}>{v.version}</option>)}
            </Form.Select>
          </FloatingLabel>
        <Button variant="dark" onClick={props.eventCopyAsNewVersion}>Copy as New Version</Button>
        </Col>
      </Row>
    </Container>;
  };

export default PolicyMetaData;