/* NODE PACKAGES */
import React from "react";
import {Spinner, Container, Badge} from 'react-bootstrap';
/* API */
import { APIRequestTemplate } from "common/api/types";
import {createNewRequestTemplate} from "common/api/requests";
/* HOOKS */
import {useRequestTemplateListStore} from "hooks";
/* CUSTOM COMPONENTS */
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";
import {Page, Header, Main, Section, FlexBox, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// TEMPLATES INDEX LISTING
///////////////////////////////////////

const Templates = () =>
  {
  const requestTemplateList = useRequestTemplateListStore();
  const dataList = React.useMemo<IndexListItem[]>(() => (!requestTemplateList.data || requestTemplateList.error || requestTemplateList.loading) ? [] : requestTemplateList.data.map((template:APIRequestTemplate) => ({href: `/#/request_template/${template.id}`, name: template.name})), [requestTemplateList]);

  const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return ((requestTemplateList.loading || requestTemplateList.error)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{`Request Templates`} <small className="text-muted">{`(${dataList?.length ?? 0})`}</small></span>
          <div className="ms-auto"></div>
          <span className={cssIcon} onClick={createNewRequestTemplate}><i className="bi bi-plus-lg"></i></span>
          </Header>
        <Main>
          <Section>
            <IndexListing data={dataList} />
            </Section>
          </Main>
        <Footer />
        </Page>);
  }

export default React.memo(Templates);

/*
Developer Notes:

 */