/* NODE PACKAGES */
import React from 'react';
import {Spinner, Badge, Container} from 'react-bootstrap';
/* TYPES */
import {APIRegistration} from 'common/api/types';
import {createNewRegistration} from 'common/api/requests';
/* HOOKS */
import {useRegistrationListStore, RegistrationListStore} from 'hooks';
/* CUSTOM COMPONENTS */
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";
/* TEMPLATES */
import {Page, Header, Main, Section, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// REGISTRATIONS INDEX LISTING
///////////////////////////////////////

function Registrations ()
  {
  const registrationList = useRegistrationListStore();

  const dataList = React.useMemo<IndexListItem[]>(() => (!registrationList.data || registrationList.error || registrationList.loading) ? [] : registrationList.data.map((registration:APIRegistration) => ({href: `/#/registration/${registration.id}`, name: registration.name})), [registrationList]);

  const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return ((registrationList.loading || registrationList.error)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{`Registrations`} <small className="text-muted">{`(${dataList?.length ?? 0})`}</small></span>
          <div className="ms-auto"></div>
          <span className={cssIcon} onClick={createNewRegistration}><i className="bi bi-plus-lg"></i></span>
          </Header>
        <Main>
          <Section>
            <IndexListing data={dataList} />
            </Section>
          </Main>
        <Footer />
        </Page>);
  }

export default Registrations;

/* Development Notes:



*/