/* NODE PACKAGES */
import React from "react";
import {Spinner, Alert, Container, Row, Col} from 'react-bootstrap';
/* API */
import {APIRequesterGroup, APIRegistrar, APIRequestTemplate, APIRegistrarGroup} from "common/api/types";
import {getRegistrarGroups} from "common/api/requests";
/* HOOKS */
import { useAxios, useRegistrarGroupMembersStore} from "hooks";
/* CUSTOM COMPONENTS */
import GroupTable, {TableRowData} from "pages/Registrars/modules/GroupTable";
import GroupCreator from "pages/Registrars/modules/GroupCreator";
import {Page, Header, Main, Section, FlexBox, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

const currentObjType = "Rr";

///////////////////////////////////////
// REGISTRAR EDITOR
///////////////////////////////////////

interface RegistrarEditorProps
  {
  selectedRegID: number;
  unsavedChanges: (flag: boolean) => void;
  }

function RegistrarEditor (props: RegistrarEditorProps)
  {
  // DATA (READ ONLY)
  //const [RgGroupMembersData, RgGroupMembersError, RgGroupMembersLoading] = useAxios({url: "/api/registrar_group_member_query_all", method: "GET"});
  const registrarGroupMembers = useRegistrarGroupMembersStore();
  const [ReqTempsData, ReqTempsError, ReqTempsLoading] = useAxios({url: "/api/request_templates", method: "GET"});
  const [rrGs, rrGsError, rrGsLoading] = useAxios({url: `/api/registrar/${props.selectedRegID}/registrar_groups`, method: "GET"});
  // STATE
  const [allRqGs, setAllRqGs] = React.useState<APIRequesterGroup[] | null>(null);
  const [allRrs, setAllRrs] = React.useState<APIRegistrar[] | null>(null);
  const [allReqTemps, setAllReqTemps] = React.useState<APIRequestTemplate[] | null>(null);
  const [registrarGroups, setRegistrarGroups] = React.useState<APIRegistrarGroup[] | null>(null);
  const [dataTable, setDataTable] = React.useState<TableRowData[]>([{key: 0, description: "", objectData: "", templates: ""}]);
  const [registrar, setRegistrar] = React.useState<APIRegistrar | null>(null);
  const [isMakingRrG, setIsMakingRrG] = React.useState<boolean>(false);

  React.useEffect(() =>
    {
    if (!registrarGroupMembers.registrars || !registrarGroupMembers.requesterGroups) return;
    const rg = registrarGroupMembers.registrars.filter((reg:APIRegistrar) => reg.id === props.selectedRegID)[0];
    setRegistrar(rg);
    setAllRqGs(registrarGroupMembers.requesterGroups);
    setAllRrs(registrarGroupMembers.registrars);
    }, [registrarGroupMembers.registrars, registrarGroupMembers.requesterGroups]);

  React.useEffect(() =>
    {
    if (ReqTempsData) setAllReqTemps(ReqTempsData.request_templates);
    }, [ReqTempsData]);

  React.useEffect(() =>
    {
    if (rrGs?.registrar_groups) setRegistrarGroups(rrGs.registrar_groups);
    }, [rrGs]);

  React.useEffect(() =>
    {
    if (!registrarGroups || !allRqGs || !allRrs || !allReqTemps) return;
    const tableData = registrarGroups.map(rrG =>
      {
      const key = rrG.id;
      const description = rrG.description;
      const requesterGroups = rrG.requester_group_ids.length
        ? rrG.requester_group_ids.filter((rqG) => rqG !== undefined).map((rqGID) => allRqGs.filter((rqG) => rqG.id === rqGID)[0]?.members?.map((rr) => rr.name)).join(", ")
        : "[None]";
      const templates = rrG.request_template_ids.length
        ? rrG.request_template_ids.map((reqTempID) => allReqTemps.find((reqTemp) => reqTemp.id === reqTempID)?.name).join(", ")
        : "[None]";

      return {key: key, description: description, objectData: requesterGroups, templates: templates};
      });

    setDataTable(tableData);
    }, [registrarGroups, allRqGs, allReqTemps]);


  const handleCloseModal = () =>
    {
    setIsMakingRrG(false);
    };

  const eventCreateSuccess = () =>
    {
    if (!props.selectedRegID || !currentObjType) return;
    getRegistrarGroups(props.selectedRegID, currentObjType).then(setRegistrarGroups).finally(() => setIsMakingRrG(false));
    //if (registrar) saveNewRegistrar(registrar);
    };

  const eventCreate = () => setIsMakingRrG(true);

  // RENDER
  return ((!registrar)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{registrar.name}</span>
          <div className="ms-auto"></div>
          <span className="d-block btn btn-outline-secondary m-0 p-1 border-0 rounded-circle fs-2 lh-1 fw-normal" onClick={eventCreate}><i className="bi bi-plus-lg"></i></span>
          </Header>
        <Main>
          <Section>
            <Row>
              <Col>
                <h4 className="mx-auto my-0 mb-5 p-0 fs-4 lh-1 text-center"> Registrar Groups </h4>
                <GroupTable currentObjID={registrar.id} currentObjType={currentObjType} tableData={dataTable} />
                </Col>
              </Row>
            </Section>
          </Main>
        <Footer />
        <GroupCreator
          showModal={isMakingRrG}
          onCreateSuccess={eventCreateSuccess}
          onCloseModal={handleCloseModal}
          currentObjID={registrar.id}
          currentObjType={currentObjType}
          requesterGroups={allRqGs ?? []}
          registrars={allRrs ?? []}
          requestTemplates={ReqTempsData.request_templates} />
        </Page>);
  }

export default RegistrarEditor;

/* Developer's Notes:

@web/src/pages/Registrars/RegistrarEditor.tsx:1-142 is a React component that provides a user interface for managing and editing a "Registrar" entity. A Registrar is likely an organization or entity responsible for managing domain registrations within the application's domain.

The component takes an input prop called selectedRegID, which is expected to be a number or null value. This prop is used to identify the specific Registrar that should be loaded and displayed in the component.

The purpose of this code is to fetch data related to the selected Registrar from an API, display that data in a form-like interface, and allow the user to make changes to the Registrar's properties. The component also provides functionality to create a new "Registrar Group" (which is likely a group or collection of Registrars) from the selected Registrar.

Here's a breakdown of the logic flow:

The component initializes several state variables using the useState hook. These state variables will store data fetched from the API, loading indicators, and other relevant information related to the Registrar.

When the component mounts, it checks if a selectedRegID prop was provided. If so, it fetches the corresponding Registrar data from the API using the useAxios hook, which is likely a custom hook for making API requests.

The fetched Registrar data is stored in the registrar state variable.

The component renders a user interface based on the fetched data. This interface likely includes input fields or forms where the user can modify the Registrar's properties, such as its name.

The component also renders a table or list displaying the "Registrar Groups" that the selected Registrar belongs to.

The user can interact with the interface to modify the Registrar's properties.

When the user makes changes to the input fields, the component updates its state accordingly using the useState hook's update functions.

The component provides a button or other UI element that allows the user to create a new "Registrar Group" from the selected Registrar. When the user interacts with this element, the component likely triggers a modal or another component to handle the creation process.

The component also includes functionality to save the changes made to the Registrar's properties back to the API. This is typically done through a button or other UI element that, when clicked, calls a function (likely defined elsewhere in the codebase) to send the updated Registrar data to the API.

Throughout the process, the component manages loading indicators and error states to provide feedback to the user about the status of API requests and data fetching operations.

The code achieves its purpose by leveraging React's component-based architecture, hooks for state management and lifecycle methods, and integration with an API for fetching and updating data. It follows a common pattern of fetching data on component mount, rendering a user interface based on that data, allowing user input to modify the data, and providing mechanisms to persist those changes back to the API or create new related entities (in this case, a Registrar Group).

*/
