/* NODE PACKAGES */
import _, { Dictionary, over, parseInt, set } from 'lodash';
import React from 'react';
import { Spinner, Table, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* HOOKS */
import {PolicyStore, DataDictionaryStore, RegistrationStore, RegistrantFactsStore, useRegistryListStore, RegistryListStore} from 'hooks';
/* CUSTOM COMPONENTS */
import { APIDictionary, APIAttribute, APIAttributeOption, APIRegistration, APIRuleAttribute, APIRegistrantFactItem, APIRegistrantFacts, APIRule, APIPolicy, APIRegistryList , APIElementGroup, APIElement, RULE_ATTRIBUTES, RULE_COLLECTION} from 'common/api/types';
import { getAttributeFromRule, getRuleForElement } from 'common/api/requests';
import { updatePolicyRuleAttributeValue } from 'pages/Policies/PolicyEditor';
import {PolicyRuleCell } from 'components/organisms/PolicyRuleCell';
import DataBox from 'components/atoms/Inputs/DataBox';

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

const REG_ATTRIBUTES = {V3RQ: 14, DG: 15 };
const ANY = "15";
const GROUP_REGSCOPE = 2;
const ELEMENT_REGISTRY = 4;

///////////////////////////////////////
// HELPER: SINGLETON TEST
///////////////////////////////////////

function isSingleton (value: number)
  {
  return (value & value - 1) === 0;
  }

///////////////////////////////////////
// HELPER: RANGE TEST (partial solution)
///////////////////////////////////////

const ranges : {[key: number]: number[]; } = { 3: [1,2,3], 6: [2,3,4,6], 7: [1,2,3,4,6,7], 12: [4,6,7,8], 14: [2,3,4,8,12,14], 15: [1,2,3,4,6,7,8,12,14,15] };

///////////////////////////////////////
// REGISTRATION TABLE DATA
///////////////////////////////////////
/*
The RegistrationEditorTable.tsx component and its sub-components create a complex table for editing and displaying registration data. Here's a summary of what it does:
  * It renders a table with rows for each element in the data dictionary, grouped by element groups.
  * The table displays information about registrant facts, policy rules, and registration details for each element.
  * It allows editing of registrant facts, validation requests (VRQ), and sensitivity requests (SRQ).
  * It shows policy rules for each element, including collection status, validation, and sensitivity.
  * The component calculates and displays registration values, validation, and sensitivity based on the policy rules and registrant facts.

The main calculations in simple terms:
  * Registration Value: It filters the registrant fact based on the collection status from the policy rule.
  * Registration Validation: It combines the validation rule from the policy with the VRQ from the registrant fact, using bitwise OR operation. It displays the stricter of the two values.
  * Registration Sensitivity: Similar to validation, it combines the sensitivity rule from the policy with the SRQ from the registrant fact, displaying the stricter value.

These calculations ensure that the displayed registration data adheres to both the policy rules and the registrant's requests, always showing the more restrictive option when there's a difference between policy and registrant preferences.
*/

interface RegistrationEditorTableProps
  {
  registration: RegistrationStore;
  }

function RegistrationDataPrinter (props: RegistrationEditorTableProps)
  {
  const registryList: RegistryListStore = useRegistryListStore();

  const empty_attribute         : APIAttribute             = { id: 0, name: "", values: [] };
  const regValidationDefinition : APIAttribute             = props.registration.dictionary.getRuleAttribute(RULE_ATTRIBUTES.VAL) ?? empty_attribute;
  const regSensitivtyDefinition : APIAttribute             = props.registration.dictionary.getRuleAttribute(RULE_ATTRIBUTES.SENS) ?? empty_attribute;
  const V3RQ_attribute          : APIAttribute | undefined = props.registration.dictionary.getRegistrationAttribute(REG_ATTRIBUTES.V3RQ);
  const SRQ_attribute           : APIAttribute | undefined = props.registration.dictionary.getRegistrationAttribute(REG_ATTRIBUTES.DG);

  // Renders the data table generated by mapping over the `data_extraction` array, which contains objects representing the data for each row in the table. For each row, it renders:
  return (!props.registration.data || !props.registration.dictionary.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Table responsive hover size="sm">
        {props.registration.dictionary.data.element_groups.map((group: APIElementGroup) =>
          {
          return (!props.registration.dictionary.data)
            ? null
            : <React.Fragment key={`print_group_${group.id}`}>
              <DataTableHeader groupName={group.name} rule_attributes={props.registration.dictionary.data?.rule_attributes} />
              <tbody>
              {group.elements.map((element: APIElement, index: number) =>
                {
                const defaultFact = { element_id: element.id, value: element.name, V3RQ: ANY, DG: ANY };

                let fact    : APIRegistrantFactItem = props.registration.registrantFacts.getRegistrantFact(element.id) ?? defaultFact;
                let rule    : APIRule               = props.registration.policy.getRule(element.id) ?? { id: 0, element_id: element.id, attributes: [] };
                let collect : number                = (rule) ? props.registration.dictionary.getRuleAttributeOption(RULE_ATTRIBUTES.COLL, rule)?.value ?? 0 : 0;
                let key     : string                = `printRowData${group.id}${element.id}`;
                let scope   : JSX.Element           = (<RegistrationScopeAttribute elementID={element.id} dictionary={props.registration.dictionary} registration={props.registration} registryList={registryList}  />);
                return (!props.registration.dictionary.data) ? null :
                    <tr key={key}>
                      <RegistrantFactCell groupID={group.id} element={element} regFact={fact} scope={scope} registrantFacts={props.registration.registrantFacts} />
                      <VrqCell element={element} attribute={V3RQ_attribute} rule={rule} regFact={fact} definition={props.registration.dictionary.data.registration_attributes} registrantFacts={props.registration.registrantFacts} />
                      <SrqCell element={element} attribute={SRQ_attribute} rule={rule} regFact={fact} definition={props.registration.dictionary.data.registration_attributes} registrantFacts={props.registration.registrantFacts} />
                      <td className="border">&nbsp;</td>
                      <PolicyRules rule={rule} definitions={props.registration.dictionary.data.rule_attributes} policy={props.registration.policy} />
                      <td className="border">&nbsp;</td>
                      <RegistrationValue groupID={group.id} scope={scope} regFact={fact} collect={collect} />
                      <RegistrationValidation collect={collect} rule={rule} regFact={fact} definition={regValidationDefinition} />
                      <RegistrationSensitivity collect={collect} rule={rule} regFact={fact} definition={regSensitivtyDefinition} />
                      </tr>

                })}
              </tbody></React.Fragment>
          })}</Table>
  }

export default RegistrationDataPrinter;

///////////////////////////////////////
// COLUMN: REGISTRATION SCOPE ATTRIBUTE
///////////////////////////////////////
// Retrieves the registration scope attribute for the given element ID. If the element ID is 4, it returns the name of the TLD from the registration data, or "<none>" if not found. For other element IDs, it looks up the scope attribute ID in a join table, finds the corresponding scope attribute value in the registration data, and returns the name of that value, or "ø" if not found.
const RegistrationScopeAttribute = React.memo((props: {elementID: number, dictionary: DataDictionaryStore, registration: RegistrationStore, registryList: RegistryListStore}) =>
  {
  const findPSLValue = () =>
    {
    const onRegistryListChange = (newTLDList: APIRegistryList | null) => props.registration.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null });
    const attribute: APIAttribute | undefined = { id: 0, name: "Registry List", values: props.registryList.data?.map((item: APIRegistryList) => ({ value: item.id, name: item.name, className: "rule_dictionary" })) ?? [] };
    return <DataBox ruleValue={props.registration.data?.tld_list_id ?? undefined} attribute={attribute} onChange={(newValue: number) => onRegistryListChange(props.registryList.data?.find(item => item.id === newValue) ?? null)} />
    }

  const findScopeValue = () =>
    {
    const UNKNOWN = 1;
    const joinTable : { [key: number]: number; } = { 84: 10, 5: 11, 6: 12, 85: 13 };
    const scopeAttribute : APIAttribute | undefined = props.dictionary.data?.scope_attributes?.find(sa => sa.id === joinTable[props.elementID]);

    if (scopeAttribute)
      {
      const existingValue : number                           = props.registration.data?.scope_attributes?.find((sa:APIRuleAttribute) => sa.attribute_id === joinTable[props.elementID])?.value ?? UNKNOWN;
      const altDropdown   : APIAttributeOption[] | undefined = scopeAttribute.values.filter((val) => props.registration.singletonScopes?.includes(val.value));
      const eventOnChange : (newVal: number) => void         = (newVal:number) => props.registration.updateScopeAttribute(scopeAttribute.id, newVal);

      return <DataBox attribute={scopeAttribute} ruleValue={existingValue} alternateDropdown={altDropdown} onChange={eventOnChange} />
      }
    else
      {
      return null;
      }
    }

  return (props.elementID === ELEMENT_REGISTRY) ? findPSLValue() : findScopeValue();
  });

//////////////////////////////////////////////////////
// REGISTRATION TABLE HEADERS (repeats for each group)
//////////////////////////////////////////////////////

function DataTableHeader (props: {groupName:string, rule_attributes: APIAttribute[]})
  {
  const rule_attributes = props.rule_attributes.map(att => <th key={`print_headerCell_${att.id}`} className="text-muted"><small>{att.name}</small></th>);
  const policyColSpan = props.rule_attributes.length;
  const groupName = props.groupName;

  return <thead>
    <tr className="border border-2 border-start-0 border-end-0 border-dark">
      <th colSpan={3} className="fst-italic fw-normal"><small>Registrant Facts</small></th>
      <th className="border-start border-end"><small>&nbsp;</small></th>
      <th colSpan={policyColSpan} className="fst-italic fw-normal"><small>Policy</small></th>
      <th className="border-start border-end"><small>&nbsp;</small></th>
      <th colSpan={3} className="fst-italic fw-normal"><small>Registration</small></th>
      </tr>
    <tr>
      <th className="text-muted"><small>{groupName}</small></th>
      <th className="text-muted"><small>Vrq</small></th>
      <th className="text-muted"><small>Srq</small></th>
      <th className="border-start border-end"><small>&nbsp;</small></th>
      {rule_attributes}
      <th className="border-start border-end"><small>&nbsp;</small></th>
      <th className="text-muted"><small>Value</small></th>
      <th className="text-muted"><small>Val</small></th>
      <th className="text-muted"><small>Sens</small></th>
      </tr>
    </thead>
  }

///////////////////////////////////////
// COLUMN: REGISTRANT FACT
///////////////////////////////////////

const styleRegistrationFactCell = {width: '175px', textOverflow: "ellipsis"};
function RegistrantFactCell (props: {groupID:number, element: APIElement, regFact: APIRegistrantFactItem, registrantFacts: RegistrantFactsStore, scope:React.ReactNode})
  {
  const toolTip = <Tooltip id={`factTT-${props.element.id}`}>{props.element.name}</Tooltip>
  return (props.groupID === GROUP_REGSCOPE)
    ? <td align="left" className="fst-italic overflow-hidden text-nowrap" style={styleRegistrationFactCell}>{props.scope}</td>
    : <td align="left" className="fst-italic overflow-hidden text-nowrap" style={styleRegistrationFactCell}><OverlayTrigger overlay={toolTip}><Form.Control id={`print_registrantFact_${props.element.id}`} type="text" placeholder={props.element.name} value={props.regFact.value ?? ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.registrantFacts?.updateFact(props.element, {value: e.target.value.length ? e.target.value : props.element.name})} /></OverlayTrigger></td>
  }

///////////////////////////////////////
// COLUMN: VALIDATION REQUEST
///////////////////////////////////////

function VrqCell (props: {element: APIElement, attribute: APIAttribute | undefined, rule:APIRule, regFact: APIRegistrantFactItem, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const VAL = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.VAL)?.value ?? 0;
  const value:number = parseInt(props.regFact?.V3RQ ?? ANY);
  const VrqChangeEvent = (newVal:number) => props.registrantFacts.updateFact(props.element, { V3RQ: String(newVal) });

  // TODO: if VAL is a range, restrict options within range of VAL

  return <td><div style={{opacity: ranges[VAL]?.includes(value) ? '1.0' : '0.5'}}><DataBox ruleValue={value} attribute={props.attribute} onChange={VrqChangeEvent} /></div></td>
  }

///////////////////////////////////////
// COLUMN: SENSITIVITY REQUEST
///////////////////////////////////////

function SrqCell (props: {element: APIElement, attribute: APIAttribute | undefined, rule:APIRule, regFact:APIRegistrantFactItem | undefined, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const SENS: number = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.SENS)?.value ?? 0;
  const value: number = parseInt(props.regFact?.DG ?? ANY);
  const SrqChangeEvent = (newVal:number) => props.registrantFacts.updateFact(props.element, { DG: String(newVal) });

  // TODO: if SENS is a range, restrict options within range of SENS

  return <td><div style={{opacity: ranges[SENS]?.includes(value) ? '1.0' : '0.5'}}><DataBox ruleValue={value} attribute={props.attribute} onChange={SrqChangeEvent} /></div></td>
  }

///////////////////////////////////////
// COLUMN: POLICY RULES
///////////////////////////////////////

function PolicyRules (props: {rule:APIRule, definitions:APIAttribute[], policy: PolicyStore}): JSX.Element
  {
  const cssCellColorError = 'bg-warning';
  const cssCellColorNormal = 'bg-transparent';

  const calculateErrorState = (attID: number) =>
    {
    if (attID === RULE_ATTRIBUTES.V3RQ)
      {
      const VAL  : number = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.VAL)?.value ?? 0;
      const V3RQ : number = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.V3RQ)?.value ?? 0;

      return isSingleton(VAL) ? (VAL === V3RQ ? cssCellColorNormal : cssCellColorError) : (ranges[VAL].includes(V3RQ) ? cssCellColorNormal : cssCellColorError);
      }

    if (attID === RULE_ATTRIBUTES.DG)
      {
      const SENS : number = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.SENS)?.value ?? 0;
      const DG   : number = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.DG)?.value ?? 0;

      return isSingleton(SENS) ? (SENS === DG ? cssCellColorNormal : cssCellColorError) : (ranges[SENS].includes(DG) ? cssCellColorNormal : cssCellColorError);
      }

    return cssCellColorNormal;
    }

  function eventChange (attID: number, newVal:number)
    {
    if (props.policy.data?.rules) props.policy.updateData(updatePolicyRuleAttributeValue(props.policy.data, props.rule.element_id, attID, newVal));
    }

  return (<>{props.definitions.map((att:APIAttribute) => <td key={`print_policyRuleCell_${att.id}`} className={calculateErrorState(att.id)}><PolicyRuleCell key={`print_policyRuleCell_${att.id}`} rule={props.rule} attribute={att} onChange={(value:number) => eventChange(att.id, value)} /></td>)}</>);
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALUE
///////////////////////////////////////

const styleRegistrationValueCell = {maxWidth: '175px', textOverflow: "ellipsis"};
function RegistrationValue (props: {groupID:number, scope: React.ReactNode, regFact: APIRegistrantFactItem | undefined, collect: number})
  {
  /**
   * Determines the appropriate value to display for a registration fact based on the collection status.
   *
   * @param props - An object containing the following properties:
   *   - groupID: The ID of the registration group.
   *   - scope: The scope of the registration.
   *   - regFact: The registration fact item.
   *   - collect: The collection status of the registration fact.
   * @returns The value to display for the registration fact.
   */
  const filterFact = () =>
    {
    const UNSET   = "ø";
    const MISSING = "MISSING";
    const EMPTY   = null;
    const FACT    = props.regFact?.value ?? null;
    if (props.groupID === GROUP_REGSCOPE) return props.scope;
    switch (props.collect)
      {
      case RULE_COLLECTION.NULL: return UNSET;
      case RULE_COLLECTION.COLLECT: return FACT ?? MISSING;
      case RULE_COLLECTION.DONOTCOLLECT: return EMPTY;
      case RULE_COLLECTION.OPTIONAL: return FACT ?? EMPTY;
      case RULE_COLLECTION.COLLECT_OPTIONAL: return FACT ?? EMPTY;
      case RULE_COLLECTION.COLLECT_DONOTCOLLECT: return FACT ?? EMPTY;
      case RULE_COLLECTION.ANY: return FACT ?? MISSING;
      default: return EMPTY;
      }
    }

  return <td align="left" className="fst-italic overflow-hidden text-nowrap" style={styleRegistrationValueCell}>{filterFact()}</td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALIDATION
///////////////////////////////////////

function RegistrationValidation (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem, definition:APIAttribute})
  {
  function calculateValidation (rule: APIRule)
    {
    const Vrq: number = props.definition.values?.find((v) => v.value === parseInt(props.regFact.V3RQ))?.value ?? 0; // correct? confirm that this is not always zero. does REG_ATTRIBUTES.V3RQ exist or match on APIRule?
    const val: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.VAL)?.value ?? 0;
    const v_dflt: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.V3RQ)?.value ?? 0;
    const validation: number = isSingleton(val) ? val : Vrq === parseInt(ANY) ? v_dflt : ranges[val].includes(Vrq) ? Vrq : v_dflt;
    const validationName = props.definition.values?.find((v) => v.value === validation)?.name ?? "";

    return (props.collect === RULE_COLLECTION.DONOTCOLLECT) ? null : validationName;
    }

  const regValidationValue = calculateValidation(props.rule);

  return <td>{regValidationValue}</td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION SENSITIVITY
///////////////////////////////////////

function RegistrationSensitivity (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem, definition:APIAttribute})
  {
  function calculateSensitivity (rule: APIRule)
    {
    const Srq: number = props.definition.values?.find((v) => v.value === parseInt(props.regFact.DG))?.value ?? 0;
    const sens: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.SENS)?.value ?? 0;
    const s_dflt: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.DG)?.value ?? 0;
    const sensitivity: number = isSingleton(sens) ? sens : Srq === parseInt(ANY) ? s_dflt : ranges[sens].includes(Srq) ? Srq : s_dflt;
    const sensitivityName = props.definition.values?.find((v) => v.value === sensitivity)?.name ?? "";

    return (props.collect === RULE_COLLECTION.DONOTCOLLECT) ? null : sensitivityName;
    }

  let regSensToDisplay = calculateSensitivity(props.rule);

  return <td>{regSensToDisplay}</td>
  }

