/* NODE PACKAGES */
import { useState } from 'react';
import {Offcanvas, Button, ProgressBar, Nav, ListGroup, ListGroupItem} from 'react-bootstrap';
/* API */
import {APIDictionary, APIAttribute, APIPolicy, APIRuleAttribute, APIRule, PolicyListItem } from "common/api/types";
/* MODULAR CSS */
import styleDataBox from 'components/atoms/Inputs/DataBox.module.css';
import styleChartBox from 'components/atoms/Inputs/ChartBox.module.css';

// Functional component representing a legend table
function ColorKey ()
  {
  const [checked, setChecked] = useState(false);

  const colorLegendSet = "d-flex flex-column justify-content-start align-items-start gap-2 m-0 p-0 border-0 lh-1";
  const colorLegendSetItem = "d-flex flex-row justify-content-start align-items-start gap-2";
  const gridColumn = "d-flex flex-column justify-content-start align-items-start gap-2";
  const cssChart : React.CSSProperties = {width: '80px', height: '20px'};

  const colorChart = (className: string = "", description: string = "ANY") =>
    {
    return <li className={colorLegendSetItem}>
      <ProgressBar max={100} style={cssChart}>
        <ProgressBar now={100} variant="light" className={`${styleDataBox[className] ?? "rule_dictionary"} bg-transparent shadow-sm`} />
        </ProgressBar>
      <ProgressBar max={100} style={cssChart}>
        <ProgressBar now={100} variant="light" className={`${styleChartBox[className] ?? "rule_dictionary"} bg-transparent shadow-sm`} />
        </ProgressBar>
      <span className={`fw-bold font-monospace m-0 p-0`} style={{fontSize:'14px'}}><small>{description}</small></span>
      </li>
    }

  return (<>
    <Button variant="outline-dark" className="m-0 p-2 lh-1 text-nowrap" onClick={() => setChecked(!checked)}>Color Key</Button>
    <Offcanvas show={checked} onHide={() => setChecked(!checked)} keyboard={true} placement='start'>
      <Offcanvas.Header className="bg-light" closeButton>
        <Offcanvas.Title>
          <h4 className="lh-1 text-dark m-0 px-0 py-1">Help Sheet: Color Key</h4>
          <h6 className="lh-1 text-muted m-0 px-0 py-1"> Reference for data attribute colors </h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
      <Offcanvas.Body className={gridColumn}>

        <ul className={colorLegendSet}><span> Rule: Defaults </span>
          {colorChart('rule_null', "NULL")} {/* "NULL" */}
          {colorChart('rule_any' , "ANY" )} {/* "ANY" */}
          </ul>

        <ul className={colorLegendSet}><span> Rule: Comparisons </span>
          {colorChart('rule_equal'       , "Equal"       )} {/* "Equal"        */}
          {colorChart('rule_subset'      , "Superset"    )} {/* "Superset"     */}
          {colorChart('rule_superset'    , "Subset"      )} {/* "Subset"       */}
          {colorChart('rule_incompatible', "Incompatible")} {/* "Incompatible" */}
          {colorChart('rule_null'        , "NULL"        )} {/* "Null"         */}
          </ul>

        <ul className={colorLegendSet}><span> Rule: Collect </span>
          {colorChart('rule_coll_1', "Collect"          )} {/* "Collect"           */}
          {colorChart('rule_coll_2', "!Collect"         )} {/* "!Collect"          */}
          {colorChart('rule_coll_3', "Collect/!Collect" )} {/* "Collect/!Collect"  */}
          {colorChart('rule_coll_4', "Optional"         )} {/* "Optional"          */}
          {colorChart('rule_coll_5', "Collect/Optional" )} {/* "Collect/Optional"  */}
          {colorChart('rule_coll_6', "Optional/!Collect")} {/* "Optional/!Collect" */}
          </ul>

        <ul className={colorLegendSet}><span> Rule: Validation </span>
          {colorChart('rule_val_01', "V0"    )} {/* "V0"      */}
          {colorChart('rule_val_02', "V1"    )} {/* "V1"      */}
          {colorChart('rule_val_03', "V0..V1")} {/* "V0..V1"  */}
          {colorChart('rule_val_04', "V2"    )} {/* "V2"      */}
          {colorChart('rule_val_05', "V1..V2")} {/* "V1..V2"  */}
          {colorChart('rule_val_06', "V0..V2")} {/* "V0..V2"  */}
          {colorChart('rule_val_07', "V3"    )} {/* "V3"      */}
          {colorChart('rule_val_08', "V2..V3")} {/* "V2..V3"  */}
          {colorChart('rule_val_09', "V1..V3")} {/* "V1..V3"  */}
          </ul>

        <ul className={colorLegendSet}><span> Rule: Sensitivity </span>
          {colorChart('rule_sens_01', "S0"    )} {/* "S0"     */}
          {colorChart('rule_sens_02', "S1"    )} {/* "S1"     */}
          {colorChart('rule_sens_03', "S0..S1")} {/* "S0..S1" */}
          {colorChart('rule_sens_04', "S2"    )} {/* "S2"     */}
          {colorChart('rule_sens_05', "S1..S2")} {/* "S1..S2" */}
          {colorChart('rule_sens_06', "S0..S2")} {/* "S0..S2" */}
          {colorChart('rule_sens_07', "S3"    )} {/* "S3"     */}
          {colorChart('rule_sens_08', "S2..S3")} {/* "S2..S3" */}
          {colorChart('rule_sens_09', "S1..S3")} {/* "S1..S3" */}
          </ul>

        <ul className={colorLegendSet}><span> Rule: Store </span>
          {colorChart('rule_store_1', "!Store")} {/* "!Store" */}
          {colorChart('rule_store_2', "Either")} {/* "Either" */}
          {colorChart('rule_store_3', "Store" )} {/* "Store"  */}
          </ul>

        <ul className={colorLegendSet}><span> Scope: Person </span>
          {colorChart('scope_person_1', "Unknown"        )} {/* "Unknown"          */}
          {colorChart('scope_person_2', "Natural"        )} {/* "Natural"          */}
          {colorChart('scope_person_3', "Natural/Unknown")} {/* "Natural/Unknown"  */}
          {colorChart('scope_person_4', "Legal"          )} {/* "Legal"            */}
          {colorChart('scope_person_5', "Legal/Unknown"  )} {/* "Legal/Unknown"    */}
          </ul>

        <ul className={colorLegendSet}><span> Scope: Protection </span>
          {colorChart('scope_protection_1', "Unknown"          )} {/* "Unknown"           */}
          {colorChart('scope_protection_2', "Normal"           )} {/* "Normal"            */}
          {colorChart('scope_protection_3', "Normal/Unknown"   )} {/* "Normal/Unknown"    */}
          {colorChart('scope_protection_4', "Protected"        )} {/* "Protected"         */}
          {colorChart('scope_protection_5', "Protected/Unknown")} {/* "Protected/Unknown" */}
          </ul>

        <ul className={colorLegendSet}><span> Scope: Nexus </span>
          {colorChart('scope_nexus_1', "Unknown"             )} {/* "Unknown"               */}
          {colorChart('scope_nexus_2', "Non-Resident"        )} {/* "Non-Resident"          */}
          {colorChart('scope_nexus_3', "Non-Resident/Unknown")} {/* "Non-Resident/Unknown"  */}
          {colorChart('scope_nexus_4', "Resident"            )} {/* "Resident"              */}
          {colorChart('scope_nexus_5', "Resident/Unknown"    )} {/* "Resident/Unknown"      */}
          </ul>

        <ul className={colorLegendSet}><span> Scope: Personal </span>
          {colorChart('scope_personal_1', "Unknown"                   )} {/* "Unknown"                    */}
          {colorChart('scope_personal_2', "Non-Personal"              )} {/* "Non-Personal"               */}
          {colorChart('scope_personal_3', "Non-Personal/Unknown"      )} {/* "Non-Personal/Unknown"       */}
          {colorChart('scope_personal_4', "Personal"                  )} {/* "Personal"                   */}
          {colorChart('scope_personal_5', "Personal/Unknown"          )} {/* "Personal/Unknown"           */}
          {colorChart('scope_personal_6', "Non-Personal & Unspecified")} {/* "Non-Personal & Unspecified" */}
          </ul>

        </Offcanvas.Body>
      </Offcanvas>
    </>);
  }

export default ColorKey;

/*
Developer Notes:

*/
