/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel, FormControlProps} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'common/api/types';

/////////////////////////////////////////////////
// HELPER METHODS: VALIDATION
/////////////////////////////////////////////////

// Helper method to validate based on pattern
const validatePattern = (pattern: RegExp, value: string, required: boolean): boolean => required ? pattern.test(value) : !value || pattern.test(value);

// Default required field validation (for plain text)
const validateRequiredField = (value: string, required: boolean = false): boolean => required ? value.trim().length > 0 : true;

// Validate function for dynamic input type
const validateInputByType = (value: string, type: keyof typeof inputTypePatterns = "text", required: boolean = false): boolean =>
  {
  const pattern = inputTypePatterns[type] || inputTypePatterns["text"];
  return validatePattern(pattern, value, required);
  };

/////////////////////////////////////////////////
// TYPE DEFINITIONS
/////////////////////////////////////////////////

// Pattern mappings for various input types
const inputTypePatterns: { [key: string]: RegExp } =
  {
  date: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,  // YYYY-MM-DD
  name: /^[a-zA-Z\s]{2,}$/,              // Letters and spaces (min 2 characters)
  organization: /^[\w\s\-&,]{2,}$/,      // Alphanumeric and special characters
  address: /^[\w\s,.'-]{3,}$/,           // Alphanumeric and punctuation
  city: /^[a-zA-Z\s]{2,}$/,              // Letters and spaces
  state: /^[a-zA-Z\s]{2,}$/,             // Letters (min 2 characters)
  zipcode: /^\d{5}(-\d{4})?$/,           // US Zip Code (5 or 9 digits)
  country: /^[a-zA-Z\s]{2,}$/,           // Letters and spaces
  tel: /^[2-9]{1}\d{2}-?\d{3}-?\d{4}$/,  // US Phone number (10 digits)
  phoneExt: /^\d{1,5}$/,                 // Phone extension (1-5 digits)
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,   // Email format
  url: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/, // URLs (basic domain name format)
  uniqueID: /^[a-zA-Z0-9]{6,}$/,         // Alphanumeric (min 6 characters)
  userAccountID: /^[a-zA-Z0-9]{4,20}$/,  // Alphanumeric (4-20 characters)
  socialCredit: /^\d{18}$/,              // Numeric (18 digits)
  text: /^.+$/,                          // Any text (default for required fields)
  };


/////////////////////////////////////////////////
// TEXTBOX UI COMPONENT
/////////////////////////////////////////////////

// CSS classes for styling
const cssFloatingLabelInput = "d-block position-relative w-100";
const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";

// type TextInputProps = React.ComponentProps<'input'> &
//   {
//   label?: string;
//   name: string;
//   };

interface TextBoxProps
  {
  value: string | undefined;
  definition: APITypedAttribute | undefined;
  eventChange: (key: number, value: string) => void;
  type?: keyof typeof inputTypePatterns; // Restrict type to known input types
  required?: boolean;
  disabled?: boolean;
  }

function TextBox({value = "", definition = undefined, eventChange = (key: number, value: string) => {}, type = "text", disabled = false, required = false}:TextBoxProps)
  {
  const isValid = validateInputByType(value, type, required);

  return (!definition)
    ? null
    : <FloatingLabel key={`${definition.type}_${definition.attribute.id}`} controlId={`${definition.type}_${definition.attribute.id}`} label={definition.attribute.name} className={cssFloatingLabelInput}>
        <Form.Control
          type={String(type)}
          placeholder={definition.attribute.name}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => eventChange(definition.attribute.id, e.target.value)}
          disabled={disabled}
          required={required}
          isValid={isValid}
          isInvalid={!isValid}
          />
        <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
        <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Input</Form.Control.Feedback>
        </FloatingLabel>
  }

export default TextBox;

/*

import { ComponentProps } from 'react';
import { Control, useController } from 'react-hook-form';

type TextInputProps = ComponentProps<'input'> & {
  control: Control<any>;
  label?: string;
  name: string;
};

export default function TextInput({
  control,
  label,
  name,
  ...inputProps
}: TextInputProps) {
  const {
    formState: { errors },
  } = useController({ control, name });

  return (
    <div className="flex flex-col gap-1">
      {label && <label className="font-semibold">{label}</label>}
      <input {...control.register(name)} {...inputProps} />
      {errors[name] && (
        <span className="text-red-500">{errors[name].message?.toString()}</span>
      )}
    </div>
  );
}

*/