/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert} from 'react-bootstrap';
/* HOOKS */
import {useDictionaryStore, DataDictionaryStore} from 'hooks';
/* CUSTOM COMPONENTS */
//import { redirect } from 'common/webutils';
import PolicyEditor from 'pages/Policies/PolicyEditor';
import Policies from 'pages/Policies/';
//import RegistrantFactsEditor from 'pages/Registrations/modules/RegistrantFactsEditor';
import RegistrationEditor from 'pages/Registrations/RegistrationEditor';
import RegistrationEditorOld from 'pages/Registrations/RegistrationEditorOld';
import TemplateEditor from 'pages/Requests/TemplateEditor';
import RequestForm from 'pages/Requests/RequestForm';
import Templates from 'pages/Requests';
import Registrations from 'pages/Registrations';
import RegistrarGroupMembers from 'pages/Registrars';
import RequesterEditor from 'pages/Registrars/RequesterEditor';
import RequesterGroupEditor from 'pages/Registrars/RequesterGroupEditor';
import RegistrarEditor from 'pages/Registrars/RegistrarEditor';
import RegistrarGroupEditor from 'pages/Registrars/RegistrarGroupEditor';
import PSL from 'pages/Registries';
import RegistryListEditor from 'pages/Registries/RegistryListEditor';
import Dashboard from 'pages/Dashboard';
import AuthModal from 'components/molecules/Modals/AuthModal';
import ConfirmDialog from 'components/molecules/Modals/ConfirmDialog';
import NavigationBar from 'components/atoms/Templates/NavigationBar';

////////////////////////////////
// TYPE DEFINITIONS
////////////////////////////////

export enum HashMode
  {
  policy = "/#/policy/",
  registrations = "/#/registrations/",
  registration = "/#/registration/",
  registration_alpha = "/#/registration_alpha/",
  request_template = "/#/request_template/",
  request_execution = "/#/request_execution/",
  requester_group = "/#/requester_group/",
  requester = "/#/requester/",
  registrar_group = "/#/registrar_group/",
  registrar = "/#/registrar/",
  registry = "/#/registry/",
  registrars = "/#/registrars/",
  registries = "/#/registries/",
  requests = "/#/requests/",
  policies = "/#/policies/",
  default = "/#/",
  }

type AppMode =
  | { mode: HashMode.policy; id: number; }
  | { mode: HashMode.registration | HashMode.registration_alpha; id: number; }
  | { mode: HashMode.request_template; id: number;}
  | { mode: HashMode.request_execution; id: number;}
  | { mode: HashMode.requester_group; id: number; }
  | { mode: HashMode.requester; id: number; }
  | { mode: HashMode.registrar_group; id: number; }
  | { mode: HashMode.registrar; id: number; }
  | { mode: HashMode.registry; id: number}
  | { mode: HashMode.registrars;}
  | { mode: HashMode.registrations; }
  | { mode: HashMode.registries;}
  | { mode: HashMode.requests;}
  | { mode: HashMode.policies;}
  | { mode: HashMode.default; };

////////////////////////////////
// MAPPINGS
////////////////////////////////

const regexMappings: Array<[RegExp, (match: RegExpMatchArray) => AppMode]> =
  [
  [/(\/policy\/(\d+))$/, (match) => ({ mode: HashMode.policy, id: parseInt(match[2], 10) }),],
  [/(\/registration_alpha\/(\d+))$/, (match) => ({ mode: HashMode.registration_alpha, id: parseInt(match[2], 10) })],
  [/(\/registration\/(\d+))$/, (match) => ({mode: HashMode.registration, id: parseInt(match[2], 10),}),],
  [/(\/request_template\/(\d+))$/, (match) => ({mode: HashMode.request_template, id: parseInt(match[2], 10),}),],
  [/(\/request_execution\/(\d+))$/, (match) => ({mode: HashMode.request_execution, id: parseInt(match[2], 10),}),],
  [/(\/requester_group\/(\d+))$/, (match) => ({ mode: HashMode.requester_group, id: parseInt(match[2], 10) }),],
  [/(\/requester\/(\d+))$/, (match) => ({ mode: HashMode.requester, id: parseInt(match[2], 10) }),],
  [/(\/registrar_group\/(\d+))$/, (match) => ({ mode: HashMode.registrar_group, id: parseInt(match[2], 10) }),],
  [/(\/registrar\/(\d+))$/, (match) => ({ mode: HashMode.registrar, id: parseInt(match[2], 10) }),],
  [/(\/registry\/(\d+))$/, (match) => ({ mode: HashMode.registry, id: parseInt(match[2], 10) }),],
  [/(\/registrars\/)$/, () => ({ mode: HashMode.registrars })],
  [/(\/registrations\/)$/, () => ({ mode: HashMode.registrations })],
  [/(\/registries\/)$/, () => ({ mode: HashMode.registries })],
  [/(\/requests\/)$/, () => ({ mode: HashMode.requests })],
  [/(\/policies\/)$/, () => ({ mode: HashMode.policies })],
  ];

////////////////////////////////
// HELPER: getAppModeFromHash
////////////////////////////////

const getAppModeFromHash = (): AppMode =>
  {
  const hash = window.location.hash;
  for (const [regex, modeFn] of regexMappings)
    {
    const match = hash.match(regex);
    if (match) return modeFn(match);
    }
  return {mode: HashMode.default};
  };

////////////////////////////////
// APP CONTEXT
////////////////////////////////

export type AppContextType =
  {
  dictionary: DataDictionaryStore;
  unsavedChanges: React.MutableRefObject<boolean>;
  };

export const AppContext: React.Context<AppContextType | undefined> = React.createContext<AppContextType | undefined>(undefined);

////////////////////////////////
// MAIN ENTRY POINT
////////////////////////////////

function App ()
  {
  const [mode, setMode] = React.useState<AppMode>({mode: HashMode.default});
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [isConfirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmCallback, setConfirmCallback] = React.useState<(() => void) | null>(null);
  const [cancelCallback, setCancelCallback] = React.useState<(() => void) | null>(null);
  const dictionary = useDictionaryStore({authenticated});
  const hasUnsavedChangesRef = React.useRef<boolean>(false);

  const onHashChange = React.useCallback(() =>
    {
    const update = getAppModeFromHash();
    if (update.mode === HashMode.default || (update.mode !== mode.mode) || Object.keys(update).some(key => (update as any)[key] !== (mode as any)[key]))
      {
      setMode(update);
      hasUnsavedChangesRef.current = false;
      }
    //if ((update.mode !== mode.mode) || Object.keys(update).some(key => (update as any)[key] !== (mode as any)[key])) setMode(update);
    }, [mode]);

  // Synchronize app mode with current hash when the component is mounted
  React.useEffect(() =>
    {
    setMode(getAppModeFromHash());
    }, []);

  // Synchronize hash with app mode when the hash changes
  React.useEffect(() =>
    {
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
    }, []);

  const showConfirmDialog = (): Promise<boolean> =>
    {
    return new Promise((resolve) =>
      {
      setConfirmCallback(() => () => resolve(true));
      setCancelCallback(() => () => resolve(false));
      setConfirmOpen(true);
      });
    };

  const handleHashChange = async (event: HashChangeEvent) =>
    {
    // console.log("Hash change event:", event.oldURL, window.location.href);
    // if the new url is the same as the current policy, then do nothing
    if (hasUnsavedChangesRef.current && window.location.href === event.oldURL) return;

    if (!hasUnsavedChangesRef.current || await showConfirmDialog())
      {
      // Continue with the existing logic for hashchange
      hasUnsavedChangesRef.current = false;
      onHashChange();
      }
    else
      {
      event.preventDefault();
      console.log("Hash change event:", event.oldURL, window.location.href);
      //window.history.pushState(null, '', event.oldURL?.split('#')[1] ?? '');
      window.history.replaceState(null, '', `#${event.oldURL?.split('#')[1] ?? ''}`);
      return;
      }
    };

  const handleUnsavedChanges = (flag:boolean) => (hasUnsavedChangesRef.current = flag);
  const handleAuthSuccess    = ()             => setAuthenticated(true);
  const handleAuthFailure    = ()             => setAuthenticated(false);

  const handleConfirmDialog = () =>
    {
    setConfirmOpen(false);
    confirmCallback && confirmCallback();
    }

  const handleCancelDialog = () =>
    {
    setConfirmOpen(false);
    cancelCallback && cancelCallback();
    }

  const RenderComponent = React.useMemo(() =>
    {
    if (!authenticated) return <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    if (!dictionary.data) return <Alert variant="warning">{"Data is not available at this time."}</Alert>
    switch (mode.mode)
      {
      case HashMode.policy: return <PolicyEditor dataDictionary={dictionary.data} policyID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.registration: return <RegistrationEditor registrationID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.registration_alpha: return <RegistrationEditorOld selectedRegistrationID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.request_execution: return <RequestForm selectedRequestTemplateID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.request_template: return <TemplateEditor selectedRequestTemplateID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.requester: return <RequesterEditor selectedRequesterID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.requester_group: return <RequesterGroupEditor selectedRqGID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.registrar: return <RegistrarEditor selectedRegID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.registrar_group: return <RegistrarGroupEditor selectedID={mode.id} unsavedChanges={handleUnsavedChanges} />
      case HashMode.registry: return <RegistryListEditor selectedID={mode.id} />
      case HashMode.registrations: return <Registrations />
      case HashMode.registrars: return <RegistrarGroupMembers />
      case HashMode.requests: return <Templates />
      case HashMode.registries: return <PSL />
      case HashMode.policies: return <Policies />
      case HashMode.default: return <Dashboard />
      }
    }, [mode, authenticated, dictionary]);

  // password dialog only displays if a valid password is absent from local storage
  return (<AppContext.Provider value={{ dictionary: dictionary, unsavedChanges: hasUnsavedChangesRef }}>
    <AuthModal onAuthSuccess={handleAuthSuccess} onAuthFailure={handleAuthFailure} />
    {isConfirmOpen && ( <ConfirmDialog header="Unsaved changes!" message="Are you sure you want to exit before saving changes?" onConfirm={handleConfirmDialog} onCancel={handleCancelDialog} /> )}
    <NavigationBar hashMode={mode.mode} />
    {RenderComponent}
    </AppContext.Provider>);
  }

export default App;


/* Developer Notes:

This code defines the main component of a React application called "App". The purpose of this component is to manage the application's mode and data dictionary based on the current URL hash and to handle password authentication.

The code takes no direct input, but it relies on the current URL hash to determine the application mode. The output it produces is the rendered user interface of the application, which is determined by the application mode and the data dictionary.

To achieve its purpose, the code follows this logic and algorithm:

 * It defines an array of regular expressions and corresponding functions to map different URL hash patterns to application modes.
 * It defines a function getAppModeFromHash that uses the regular expression mappings to determine the application mode based on the current URL hash.
 * It defines the main App component as a functional component using React hooks.
 * Inside the App component, it initializes state variables to store the application mode, data dictionary, and loading state.
 * It checks if the password is stored in the browser's local storage. If not, it renders a modal prompting the user to enter the password.
 * If the password is stored, it sets up an effect hook to update the application mode whenever the URL hash changes. This effect listens for the hashchange event on the window and calls the handleHashChange function to update the application mode accordingly.
 * It sets up another effect hook to initialize the application mode based on the current URL hash when the component mounts.
 * The handleHashChange function compares the new mode derived from the URL hash with the current mode. If the modes are different, it updates the state with the new mode. If the modes are the same but some properties have changed, it also updates the state with the new mode.
 * The code does not perform any complex data transformations or logic flows beyond determining the application mode based on the URL hash and managing the state accordingly.

It's important to note that this code assumes the existence of certain functions and variables, such as storePassword, getPassword, ReactModal, and _ (likely from a utility library like Lodash), which are not shown in the provided code snippet.

*/
