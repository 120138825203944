/* NODE PACKAGES */
import React from "react";
import {Spinner, Badge, Container, Stack} from 'react-bootstrap';
/* TYPES */
import { APIRegistryList } from "common/api/types";
/* UTILITY */
import {createRegistryList} from "common/api/requests";
/* CUSTOM HOOKS */
import useRegistryListStore from "hooks/useRegistryListStore";
/* CUSTOM COMPONENTS */
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";
/* TEMPLATES */
import {Page, Header, Main, Section, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// PSL INDEX LISTING
///////////////////////////////////////

const PSL = () =>
  {
  const pslList = useRegistryListStore();
  const dataList = React.useMemo<IndexListItem[]>(() => (!pslList.data || pslList.loading || pslList.error) ? [] : pslList.data.map((tldList: APIRegistryList) => ({href: `/#/registry/${tldList.id}`, name: tldList.name})), [pslList]);

  const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return ((pslList.loading)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{`Registry Lists`} <small className="text-muted">{`(${dataList?.length ?? 0})`}</small></span>
          <div className="ms-auto"></div>
          <span className={cssIcon} onClick={createRegistryList}><i className="bi bi-plus-lg"></i></span>
          </Header>
        <Main>
          <Section>
            <IndexListing data={dataList} />
            </Section>
          </Main>
        <Footer />
        </Page>);
  }

export default React.memo(PSL);

/*
Developer Notes:

 */