/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { APIRegistryList } from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';

const GetPSLList:AxiosRequestConfig = { method: 'GET', url: '/api/tld_lists', }

export type RegistryListStore =
  {
  readonly loading: boolean;
  readonly error:any;
  readonly data: APIRegistryList[] | null;
  readonly count: number;
  readonly refresh: () => void;
  };

function useRegistryListStore(): RegistryListStore
  {
  const [response, error, loading, callAxios] = useAxios(GetPSLList);
  const [data, setData] = React.useState<APIRegistryList[] | null>(null);

  React.useEffect(() =>
    {
    if (response) setData(response.tld_lists?.sort((a:APIRegistryList, b:APIRegistryList) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  const refresh = () => callAxios();

  return {loading, error, data, count: (data?.length ?? 0), refresh};
  }

export default useRegistryListStore;
