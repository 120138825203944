/* NODE PACKAGES */
import React from 'react';
import _, { set, update } from 'lodash';
/* CUSTOM COMPONENTS */
import {APIDictionary, APIRegistration, APIRuleAttribute} from 'common/api/types';
import {getRegistration, saveRegistration, deleteRegistration} from "common/api/requests";
/* HOOKS */
import {useAxios, useApplicationContext, AppContextType} from 'hooks';
import {DataDictionaryStore, useRegistrantFactsStore, RegistrantFactsStore, usePolicyStore, PolicyStore} from 'hooks';
/* UTILITY */
import { redirect } from "common/utility/window";

export type RegistrationStore =
  {
  data: APIRegistration | null;
  title: string;
  dictionary: DataDictionaryStore;
  scope_attributes: APIRuleAttribute[];
  unsavedChanges: boolean;
  singletonScopes: number[];
  registrantFacts:RegistrantFactsStore,
  policy: PolicyStore,
  updateRegistration(id: number): void;
  updateData: (newValues: Partial<APIRegistration>) => void;
  clearData: () => void;
  eventSave: () => void;
  eventDelete: () => void;
  eventCopyDefaults: () => void;
  getScopeAttribute(attID: number): APIRuleAttribute | undefined;
  updateScopeAttribute(attID: number, newValue: number): void;
  };

interface RegistrationStoreProps
  {
  registrationID: number;
  }

function useRegistrationStore(props:RegistrationStoreProps): RegistrationStore
  {
  const [response, error, loading, requestAPI] = useAxios({ method: 'GET', url: `/api/registration/${props.registrationID}`});
  const [data, setData] = React.useState<APIRegistration | null>(null);
  const [name, setName] = React.useState<string>("* | *");
  const registrantFacts:RegistrantFactsStore = useRegistrantFactsStore({registrantFactsID: data?.registrant_facts_id ?? -1});
  const policy:PolicyStore = usePolicyStore({selectedPolicyID: data?.policy_id ?? -1});
  const application = useApplicationContext();
  const singletonScopes: number[] = [1, 2, 4];

  React.useEffect(() => updateRegistration(), [props.registrationID]);

  React.useEffect(() => setData(response?.registration ?? null), [response]);

  React.useEffect(() => policy.updatePolicy(data?.policy_id ?? -1), [data?.policy_id]);

  React.useEffect(() => registrantFacts.updateFacts(data?.registrant_facts_id ?? -1), [data?.registrant_facts_id]);

  React.useEffect(() => setName(prev => `${registrantFacts.data?.name ?? "*"} | ${policy.data?.name ?? "*"}`), [registrantFacts.data?.name, policy.data?.name]);

  function updateRegistration (regID: number = props.registrationID)
    {
    if (props.registrationID === -1)
      {
      setData(null);
      }
    else
      {
      requestAPI({ method: 'GET', url: `/api/registration/${regID}`});
      }
    }

  function updateData (newValues: Partial<APIRegistration>)
    {
    if (!data) return;
    const newRegistration = Object.assign({}, data, newValues);
    setData(newRegistration as APIRegistration);
    application.unsavedChanges.current = true;
    }

  function clearData ()
    {
    setData(null);
    application.unsavedChanges.current = true;
    }

  function eventSave ()
    {
    if (data)
      {
      saveRegistration({...data, name: name}).finally(() =>
        {
        updateData({name: name});
        application.unsavedChanges.current = false;
        });
      }
    }

  function eventDelete ()
    {
    if (data && window.confirm('Are you sure you wish to delete this item?')) deleteRegistration(data.id).finally(() => redirect('/registrations/'));
    }

  function getScopeAttribute (attID: number): APIRuleAttribute | undefined
    {
    return data?.scope_attributes.filter((regAtt) => regAtt.attribute_id === attID).at(0);
    }

  function updateScopeAttribute (attID: number, newValue: number)
    {
    const copyScopeAttributes : APIRuleAttribute[] = data?.scope_attributes?.slice() ?? [];
    const existingIndex       : number             = copyScopeAttributes.findIndex((att:APIRuleAttribute) => att.attribute_id === attID);
    const newScopeAttribute   : APIRuleAttribute   = { attribute_id: attID, value: newValue };
    (existingIndex === -1) ? copyScopeAttributes.push(newScopeAttribute) : copyScopeAttributes[existingIndex] = newScopeAttribute;
    updateData({scope_attributes: copyScopeAttributes });
    }

  function eventCopyDefaults ()
    {
    registrantFacts.eventCopyDefaults(policy.data);
    }

  return (
    {
    data,
    title: name,
    dictionary: application.dictionary,
    scope_attributes: data?.scope_attributes ?? [],
    unsavedChanges: application.unsavedChanges.current,
    singletonScopes,
    registrantFacts,
    policy,
    eventCopyDefaults,
    updateRegistration,
    updateData,
    clearData,
    eventSave,
    eventDelete,
    getScopeAttribute,
    updateScopeAttribute
    });
  }

export default useRegistrationStore;
