/* NODE PACKAGES */
import React from 'react';
/* CUSTOM COMPONENTS */
import {APIDictionary, APITypedAttribute, APIRule, APIRuleAttribute, APIAttribute, APIAttributeOption, APIElementGroup, APIElement} from 'common/api/types';
import {useAxios, requestAPI} from 'hooks';
/* UTILITY */
import { getAttributeFromRule, getRuleForElement } from 'common/api/requests';
/* ASSETS */
let dictionary: APIDictionary = require('common/assets/dictionary.json');


export const getDataDictionary = (): Promise<APIDictionary> => requestAPI({method: "GET", url: '/api/data_dictionary'}).then((response) => response?.data ?? null);


export interface DataDictionaryStore
  {
  data: APIDictionary | null;
  rule_attributes: APIAttribute[] | undefined;
  element_groups: APIElementGroup[] | undefined;
  element_groups_flat: [APIElementGroup | null, APIElement][] | undefined;
  scope_attributes: APIAttribute[] | undefined;
  registration_attributes: APIAttribute[] | undefined;
  request_attributes: APITypedAttribute[] | undefined;
  getRuleAttributeOption: (id: number, rule: APIRule) => APIAttributeOption | undefined;
  getRuleAttribute: (attID: number) => APIAttribute | undefined;
  getScopeAttribute: (attID: number) => APIAttribute | undefined;
  getElementGroupByID: (groupID: number) => APIElementGroup | undefined;
  getElementByID: (elementID: number, groupID: number) => APIElement | undefined;
  getRegistrationAttribute: (attID: number) => APIAttribute | undefined;
  getRequestAttributeByID: (attID: number) => APITypedAttribute | undefined;
  getRequestAttributesByType: (type: "rule" | "wrapper" | "header" | "fill") => APITypedAttribute[] | undefined;
  };

interface DictionaryDataProps
  {
  authenticated: boolean;
  }

function useDictionaryStore(props:DictionaryDataProps): DataDictionaryStore
  {
  // const [response, error, loading, callAxios] = useAxios({ method: 'GET', url: '/api/data_dictionary', });
  const [data, setData] = React.useState<APIDictionary | null>(dictionary ?? null);

  // React.useEffect(() => setData(response.data ?? null), [response]);

  // Retrieves the API attribute object for the specified attribute ID.
  function getRuleAttribute (attID: number): APIAttribute | undefined
    {
    return data?.rule_attributes.find(att => att.id === attID);
    };

  // Retrieves the value of a specific attribute for a given rule.
  function getRuleAttributeOption (id: number, rule: APIRule): APIAttributeOption | undefined
    {
    const attribute: APIRuleAttribute | undefined = getAttributeFromRule(rule, id);
    return (attribute !== undefined) ? data?.rule_attributes.find(att => att.id === attribute.attribute_id)?.values?.at(attribute?.value) : undefined;
    };

  function getScopeAttribute (attID: number): APIAttribute | undefined
    {
    return data?.scope_attributes?.find(att => att.id === attID);
    };

  function getRegistrationAttribute (attID: number): APIAttribute | undefined
    {
    return data?.registration_attributes?.find(att => att.id === attID);
    };

  function getElementGroupByID (groupID: number): APIElementGroup | undefined
    {
    return data?.element_groups?.find(att => att.id === groupID);
    };

  function getElementByID (elementID: number, groupID: number): APIElement | undefined
    {
    return data?.element_groups?.find(att => att.id === groupID)?.elements?.find(att => att.id === elementID);
    };

  function getRequestAttributeByID (attID: number): APITypedAttribute | undefined
    {
    return data?.request_attributes?.find((field: APITypedAttribute) => field.attribute.id === attID);
    }

  function getRequestAttributesByType (type: "rule" | "wrapper" | "header" | "fill"): APITypedAttribute[] | undefined
    {
    return data?.request_attributes?.filter(att => att.type === type);
    }

  function consolidateElementGroups (): [APIElementGroup | null, APIElement][]
    {
    const elements: [APIElementGroup | null, APIElement][] = [];
    data?.element_groups.forEach((group: APIElementGroup) => group.elements.forEach((element: APIElement, index: number) => elements.push([(index === 0) ? group : null, element])));
    return elements;
    }

  return ({
    data,
    rule_attributes: data?.rule_attributes,
    element_groups: data?.element_groups,
    element_groups_flat: consolidateElementGroups(),
    scope_attributes: data?.scope_attributes,
    registration_attributes: data?.registration_attributes,
    request_attributes: data?.request_attributes,
    getRuleAttribute,
    getRuleAttributeOption,
    getElementGroupByID,
    getElementByID,
    getRegistrationAttribute,
    getScopeAttribute,
    getRequestAttributeByID,
    getRequestAttributesByType
    });
  }

export default useDictionaryStore;



/////////////////////////////////////////////////
// DICTIONARY CONSTANTS
/////////////////////////////////////////////////

// Request Templates
export enum TemplateAttributes
  {
  Rule_RequesterID	      =  1, // rule - remove from database migration
  Rule_SearchContact	    =  2, // rule - remove from database migration
  Rule_SearchEmail	      =  3, // rule - remove from database migration
  Rule_Processing	        =  4, // rule
  Rule_ExigentOK	        =  5, // rule
  Rule_DNS	              =  6, // rule
  Rule_RegOp	            =  7, // rule
  Rule_Forensic	          =  8, // rule
  Rule_Org	              =  9, // rule
  Rule_Name	              = 10, // rule
  Rule_Phone	            = 11, // rule
  Rule_Email	            = 12, // rule
  Rule_Location	          = 13, // rule
  Rule_UniqueID	          = 14, // rule
  Rule_Sensitivity	      = 15, // rule
  Rule_Logging	          = 16, // rule
  Metadata_OrgName	      = 17, // wrapper
  Metadata_OrgType	      = 18, // wrapper
  Metadata_PrimePOC	      = 19, // wrapper
  Metadata_PrimeEmail     = 20, // wrapper
  Metadata_AltPOC	        = 21, // wrapper
  Metadata_AltEmail	      = 22, // wrapper
  Metadata_Updated	      = 23, // wrapper
  Metadata_Version	      = 24, // wrapper
  Metadata_Completion	    = 25, // wrapper
  Metadata_Status	        = 26, // wrapper
  Metadata_Date	          = 27, // wrapper
  Metadata_Distribution   = 28, // wrapper
  Header_AccredAuth	      = 29, // header
  Header_Description	    = 30, // header
  UI_Date	                = 31, // fill
  UI_RequesterID	        = 32, // fill
  UI_Exigent	            = 33, // fill
  UI_Domain	              = 34, // fill
  UI_Contact	            = 35, // fill
  UI_Email	              = 36, // fill
  UI_ProcessingNotes      = 37, // fill
  UI_Organization         = 38, // fill    - add to database migration
  Expected_Fields         = 39, // wrapper - add to database migration
  Identity_Service        = 40, // wrapper - add to database migration
  Accredting_Enforcement  = 41, // wrapper - add to database migration
  Purposes_Protection     = 42, // wrapper - add to database migration
  Jurisdictions           = 43, // wrapper - add to database migration
  }
