/* NODE PACKAGES */
import React from "react";
import {Spinner, Alert, Button, ListGroup, ListGroupItem} from 'react-bootstrap';
/* API */
import { APIRequester, APIRequesterGroup, APIRequesterGroupMembership} from "common/api/types";
import { joinRqG, leaveRqG, getRqGJoined, getAllRqGs, saveRequester, deleteRequester} from "common/api/requests";
/* HOOKS & UTILITY */
import { useAxios, useRegistrarGroupMembersStore, RegistrarStore} from "hooks";
import { redirect } from "common/utility/window";
/* CUSTOM COMPONENTS */
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import FileMenu, {FileMenuDivider, FileMenuHelp, FileMenuSave, FileMenuDelete} from 'components/molecules/Menu/File';
import {Page, Header, Main, Section, FlexBox, Footer} from "components/atoms/Templates";

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

export const enum RequesterSelectMode
  {
  enroll = "Enroll",
  cancel = "Cancel",
  }

////////////////////////////////
// REQUESTER EDITOR
////////////////////////////////

interface RequesterEditorProps
  {
  selectedRequesterID: number,
  unsavedChanges: (flag: boolean) => void;
  }

function RequesterEditor (props: RequesterEditorProps)
  {
  const [requester, setRequester] = React.useState<APIRequester | null>(null);
  const [RqGJoined, setRqGJoined] = React.useState<APIRequesterGroupMembership[] | null>(null);
  const [enrolled, setEnrolled] = React.useState<APIRequesterGroup[] | null>(null);
  const [cancelled, setCancelled] = React.useState<APIRequesterGroup[] | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const registrarGroupMembers:RegistrarStore = useRegistrarGroupMembersStore();

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(unsavedChanges);
    }, [unsavedChanges]);

  React.useEffect(() =>
    {
    if (!registrarGroupMembers.requesters) return;
    setRequester(registrarGroupMembers.requesters.find((requester:APIRequester) => requester.id === props.selectedRequesterID) ?? null);
    }, [registrarGroupMembers.requesters]);

  React.useEffect(() =>
    {
    getRqGJoined(props.selectedRequesterID).then(setRqGJoined);
    }, [registrarGroupMembers.requesterGroups]);

  React.useEffect(() =>
    {
    if (!registrarGroupMembers.requesterGroups || !RqGJoined) return;
    setEnrolled  (registrarGroupMembers.requesterGroups.filter((group:APIRequesterGroup) => RqGJoined.filter((rqG:APIRequesterGroupMembership) => group.id === rqG.requester_group_id && !rqG.deleted)[0]));
    setCancelled (registrarGroupMembers.requesterGroups.filter((group:APIRequesterGroup) => !RqGJoined.filter((rqG:APIRequesterGroupMembership) => group.id === rqG.requester_group_id && !rqG.deleted)[0]));
    }, [RqGJoined]);

  const eventChangeTitle = (newValue: string) =>
    {
    const newRequestObject = Object.assign({}, requester, {name: newValue});
    setRequester(newRequestObject);
    setUnsavedChanges(true);
    };

  const eventUpdateLists = (group: APIRequesterGroup, mode: RequesterSelectMode) =>
    {
    if (!props.selectedRequesterID || !RqGJoined) return;
    if (mode === RequesterSelectMode.enroll) joinRqG(props.selectedRequesterID, group.id, RqGJoined).then(response => registrarGroupMembers.refresh()).catch(error => console.error("Error joining group:", error))
    if (mode === RequesterSelectMode.cancel) leaveRqG(props.selectedRequesterID, group.id).then(response => registrarGroupMembers.refresh()).catch((error) => console.error("Cancel Error » ", error));
    };

  const eventSave = () =>
    {
    if (!requester) return;
    saveRequester(requester); setUnsavedChanges(false);
    };

  const eventDelete = () =>
    {
    if (!requester) return;
    if (window.confirm('Are you sure you wish to delete this item?'))
    deleteRequester(requester.id).then(() => redirect('/registrars/'));
    };

  return ((registrarGroupMembers.loading)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : (registrarGroupMembers.error || !requester || !registrarGroupMembers.requesterGroups || registrarGroupMembers.requesterGroupCount <= 0 || !enrolled)
      ? <Alert variant="warning">{"Data is not available at this time."}</Alert>
      : <Page>
          <Header>
            <EditableLabel value={requester.name} onValueChange={eventChangeTitle} />
            <FileMenu dirtyFlag={unsavedChanges}>
              <FileMenuSave onClick={eventSave} />
              <FileMenuDelete onClick={eventDelete} />
              <FileMenuDivider />
              <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#registrars.md" />
              </FileMenu>
            </Header>
          <Main>
            <Section>
              <h4 className="d-block text-center mx-auto my-0 mb-3 p-0">{enrolled.length} Subscriptions</h4>
              <ListGroup className="d-block w-100 bg-white border-0 shadow-sm">
                {
                registrarGroupMembers.requesterGroups?.map((rqGroup: APIRequesterGroup) =>
                  {
                  const notEnrolled = (enrolled.length < 1 || enrolled.find(joinedGroup => joinedGroup.id === rqGroup.id) === undefined);
                  return <ListGroupItem key={`rqG-${rqGroup.id}`} className={notEnrolled ? "d-flex flex-row justify-content-between align-items-center lh-1 bg-white" : "d-flex justify-content-between align-items-center lh-1 text-bg-success"}>
                    <span>{rqGroup.name}</span>
                      {
                      notEnrolled
                        ? <Button variant="success" className="m-0 p-1 lh-1" onClick={() => eventUpdateLists(rqGroup, RequesterSelectMode.enroll)}><i className="bi bi-plus-slash-minus lh-1"></i></Button>
                        : <Button variant="dark" className="m-0 p-1 lh-1" onClick={() => eventUpdateLists(rqGroup, RequesterSelectMode.cancel)}><i className="bi bi-plus-slash-minus lh-1"></i></Button>
                      }
                    </ListGroupItem>
                  }
                )}
                </ListGroup>
              </Section>
            </Main>
          <Footer />
          </Page>);
  };

export default RequesterEditor;



/* Development Notes:

@web/src/pages/Registrars/RequesterEditor.tsx:1-128 is a React component that renders a user interface for managing and editing a "Requester" entity. A Requester is likely an object or data structure representing an individual or organization that can make requests within the application's domain.

The component takes an input prop called selectedRequesterID, which is expected to be a number or null value. This prop is likely used to identify the specific Requester that should be loaded and displayed in the component.

The purpose of this code is to fetch data related to the selected Requester from an API, display that data in a form-like interface, and allow the user to make changes to the Requester's properties. The component also provides functionality to save the changes back to the API or create a new Requester if no existing one was selected.

To achieve this, the code follows a common pattern in React applications. It uses React hooks to manage the component's state and lifecycle methods. Here's a breakdown of the logic flow:

The component initializes several state variables using the useState hook. These state variables will store data fetched from the API, loading indicators, and other relevant information.

When the component mounts, it checks if a selectedRequesterID prop was provided. If so, it fetches the corresponding Requester data from the API using the useAxios hook, which is likely a custom hook for making API requests.

The fetched Requester data is stored in the requester state variable, and other related data, such as the Requester's group memberships and a list of all available Requester groups, are fetched and stored in their respective state variables.

The component renders a user interface based on the fetched data. This interface likely includes input fields or forms where the user can modify the Requester's properties, such as its name or other attributes.

When the user makes changes to the input fields, the component updates its state accordingly using the useState hook's update functions.

The component provides buttons or other UI elements that allow the user to save the changes or create a new Requester. When the user interacts with these elements, the component calls functions (likely defined elsewhere in the codebase) to send the updated Requester data back to the API.

Throughout the process, the component manages loading indicators and error states to provide feedback to the user about the status of API requests and data fetching operations.

The code achieves its purpose by leveraging React's component-based architecture, hooks for state management and lifecycle methods, and integration with an API for fetching and updating data. It follows a common pattern of fetching data on component mount, rendering a user interface based on that data, allowing user input to modify the data, and providing mechanisms to persist those changes back to the API.

*/