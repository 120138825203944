import React from 'react';
import { Button, Modal, Dropdown, Form } from 'react-bootstrap';
import { APIAttribute, APIDictionary, APIPolicy, APIRule } from 'common/api/types';
import { getRuleForElement } from 'common/api/requests';
import { flattenDictElements, updatePolicyRuleAttributes, mergeAttributes } from 'pages/Policies/PolicyEditor';

interface CopyPasteToolProps
  {
  range: [number, number] | null;
  dataDictionary: APIDictionary;
  policy: APIPolicy | null;
  updatePolicy: (policy: APIPolicy) => void;
  }

function CopyPasteTool(props: CopyPasteToolProps)
  {
  const [pasteSpecialModal, setPasteSpecialModal] = React.useState(false);
  const [copiedData, setCopiedData] = React.useState<APIRule[] | null>(null);
  const [pasteOnly, setPasteOnly] = React.useState<number[]>(props.dataDictionary.rule_attributes.map(att => att.id));

  /**
   * Copies the selected policy rules into state.
   * Gets the min/max row indexes from the selection range.
   * Uses the flattened data dictionary to get the element IDs for those rows.
   * Maps the element IDs to their policy rules.
   * Sets the copied rules in state to use later for pasting.
   */
  function copyData()
    {
    const selectRange = props.range;
    const policy = props.policy;

    if (!selectRange || !policy) return;

    let min: number, max: number;
    if (selectRange[0] > selectRange[1])
      {
      min = selectRange[1];
      max = selectRange[0];
      }
    else
      {
      min = selectRange[0];
      max = selectRange[1];
      }

    const flattenedElements = flattenDictElements(props.dataDictionary);
    const selectedElements = flattenedElements.slice(min, max + 1);
    const copiedRules = selectedElements.map(item => getRuleForElement(policy, item[1].id) ?? { id: 0, element_id: item[1].id, attributes: [] });
    setCopiedData(copiedRules);
    }


  // Pastes copied policy rule attributes into the policy based on the selected paste range. Handles two cases:
  // 1) Repeating a single row: pastes the attributes from the copied row into all rows in the paste selection range.
  // 2) Pasting a block: pastes attributes from multiple copied rows into a block of rows starting at the paste selection start index.
  function pasteData()
    {
    const selectRange = props.range;
    let policy = props.policy;
    if (!selectRange || !policy || !copiedData) { return; }
    const flattenedElements = flattenDictElements(props.dataDictionary);

    if (copiedData.length === 1)
      {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1])
        {
        min = selectRange[1];
        max = selectRange[0];
        }
      else
        {
        min = selectRange[0];
        max = selectRange[1];
        }
      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++)
        {
        const element = flattenedElements[i][1];
        policy = updatePolicyRuleAttributes(policy, element.id, copyElement.attributes);
        }
      }
    else
      {
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++)
        {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id, copiedData[i].attributes);
        }
      }
    props.updatePolicy(policy);
    }

  function onCheckboxChange(checked: boolean, att: APIAttribute)
    {
    if (checked) setPasteOnly([...pasteOnly, att.id]);
    else setPasteOnly(pasteOnly.filter(attID => attID !== att.id));
    }

  // pasteSpecial lets the user choose which columns to paste instead of pasting all of them
  function pasteSpecial()
    {
    const selectRange = props.range;
    let policy = props.policy;
    if (!selectRange || !policy || !copiedData) { return; }
    const flattenedElements = flattenDictElements(props.dataDictionary);

    // two cases: we are repeating one row many times, or copying many rows starting from a particular index
    // 1) repeat one row many times
    // 2) copy a block of data beginning at specific element
    if (copiedData.length === 1)
      {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1])
        {
        min = selectRange[1];
        max = selectRange[0];
        }
      else
        {
        min = selectRange[0];
        max = selectRange[1];
        }
      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++)
        {
        const element = flattenedElements[i][1];
        const existingData = getRuleForElement(policy, element.id);
        policy = updatePolicyRuleAttributes(policy, element.id, mergeAttributes(existingData?.attributes ?? [], copyElement.attributes, pasteOnly));
        }
      }
    else
      {
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++)
        {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        const existingData = getRuleForElement(policy, targetElements[i][1].id);
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id, mergeAttributes(existingData?.attributes ?? [], copiedData[i].attributes, pasteOnly));
        }
      }
    props.updatePolicy(policy);
    }

  return (<>
    <Dropdown className={props.range ? "d-print-none d-block" : "d-none"} style={{ position: 'fixed', bottom: '1.0em', left: '1.0em', zIndex: '1000' }}>
      <Dropdown.Toggle variant="success">Copy/Paste</Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item onClick={() => copyData()}>Copy row values</Dropdown.Item>
        <Dropdown.Item disabled={copiedData === null} onClick={() => pasteData()}>{(copiedData === null) ? "Paste row values" : copiedData.length === 1 ? "Duplicate 1 row across selection" : `Paste ${copiedData.length} rows of values`}</Dropdown.Item>
        <Dropdown.Item disabled={copiedData === null} onClick={() => setPasteSpecialModal(true)}>Paste only...</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <Modal size="xl" scrollable show={pasteSpecialModal} onHide={() => setPasteSpecialModal(false)}>
      <Modal.Header closeButton><Modal.Title>Paste only...</Modal.Title></Modal.Header>
      <Modal.Body>
        <ul>{props.dataDictionary.rule_attributes.map(att => (<li key={att.id}><Form.Check id="pasteSpecialOption" type="checkbox" label={att.name} checked={pasteOnly.includes(att.id)} onChange={(e) => onCheckboxChange(e.target.checked, att)} /></li>))}</ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { pasteSpecial(); setPasteSpecialModal(false); }}><i className="bi bi-clipboard"></i> Paste</Button>
      </Modal.Footer>
    </Modal>
  </>);
  }

export default CopyPasteTool;