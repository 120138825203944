/* NODE PACKAGES */
import React, { useState } from 'react';
import {Spinner, Container, Row, Col, Alert, Table, Form, Card, CardGroup, Dropdown, Stack, FloatingLabel, Button, ButtonGroup, ToggleButtonGroup, ToggleButton, Modal} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import { APIPolicy } from 'common/api/types';
import { savePolicy } from 'common/api/requests';

// Function to check the integrity of the imported file
async function checkFile(files:FileList | null, setData: React.Dispatch<React.SetStateAction<string | null>>, setFileError: React.Dispatch<React.SetStateAction<string | null>>)
  {
  let jsonData;
  try
    {
    // Check if a file is selected
    if (files)
      {
      // Read the content of the file
      let data = await getTextFromFile(files[0]);
      // Handle potential errors
      if (data instanceof DOMException)
        {
        setFileError(data.message);
        }
      else if (typeof data === 'string')
        {
        jsonData = JSON.parse(data);
        setData(data);
        setFileError(null);
        //check file for required database fields
        const POST_POLICY_REQUIRED_FIELDS = ['name', 'tld_list_id', 'rules', 'scope_attributes'];
        for (let i = 0; i < POST_POLICY_REQUIRED_FIELDS.length; i++)
          {
          if (!jsonData.hasOwnProperty(POST_POLICY_REQUIRED_FIELDS[i]))
            {
            setFileError("Data does not meet database expectations. Please reexport the policy and try again.");
            return;
            }
          }
        }
      }
    else
      {
      setFileError("Please select a file");
      }
    }
  catch (e)
    {
    // Handle parsing errors
    let message = "An error has occurred. Please reexport and try again."; //Default error message
    if (e instanceof SyntaxError) message = "Data is not formatted properly. Please try again with a properly formatted file.";
    setFileError(message);
    return;
    }
  }

// Function to read text content from a file
function getTextFromFile(file: File)
  {
  return new Promise ((resolve, reject) =>
    {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {if (typeof(fileReader.result) === 'string') resolve(fileReader.result);}
    fileReader.onerror = (e) => reject(fileReader.error);
    fileReader.readAsText(file);
    });
  }

// Function to import policy from file
async function importPolicy(fileData: string | null, policyID: number, setFileError: React.Dispatch<React.SetStateAction<string | null>>)
  {
  if (fileData)
    {
    try
      {
      // Parse the file data and assign the policy ID
      let policy = JSON.parse(fileData) as APIPolicy;
      policy.id = policyID;
      // Save the policy and handle success or failure
      const status = await savePolicy(policy);
      if (status) {window.location.reload(); return;}
      setFileError('An error has occurred. Please try again later.');
      }
    catch (error)
      {
      setFileError('An error has occurred. Please try again later.');
      }
    }
  }

// Props for ImportPolicy component
interface ImportPolicyProps
  {
  policyID: number;
  open: boolean;
  closeFunc: () => void;
  }

// ImportPolicy component
const ImportPolicyModal: React.FC<ImportPolicyProps> = (props) =>
  {
  // State to hold file data and file error
  const [fileData, setFileData] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>("Please select a file");

  return <Modal show={props.open} onHide={props.closeFunc} autoFocus={false} size='lg' centered={true} backdrop='static' keyboard={false} restoreFocus={true} enforceFocus={true} animation={true}>
    <Modal.Header closeButton><Modal.Title> Import Policy </Modal.Title></Modal.Header>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            <Form.Group controlId="formFile">
              <Form.Control type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkFile(e.target.files, setFileData, setFileError)} accept={".policy"} />
              {fileError === null ? <Button onClick={() => importPolicy(fileData, props.policyID, setFileError)}>Import</Button> : <Form.Text>{fileError}</Form.Text>}
              </Form.Group>
            </Col>
          </Row>
        </Container>
    </Modal.Body>
    </Modal>
  }

export default ImportPolicyModal;

/*
Development Notes:

Original Code »
<ReactModal isOpen={props.open} onRequestClose={props.closeFunc} appElement={DOM.root}>
  <CloseButton closeFunc={props.closeFunc}/>
  <input type={"file"} accept={".policy"} onChange={(e) => { checkFile(e, setFileData, setFileError) }}/>
  {fileError === null ? <p><button onClick={() => {importPolicy(fileData, props.policyID, setFileError)}}>Import</button></p> : <p style={{ textAlign: "center" }}>{fileError}</p>}
  </ReactModal>

*/