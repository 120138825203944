/* NODE PACKAGES */
import React from 'react';
import _ from 'lodash';
import {ButtonGroup, Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip, Popover, Stack} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import { APIAttribute, APIRuleAttribute, APIRegistryList} from "common/api/types";
import {useRegistryListStore, RegistryListStore} from "hooks";
import DataBox from 'components/atoms/Inputs/DataBox';
import styles from 'components/atoms/Inputs/DataBox.module.css';

///////////////////////////////////////
// REGISTRY LIST BOX
///////////////////////////////////////

interface RegistryListProps
  {
  ruleScope: APIRegistryList | null;
  comparisonScope?: APIRegistryList | null;
  onRegistryListChange: (tldList: APIRegistryList | null) => void; // Callback when TLD list changes
  }

// Renders a selector for choosing a TLD list, with modal popup.
export function RegistryListBox (props: RegistryListProps)
  {
  const registryList = useRegistryListStore();
  const ruleValue = (!props.ruleScope && props.ruleScope === null)
    ? undefined
    : _.intersection(props.ruleScope?.tlds, props.comparisonScope?.tlds ?? []).length ? props.ruleScope.id : undefined;

  const comparisonValue = props.comparisonScope === undefined
    ? undefined
    : _.difference(props.ruleScope?.tlds, props.comparisonScope?.tlds ?? []).length ? props.comparisonScope?.id : undefined;

  return <DataBox ruleValue={props.ruleScope?.id} comparisonValue={props.comparisonScope?.id} attribute={{ id: 0, name: "Registry List", values: [] }} alternateDropdown={registryList.data?.map((item: APIRegistryList) => ({ value: item.id, name: item.name, className: "rule_dictionary" })) ?? []} onChange={(newValue: number) => props.onRegistryListChange(registryList.data?.find(item => item.id === newValue) ?? null)} />
  }

///////////////////////////////////////
// SCOPE TOOLBAR
///////////////////////////////////////

interface ScopeProps
  {
  scopeAttributes: APIAttribute[]; // Available scope attributes to choose from
  selectedScopeAttributes: APIRuleAttribute[]; // Array of currently selected scope attributes
  comparisonScopeAttributes?: APIRuleAttribute[]; // Array of currently selected scope attributes
  ruleRegistryScope: APIRegistryList | null; // Selected TLD list, or null if none selected
  comparisonRegistryScope?: APIRegistryList | null; // Selected TLD list, or null if none selected
  defaultDropdown: number; // Default value for dropdown selects
  onAttributeChange: (newScopeAttr: APIRuleAttribute[]) => void; // Callback when scope attributes change
  onRegistryListChange: (registry: APIRegistryList | null) => void; // Callback when TLD list changes
  }

function Scope (props: ScopeProps)
  {
  function eventChangeAttribute (attID: number, newVal: number)
    {
    const newScopeAtts = [...props.selectedScopeAttributes];
    const existingAttIndex = newScopeAtts.findIndex(att => att.attribute_id === attID);
    if (existingAttIndex !== -1) newScopeAtts[existingAttIndex] = {...newScopeAtts[existingAttIndex], value: newVal };
    else newScopeAtts.push({ attribute_id: attID, value: newVal });
    props.onAttributeChange(newScopeAtts);
    };

  function extractScopeAttribute (att: APIAttribute)
    {
    const ruleValue       : number = props.selectedScopeAttributes?.find(sa => sa.attribute_id === att.id)?.value ?? 0;
    const comparisonValue : number | undefined = props.comparisonScopeAttributes ? props.comparisonScopeAttributes.find(c => c.attribute_id === att.id)?.value ?? 0 : undefined;
    return <div key={`att-${att.id}`}><span className="text-muted"><small>{att.name}</small></span>
      <DataBox ruleValue={ruleValue} comparisonValue={comparisonValue} attribute={att} onChange={(newValue: number) => eventChangeAttribute(att.id, newValue)} />
      </div>
    }

  const attributes = props.scopeAttributes.map(extractScopeAttribute);

  return (<div className="d-flex flex-row justify-content-center align-items-start gap-1 m-0 p-0" aria-description='Scope attributes'>
    <div><span className="text-muted"><small>{"Registry List"}</small></span><RegistryListBox ruleScope={props.ruleRegistryScope} comparisonScope={props.comparisonRegistryScope} onRegistryListChange={props.onRegistryListChange} /></div>
    {attributes}
    </div>);
  }

export default Scope;

// ///////////////////////////////////////
// // ELIDED LIST
// ///////////////////////////////////////

// interface ElidedListProps
//   {
//   list: APITLDList;
//   selectTLDList: (newTLDList: APITLDList) => void;
//   active: boolean;
//   }

// export function ElidedList(props: ElidedListProps)
//   {
//   return (<Dropdown.Item active={props.active} eventKey={props.list.id} onClick={() => props.selectTLDList(props.list)} className={`${styles.data_option}`}><span><small>{props.list.name} {`(${props.list.tlds.length})`}</small></span></Dropdown.Item>);
//   }

// Popover disabled
// const popover = (<Popover className={props.list.tlds.length ? "d-block bg-white text-dark font-monospace lh-base border-0" : "d-none"} style={{maxWidth: '500px', overflowY: 'scroll'}}>
//   <Popover.Header as="h6" className="border-0">TLD List</Popover.Header>
//   <Popover.Body>{props.list.tlds.join(', ')}</Popover.Body>
//   </Popover>);
// return (<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
//   <Dropdown.Item active={props.active} eventKey={props.list.id} onClick={() => props.selectTLDList(props.list)} className="d-flex flex-row justify-content-between align-items-center m-0 px-2 py-2 font-monospace lh-base">
//     <span>{props.list.name}</span>
//     <span className={props.list.tlds.length > 0 ? "d-block fst-italic border-bottom lh-base" : "d-none"}><small>{`(${props.list.tlds.length})`}</small></span>
//     </Dropdown.Item>
//   </OverlayTrigger>);
