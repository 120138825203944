/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* API */
import { APIRegistration, PolicyListItem, NamedItem } from 'common/api/types';
/* HOOKS */
import {useAxios, usePolicyListStore, useRegistrantFactsListStore, PolicyListStore, RegistrantFactsListStore} from 'hooks';

export type RegistrationListStore =
  {
  readonly loading: boolean;
  readonly error:any;
  readonly data: APIRegistration[] | null;
  readonly count: number;
  readonly refresh: () => void;
  };

function useRegistrationListStore(): RegistrationListStore
  {
  const [response, error, loading, requestAPI] = useAxios({url: '/api/request_query_all', method: 'GET'});
  const [data, setData] = React.useState<APIRegistration[] | null>(null);
  const policyList:PolicyListStore = usePolicyListStore();
  const registrantFactsList:RegistrantFactsListStore = useRegistrantFactsListStore();

  const sortRegistrations = (a:APIRegistration, b:APIRegistration) => a.name.localeCompare(b.name);
  const mapNamesToIDs = (registration: APIRegistration) => ({ ...registration, name: `${registrantFactsList.getByID(registration.registrant_facts_id)?.name ?? "*"} | ${policyList.getByID(registration.policy_id)?.name ?? "*"}`});

  React.useEffect(() =>
    {
    if (response && response?.registrations && registrantFactsList.data && policyList.data)
      {
      const list = response.registrations.map(mapNamesToIDs).sort(sortRegistrations) ?? [];
      setData(list);
      }
    }, [response, registrantFactsList.data, policyList.data]);

  return ({
    loading,
    error,
    data,
    count: (data?.length ?? 0),
    refresh: () => requestAPI(),
    });
  }

export default useRegistrationListStore;
