/* NODE PACKAGES */
import React from 'react';
import {Stack, Button} from 'react-bootstrap';
/* TYPES */
import { APIAttribute, APIRuleAttribute, APIRule, RULE_COMPARISON, RULE_ATTRIBUTES, RULE_COLLECTION} from 'common/api/types';
/* UTILITIES */
import { getAttributeFromRule } from 'common/api/requests';
/* CUSTOM COMPONENTS */
import DataBox from 'components/atoms/Inputs/DataBox';

///////////////////////////////
// Policy Rule Cell
///////////////////////////////

interface PolicyRuleCellProps
  {
  rule: APIRule;
  comparisonRule?: APIRule | null;
  attribute: APIAttribute;
  onChange: (newVal: number) => void;
  }

// split this logic out of PolicyRule so that it's easier to reuse when displaying two policies side by side
export function PolicyRuleCell(props: PolicyRuleCellProps) : JSX.Element | null
  {
  const ruleAtt : APIRuleAttribute | undefined = getAttributeFromRule(props.rule, props.attribute.id);

  if (props.attribute.id !== RULE_ATTRIBUTES.COLL)
    {
    const collAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.COLL);

    if (collAtt && collAtt.value === RULE_COLLECTION.DONOTCOLLECT) return null; // return empty placeholder on elements with DONOTCOLLECT label
    }

  if (!props.comparisonRule)
    {
    return <DataBox ruleValue={ruleAtt?.value} attribute={props.attribute} onChange={props.onChange} />
    }

  if (props.comparisonRule)
    {
    const comparisonRuleAtt : APIRuleAttribute | undefined = getAttributeFromRule(props.comparisonRule, props.attribute.id);

    return <DataBox ruleValue={ruleAtt?.value ?? 0} comparisonValue={comparisonRuleAtt?.value ?? 0} attribute={props.attribute} onChange={props.onChange} />
    }

  return null;
  }



