///////////////////////////////////
// API RESPONSE types
///////////////////////////////////

export interface APISuccess
  {
  status: "success";
  }

export interface APIError
  {
  status: "error";
  error: string;
  }

///////////////////////////////////
// DATA DICTIONARY types
///////////////////////////////////

export interface APIDictionary
  {
  rule_attributes: APIAttribute[];
  scope_attributes: APIAttribute[];
  registration_attributes: APIAttribute[];
  element_groups: APIElementGroup[];
  request_attributes: APITypedAttribute[];
  }

export interface APIAttribute
  {
  id: number;
  name: string;
  values: APIAttributeOption[];
  }

export interface APITypedAttribute
  {
  type: string;
  attribute: APIAttribute;
  }

export interface APIAttributeOption
  {
  value: number;
  className: string;
  name: string;
  description?: string;
  }

export interface APIElementGroup
  {
  id: number;
  name: string;
  elements: APIElement[];
  }

export interface APIElement
  {
  id: number;
  name: string;
  element_category_id: number;
  element_category_name: string;
  rr_controlled: boolean;
  }

export const NULL_OPTION: APIAttributeOption =
  {
  value: 0,
  className: "rule_null",
  name: "∅",
  description: "No decision"
  }

export const ANY_OPTION: APIAttributeOption =
  {
  value: 15,
  className: "rule_null",
  name: "Any",
  description: "No decision"
  }

// API Requests

export type GetDataDictionary =
  {
  status: "success";
  data: APIDictionary;
  } | APIError;


///////////////////////////////////
// API RULE types
///////////////////////////////////

export enum RULE_COMPARISON
  {
  NULL = "rule_null",
  EQUAL = "rule_equal",
  SUBSET = "rule_subset",
  SUPERSET = "rule_superset",
  INCOMPATIBLE = "rule_incompatible",
  }

export enum RULE_ATTRIBUTES
  {
  COLL = 1,
  VAL = 2,
  V3RQ = 3,
  SENS = 4,
  DG = 5,
  STORE = 6
  };

export enum RULE_COLLECTION
  {
  NULL = 0,
  COLLECT = 1,
  DONOTCOLLECT = 2,
  COLLECT_DONOTCOLLECT = 3,
  OPTIONAL = 4,
  COLLECT_OPTIONAL = 5,
  OPTIONAL_DONOTCOLLECT = 6,
  ANY = 7
  };

export interface APIRuleGroup
  {
  group_id: number;
  rules: APIRule[];
  }

export interface APIRule
  {
  id: number;
  element_id: number;
  attributes: APIRuleAttribute[];
  notes?: string;
  }

// Used for describing a particular attribute instance's value not used for enumerating the possible values an attribute can have; See: APIAttributeValue
export interface APIRuleAttribute
  {
  attribute_id: number;
  value: number;
  }

// API Requests

export type GetPolicy =
  {
  status: "success";
  policy: APIPolicy;
  } | APIError;

export type NewPolicy =
  {
  status: "success";
  id: number;
  } | APIError;

///////////////////////////////////
// POLICY LIST types
///////////////////////////////////

export type PolicyListItem =
  {
  id: number;
  name: string;
  org_name: string;
  org_type: WorkingPolicy['org_type'];
  prime_poc: string;
  version: number;
  status: string;
  deleted: boolean;
  modified: number;
  }

// API Requests

export type GetPolicyList =
  {
  status: "success";
  policies: PolicyListItem[];
  } | APIError;

///////////////////////////////////
// POLICY types
///////////////////////////////////

// WorkingPolicy contains the data that the editor would need to know for work-in-progress data, i.e. what it needs to save to the server
export interface WorkingPolicy
  {
  id?: number;
  name: string;
  rules: APIRule[];
  subject_to: number[];
  // Scope
  tld_list_id: number | null;
  scope_attributes: APIRuleAttribute[];
  // Metadata
  org_name: string;
  org_type: "policy_authority" | "registry" | "registrar";
  prime_poc: string;
  prime_email: string;
  alt_poc: string;
  alt_email: string;
  version: number; // TBD... is this user controllable?
  effective_date: string;
  updated_date: string;
  completion: "draft" | "final";
  status: "proposed" | "planned" | "actual" | "certified";
  distribution: "ø" | "Internal" | "BBQ" | "No attrib" | "Public";
  notes: string;
  }

// APIPolicy contains an extended version of WorkingPolicy with additional data that the server can provide, but is not expected when saving the policy
export interface APIPolicy extends WorkingPolicy
  {
  id: number;
  user_id: number;
  tlds: APIRegistryList | null;
  modified: number; // unix timestamp
  versions: PolicyVersionReference[];
  }

export interface PolicyVersionReference
  {
  id: number;
  version: number;
  }

/////////////////////////////////
// TLD LIST types
/////////////////////////////////

export interface APIRegistryList
  {
  id: number;
  name: string;
  tlds: string[];
  }

// API Requests

export type GetTLDLists =
  {
  status: "success";
  tld_lists: APIRegistryList[];
  } | APIError;

export type PostTLDList =
  {
  status: "success";
  tld_list_id: number;
  } | APIError;

///////////////////////////////////
// REGISTRANT FACTS types
///////////////////////////////////

export interface APIRegistrantFacts
  {
  id: number;
  name: string;
  facts: APIRegistrantFactItem[];
  }

export interface APIRegistrantFactItem
  {
  element_id: number;
  value: string;
  V3RQ: string;
  DG: string;
  }

export interface NamedItem
  {
  id: number;
  name: string;
  };

// API Requests

export type PostRegistrantFacts =
  {
  status: "success",
  id: number;
  } | APIError;

export type GetRegistrantFacts =
  {
  status: "success";
  registrant_facts: APIRegistrantFacts;
  } | APIError;

export type GetRegistrantFactsList =
  {
  status: "success";
  registrants: NamedItem[];
  } | APIError;

///////////////////////////////////
// REQUEST TEMPLATE types
///////////////////////////////////

export type APIRequestTemplateRole = "user" | "administrator";

export interface APIRequestTemplate
  {
  id: number;
  name: string;
  attributes: APIRequestTemplateItem[];
  }

export interface APIRequestTemplateItem
  {
  attribute_id: number;
  value: string;
  role: APIRequestTemplateRole;
  required: boolean;
  }

export type APIMatchedRegistration =
  {
  registration: APIRegistration;
  matches: number[];
  }

// API Requests

export type PostRequestTemplate =
  {
  status: "success",
  id: number;
  } | APIError;

export type GetRequestTemplate =
  {
  status: "success";
  request_template: APIRequestTemplate;
  } | APIError;

export type GetRequestTemplates =
  {
  status: "success";
  request_templates: APIRequestTemplate[];
  } | APIError;

export type GetRegistrations =
  {
  status: "success";
  registrations: APIRegistration[];
  } | APIError;

export type GetMatchedRegistrations =
  {
  status: "success"
  registrations: APIMatchedRegistration[]
  } | APIError;

///////////////////////////////////
// REGISTRATION types
///////////////////////////////////

export interface APIRegistration
  {
  id: number;
  name: string;
  registrant_facts_id: number;
  policy_id: number;
  tlds: APIRegistryList | null;
  tld_list_id: number | null;
  scope_attributes: APIRuleAttribute[];
  }

// API Requests

export type PostRegistration =
  {
  status: "success",
  id: number;
  } | APIError;

export type GetRegistration =
  {
  status: "success";
  registration: APIRegistration;
  } | APIError;

///////////////////////////////////
// Registrar Group types
///////////////////////////////////

export interface APIRequester
  {
  id: number;
  name: string;
  }

export interface APIRequesterGroup
  {
  id: number;
  name: string;
  obligations: string;
  members: APIRequester[];
  }

export interface APIRequesterGroupMembership
  {
  requester_id: number;
  requester_group_id: number;
  deleted: boolean;
  }

export interface APIRegistrar
  {
  id: number;
  name: string;
  }

export interface APIRegistrarGroup
  {
  id: number;
  description: string;
  obligations: string;
  registrar_ids: number[];
  requester_group_ids: number[];
  request_template_ids: number[];
  }

export interface APIRegistrarGroupMembers
  {
  requesters: APIRequester[];
  requester_groups: APIRequesterGroup[];
  registrars: APIRegistrar[];
  registrar_groups: APIRegistrarGroup[];
  }

// API Requests

export type GetRegistrarGroupMembers =
  {
  status: "success";
  registrar_group_members: APIRegistrarGroupMembers;
  } | APIError;

export type GetRequesterGroupMembershipList =
  {
  status: "success";
  requester_group_memberships: APIRequesterGroupMembership[];
  } | APIError;

export type GetRegistrarGroups =
  {
  status: "success";
  registrar_groups: APIRegistrarGroup[];
  } | APIError;



///////////////////////////////////
// EXAMPLES
///////////////////////////////////

export const EXAMPLE_POLICY: APIPolicy =
  {
  id: 123,
  user_id: 1,
  name: "My example policy",
  tld_list_id: 1,
  tlds: {id: 5, name: "Best 3 TLDs", tlds: [".com", ".org", ".net"]},
  org_name: "Example Domains Inc.",
  org_type: "registrar",
  prime_poc: "Joe Smith",
  prime_email: "joe@example.com",
  alt_poc: "Jane Smith",
  alt_email: "jane@example.com",
  version: 2,
  effective_date: "2022-01-01",
  updated_date: "2021-01-01",
  modified: 1629232385,
  completion: "draft",
  status: "proposed",
  distribution: "BBQ",
  notes: "This is an example from the source code of this tool.",
  versions: [{id: 123, version: 2}, {id: 122, version: 1}],
  scope_attributes: [{attribute_id: 10, value: 1}, {attribute_id: 11, value: 2,}],
  rules: [{id: 600, element_id: 301, attributes: [{attribute_id: 100, value: 1}]}, {id: 601, element_id: 300, attributes: [{attribute_id: 101, value: 2}]},],
  subject_to: [],
  };
