/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const cssFooter = "d-print-none d-block m-0 px-0 py-5 border-top-1 border-top-light";
const cssContainer = "d-flex flex-row justify-content-between align-items-start gap-3";
const cssStyle = {background: 'linear-gradient(0deg, #f8f9faff, #E3E4E5ff)'};

function Footer ()
  {
  return <footer className={cssFooter} style={cssStyle}><Container className={cssContainer}>

    <ul><i className="fs-1 bi bi-book"></i>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/">User Manual: Quick Reference</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#about.md">About Project Jake</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#dashboard.md">Dashboard</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#policy.md">Policy Editor</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#requests.md">Request Templates Editor</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registration.md">Registration Editor</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Registrar Editors</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#psl.md">Registry List Editor</a></li>
      </ul>

    <ul><i className="fs-1 bi bi-info-circle"></i>
      <li><a className="link-secondary" href="https://eri-md.github.io/ERI-MD/changelog.html" target="_blank" rel="noreferrer">Changelog</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.edgemoorresearch.org/">Edgemoor Research Institute</a></li>
      <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.icann.org/rdrs-en">ICANN RDRS</a></li>
      <div className="mt-4"><small>2024 &copy; Edgemoor Research Institute</small></div>
      </ul>

    </Container></footer>
  }

export default Footer;
