/* NODE PACKAGES */
import React from 'react';
import {ButtonGroup, Dropdown, OverlayTrigger, Tooltip, Popover, Stack, ProgressBar} from'react-bootstrap';
/* STYLES */
import styles from './ChartBox.module.css';

/////////////////////////////////////////
// COMPARISON CHART
/////////////////////////////////////////

const styleComparisonChart = {width: '100%', height: '20px'};
function ChartBox (props: {id: number, className: string | undefined})
  {
  if (!props.className)
    {
    console.error('ComparisonChart: Data attribute undefined! Props: ', props);
    return null;
    }

  const cssClassName : string = `${styles[props.className] ?? "rule_dictionary"} bg-transparent shadow-sm`;
  return <ProgressBar max={100} style={styleComparisonChart}>
    <ProgressBar now={100} variant="dark" className={cssClassName} />
    </ProgressBar>
  }

export default ChartBox;