/* NODE PACKAGES */
import React from 'react';
import {ButtonGroup, Dropdown, OverlayTrigger, Tooltip, Popover, Stack, ProgressBar} from'react-bootstrap';
/* TYPES */
import { APIAttribute, APIAttributeOption, RULE_COMPARISON, NULL_OPTION} from 'common/api/types';
/* CUSTOM COMPONENTS */
import ChartBox from 'components/atoms/Inputs/ChartBox';
/* STYLES */
import CSS from './DataBox.module.css';

///////////////////////////////////////
// CALCULATE COMPARISONS
///////////////////////////////////////

function calculateComparison (ruleValue: number, comparisonValue: number): RULE_COMPARISON
  {
  switch (true)
    {
    case ((ruleValue                  ) === comparisonValue): return RULE_COMPARISON.EQUAL;
    case ((ruleValue | comparisonValue) === comparisonValue): return RULE_COMPARISON.SUBSET;
    case ((ruleValue | comparisonValue) === ruleValue      ): return RULE_COMPARISON.SUPERSET;
    default: return RULE_COMPARISON.INCOMPATIBLE;
    }
  }

///////////////////////////////////////
// CUSTOM TOGGLE
///////////////////////////////////////
/* Example: <Dropdown.Toggle as={CustomToggle} className={cssClassName}>{ruleAttribute?.name ?? ""}</Dropdown.Toggle> */

// type CustomToggleProps =
//   {
//   onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
//   className: string;
//   children?: React.ReactNode;
//   };

// const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>((props: CustomToggleProps, ref: React.ForwardedRef<HTMLAnchorElement>) =>
//   {
//   const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {e.preventDefault(); props.onClick(e);}
//   return <a href="" ref={ref} onClick={onClick} className="d-block w-100"><ProgressBar max={100} className={`${props.className} text-center bg-transparent`} label={`${props.children}`} style={{height: '30px', width: '100%'}} /></a>
//   });

///////////////////////////////////////
// DATA SELECT COMPONENT
///////////////////////////////////////
// Dropdown attribute selectbox: displays the attribute name and color; allows selecting between the attribute's values or alternate options; calls onChange callback when selection changes; changes styles based on comparison prop;

interface DataSelectBoxProps
  {
  attribute          : APIAttribute | undefined;   // The attribute to select a value for.
  onChange           : (newValue: number) => void; // Callback when the selected value changes.
  ruleValue?         : number | undefined;         // The currently selected attribute value.
  comparisonValue?   : number | undefined;         // The comparison type between this attribute and another.
  alternateDropdown? : APIAttributeOption[];       // Optional alternate dropdown options to display.
  }

const cssDataBoxBS    : string = `${CSS['data_attribute']} d-flex justify-content-between align-items-center gap-0 m-0 p-2 border-0 shadow-sm rounded-4 w-auto`;
const cssDataBoxText  : string = `${CSS['text_contrast']} m-0 px-1 py-0 text-center text-nowrap w-100 overflow-hidden`;
const cssMenuOptionBS : string = `${CSS['data_option']} m-0 p-2 border border-2 border-top border-white pointer`;
const cssMenuBS       : string = "border border-1 border-white m-0 p-0";
const cssFlexColumn   : string = "d-flex flex-column justify-content-start align-items-stretch gap-1";

function DataBox (props:DataSelectBoxProps)
  {
  if (props.attribute === undefined)
    {
    console.log('Undefined rule! Props: ', props);
    return null;
    }

  const attributeID   : number                 = props.attribute?.id ?? 0;
  const options       : APIAttributeOption[]   = props.alternateDropdown || props.attribute.values;
  const ruleOption    : APIAttributeOption     = options?.find((v) => v.value === props.ruleValue) ?? options[0];
  const compareOption : APIAttributeOption     = options?.find((v) => v.value === props.comparisonValue) ?? options[0];
  const comparison    : RULE_COMPARISON | null = (props.ruleValue === undefined || props.comparisonValue === undefined || ruleOption.value === 0 || compareOption.value === 0) ? null : calculateComparison(props.ruleValue, props.comparisonValue);
  const ruleToolTip   : string                 = ruleOption?.description ?? ruleOption?.name ?? "";
  const ruleCSS       : string                 = CSS[(props.comparisonValue === undefined) ? ruleOption?.className ?? "rule_dictionary" : comparison || RULE_COMPARISON.NULL];
  const label         : string                 = ruleOption?.name ?? "";

  return (
    <div className={cssFlexColumn}>
      <Dropdown id={`databox-${attributeID}`} as={ButtonGroup} size="sm" align="end" aria-description={ruleToolTip}>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id={`tt-${attributeID}`}>{ruleToolTip}</Tooltip>}>
          <Dropdown.Toggle variant="light" className={`${cssDataBoxBS} ${ruleCSS}`}><span className={cssDataBoxText}><small>{label}</small></span></Dropdown.Toggle>
          </OverlayTrigger>
        <Dropdown.Menu variant="light" className={cssMenuBS} aria-description='Data attribute options'>
          {options.map((opt: APIAttributeOption) => <Dropdown.Item key={opt.value} className={`${cssMenuOptionBS} ${CSS[opt.className]}`} active={(opt.value === ruleOption.value)} onClick={() => props.onChange(opt.value)}><span className={cssDataBoxText}><small>{opt.name}</small></span></Dropdown.Item>)}
          </Dropdown.Menu>
        </Dropdown>
      {props.comparisonValue !== undefined ? <ChartBox id={attributeID} className={ruleOption?.className} /> : null}
      {props.comparisonValue !== undefined ? <ChartBox id={attributeID} className={compareOption?.className} /> : null}
      </div>
    );
  };

export default React.memo(DataBox);

/////////////////////////////////////////
// DEVELOPER NOTES
/////////////////////////////////////////

/*

ALTERNATE SOLUTION FOR ADDING TEXT LABELS:

<div class="stripes-4-colors">
  <span class="label">Red</span>
  <span class="label">Green</span>
  <span class="label">Blue</span>
  <span class="label">Yellow</span>
  </div>

.stripes-4-colors
  {
  position: relative;
  width: 400px;
  height: 100px;
  background: linear-gradient(to right, #ff0000 0%, #ff0000 25%, #00ff00 25%, #00ff00 50%, #0000ff 50%, #0000ff 75%, #ffff00 75%, #ffff00 100%);
  }

.label
  {
  position: absolute;
  font-size: 10px;
  color: white;
  text-align: center;
  width: 25%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  }

.label:nth-child(1)
  {
  left: 0;
  }

.label:nth-child(2)
  {
  left: 25%;
  }

.label:nth-child(3)
  {
  left: 50%;
  }

.label:nth-child(4)
  {
  left: 75%;
  }

*/

