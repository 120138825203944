/* NODE PACKAGES */
import {ButtonGroup, Dropdown, DropdownToggle, OverlayTrigger, Tooltip} from 'react-bootstrap';

/////////////////////////////////////////
// FILE MENU DIVIDER
/////////////////////////////////////////

export function FileMenuDivider ()
  {
  return <Dropdown.Divider />;
  }

/////////////////////////////////////////
// FILE MENU ITEM: GENERIC
/////////////////////////////////////////

const cssDropdownItem: string = "d-flex justify-content-between align-items-center gap-2 lh-base";

// File Menu Item: Generic
interface FileMenuItemProps
  {
  children: React.ReactNode,
  onClick: () => void,
  href?: string,
  className?: string,
  disabled?: boolean,
  }

export function FileMenuItem (props: FileMenuItemProps)
  {
  return <Dropdown.Item className={`${cssDropdownItem} ${props.className ?? ''}`} onClick={props.onClick}>{props.children}</Dropdown.Item>
  }

/////////////////////////////////////////
// FILE MENU ITEMS: SPECIAL CASES
/////////////////////////////////////////

export function FileMenuSave (props: {onClick?: () => void})
  {
  return <Dropdown.Item className={cssDropdownItem} onClick={props.onClick}>Save <i className="bi bi-floppy"></i></Dropdown.Item>
  }

export function FileMenuDelete (props: {onClick?: () => void})
  {
  return <Dropdown.Item className={`${cssDropdownItem} text-danger`} onClick={props.onClick}>Delete <i className="bi bi-trash3"></i></Dropdown.Item>
  }

export function FileMenuCopy (props: {onClick?: () => void})
  {
  return <Dropdown.Item className={cssDropdownItem} onClick={props.onClick}>Copy <i className="bi bi-copy"></i></Dropdown.Item>
  }

export function FileMenuImport (props: {onClick?: () => void})
  {
  return <Dropdown.Item className={cssDropdownItem} onClick={props.onClick}>Import <i className="bi bi-database-up"></i></Dropdown.Item>
  }

export function FileMenuExport (props: {onClick?: () => void})
  {
  return <Dropdown.Item className={cssDropdownItem} onClick={props.onClick}>Export <i className="bi bi-database-down"></i></Dropdown.Item>
  }

export function FileMenuPrint ()
  {
  return <Dropdown.Item className={cssDropdownItem} onClick={() => window.print()}>Print <i className="bi bi-printer"></i></Dropdown.Item>
  }

export function FileMenuHelp (props: {href?: string})
  {
  return <Dropdown.Item className={`${cssDropdownItem} text-info`} target="_blank" rel={"noreferrer"} href={props.href ?? "https://eri-md.github.io/ERI-MD/"}>Help <i className="bi bi-question-circle"></i></Dropdown.Item>
  }

/////////////////////////////////////////
// FILE MENU
/////////////////////////////////////////

interface FileMenuProps
  {
  children?: React.ReactNode,
  dirtyFlag?: boolean,
  savingFlag?: boolean,
  }

const ttText: string = "File Options, e.g. Save, Edit, Delete, and more";
function FileMenu (props : FileMenuProps)
  {
  console.log("FileMenu:props.dirtyFlag", props.dirtyFlag);
  return (!props)
    ? null
    : <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{ttText}</Tooltip>}>
        <Dropdown as={ButtonGroup} align="end" id="dropdown-basic">
          <DropdownToggle variant={props.dirtyFlag ? "outline-dark" : "dark"} className="border border-2 border-dark"><i className="bi bi-three-dots"></i></DropdownToggle>
          <Dropdown.Menu variant='dark' flip={false}>{props.children}</Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
  }

export default FileMenu;

/* Developer Notes:

<FileMenuItem onClick={props.eventImport}> Import Data <i className="bi bi-database-up"></i></FileMenuItem>
<FileMenuItem onClick={props.eventExport}> Export Data <i className="bi bi-database-down"></i></FileMenuItem>

*/
