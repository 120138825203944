/* NODE PACKAGES */
import React from "react";
import {Alert, ListGroup, ListGroupItem, Pagination, Spinner, Badge, Stack, Container, Form, InputGroup, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import { PolicyListItem, } from 'common/api/types';

const PAGE_SIZE = 250;

export interface IndexListItem
  {
  name: string;
  href?: string;
  onSelect?: () => void;
  }

const CreateButton: React.FC<{eventClick: () => void}> = (props) =>
  {
  const [hover, setHover] = React.useState<boolean>(false);

  const cssHoverButton = React.useMemo (() => hover ? "rounded-circle m-0 p-0 border-0 bg-transparent text-dark text-opacity-100" : "rounded-circle m-0 p-0 border-0 bg-transparent text-dark text-opacity-50", [hover]);
  const eventHover = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => setHover(true), []);
  const eventBlur = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => setHover(false), []);

  return <span className={cssHoverButton} onMouseOver={eventHover} onMouseOut={eventBlur} onClick={props.eventClick}><i className="bi bi-plus-circle-fill"></i></span>
  }

const IndexPages: React.FC<{index:IndexListItem[], update:(partialList:any[]) => void}> = (props) =>
  {
  const [active, setActive] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(PAGE_SIZE);
  const [pages, setPages] = React.useState<number>(0);

  React.useEffect(() =>
    {
    setPages(Math.ceil(props.index.length/pageSize));
    }, [props.index]);

  React.useEffect(() =>
    {
    props.update(props.index.slice(active*pageSize, (active+1)*pageSize));
    }, [active, props.index, pageSize]);

  React.useEffect(() =>
    {
    setPages(Math.ceil(props.index.length/pageSize));
    }, [pageSize]);

  const generatePagination = React.useMemo(() =>
    {
    const pagination = [];

    if (props.index.length > PAGE_SIZE)
      {
      //pagination.push(<Pagination.First key={'pg_first'} onClick={() => setActive(0)} />);
      pagination.push(<Pagination.Prev key={'pg_prev'} onClick={() => setActive(Math.abs((active - 1 + pages) % (pages)))} />);
      for (let i = 0; i < pages; i++) pagination.push(<Pagination.Item key={`pg_${i}`} active={(i === active) ? true : false} onClick={() => setActive(i)}>{i+1}</Pagination.Item>);
      pagination.push(<Pagination.Next key={'pg_next'} onClick={() => setActive((active + 1) % (pages))} />);
      //pagination.push(<Pagination.Last key={'pg_last'} onClick={() => setActive(pages - 1)} />);
      }
    else
      {
      pagination.push(<Pagination.Item key={'pg_0'} active={true} onClick={() => setActive(0)}>{1}</Pagination.Item>);
      }

    return pagination;
    }, [active, pages]);

  return <Pagination className="mx-auto my-0 p-0">{generatePagination}</Pagination>
  }

const IndexHeader: React.FC<{title?: string, count?: number, eventCreate?: () => void}> = (props) =>
  {
  const Title = React.useMemo(() => props.title ? <span>{`${props.title} (${props.count})`}</span> : <span>{`Index Listing (${props.count})`}</span>, [props.title]);
  const uiCreateButton = React.useMemo(() => props.eventCreate ? <CreateButton eventClick={props.eventCreate} /> : null, [props.eventCreate]);
  //const title = React.useMemo(() => props.title ? <span>{props.title}</span> : null, [props.title]);

  return (<div className="d-flex justify-content-between align-items-center gap-2 m-0 px-3 py-0 fs-5 lh-1 fw-bold text-dark">{Title} {uiCreateButton}</div>);
  }

const IndexListing: React.FC<{data:IndexListItem[]}> = (props) =>
  {
  const [list, setList] = React.useState<IndexListItem[]>(props.data);
  const [filterList, setFilterList] = React.useState<IndexListItem[]>([]);
  const [filter, setFilter] = React.useState<string>('');

  React.useEffect(() =>
    {
    if (!list) return;
    filterData(list);
    }, [list]);

  React.useEffect(() =>
    {
    filterData(list ?? []);
    }, [filter]);

  const filterData = (list: IndexListItem[]) =>
    {
    if (!list) return;
    const filteredData = list.filter((item) => Object.values(item).join(' ').toLowerCase().includes(filter.toLowerCase()));
    setFilterList(filteredData);
    }

  const eventUpdateFilter = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => setFilter(e.target.value);
  const eventClearFilter = () => setFilter("");
  const eventExecuteFilter = (e: React.KeyboardEvent<HTMLInputElement>) => { if (list && e.key === 'Enter') { e.preventDefault(); filterData(list ?? []); e.currentTarget.blur(); } };
  const eventClickSearch = () => filterData(list ?? []);

  const cssClearButton = "position-absolute z-3 top-50 start-100 border-0 translate-middle bg-transparent text-danger align-middle lh-1";
  const styleClearButton = {translate: '-3.5em 0.0em'};

  const eventUpdatePagination = React.useCallback((partialList: any[]) => setList(partialList), []);

  const indexList = React.useMemo(() =>
    {
    const cssListItem = "d-flex justify-content-between align-items-center lh-sm";
    return filterList.length > 0 ? filterList.map((item: IndexListItem, index) => <ListGroupItem variant="light" action href={item.href} className={cssListItem} key={`${index}`}>{item.name}</ListGroupItem>) : <ListGroupItem variant="light" className={cssListItem}>No results found.</ListGroupItem>;
    }, [filterList]);

  return ((!list || !props)
    ? <Alert variant="warning">{"Data is not available at this time."}</Alert>
    : <Stack gap={3}>
      <Stack direction="horizontal" gap={3}>
        <InputGroup className="position-relative m-0 p-0">
          <Form.Control placeholder="Search..." value={filter} onKeyDown={eventExecuteFilter} onChange={eventUpdateFilter} aria-label="Search Data" aria-describedby="search-bar" />
          <Button variant="outline-primary" id="clear-button" disabled={filter === ''} onClick={eventClearFilter} className={cssClearButton} style={styleClearButton}><i className="bi bi-x fs-6"></i></Button>
          <Button variant="dark" id="search-button" disabled={filter === ''} onClick={eventClickSearch}><i className="bi bi-search"></i></Button>
          </InputGroup>
        </Stack>
      <ListGroup style={{boxShadow: "var(--bs-box-shadow-sm)"}}>{indexList}</ListGroup>
      <IndexPages index={props.data} update={eventUpdatePagination} />
      </Stack>);
  }

export default React.memo(IndexListing);


/*
Developer List:


*/
