/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { APIRequestTemplate } from 'common/api/types';
/* HOOKS */
import {useAxios} from 'hooks';

export type RequestTemplateListStore =
  {
  readonly loading: boolean;
  readonly error:any;
  readonly data: APIRequestTemplate[] | null;
  readonly count: number;
  readonly refresh: () => void;
  };

function useRequestTemplateListStore(): RequestTemplateListStore
  {
  const [response, error, loading, callAxios] = useAxios({ method: 'GET', url: "/api/request_templates", });
  const [data, setData] = React.useState<APIRequestTemplate[]>([]);

  React.useEffect(() =>
    {
    if (response) setData(response.request_templates?.sort((a:APIRequestTemplate, b:APIRequestTemplate) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  const refresh = () => callAxios();

  return {loading, error, data, count: (data?.length ?? 0), refresh};
  }

export default useRequestTemplateListStore;


