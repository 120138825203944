/* NODE */
import React from 'react';
import {Spinner, Form, Container, Row, Col, Dropdown, DropdownItem, DropdownButton, InputGroup, Modal, Button, ButtonGroup, Stack, DropdownDivider} from 'react-bootstrap';
/* TYPES */
import { NamedItem, APIDictionary, APIRegistration, PolicyListItem, APIPolicy, APIRegistryList, APIRuleAttribute, RULE_COMPARISON, APITypedAttribute, APIAttribute} from 'common/api/types';
import {newRegistrantFacts, saveRegistrantFacts, deleteRegistrantFacts} from 'common/api/requests';
/* HOOKS */
import {usePolicyListStore, PolicyListStore, PolicyStore, RegistrationStore, RegistrantFactsStore, useRegistrantFactsListStore, RegistrantFactsListStore, DataDictionaryStore, useApplicationContext, AppContextType} from 'hooks';
/* CUSTOM COMPONENTS */
import Scope from 'components/molecules/Toolbars/Scope';
import { set } from 'lodash';

///////////////////////////////////////
// SCOPE CALCULATOR
///////////////////////////////////////

function ScopeCalculator (props: { registration: RegistrationStore; })
  {
  const application: AppContextType = useApplicationContext();

  return (!props.registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Container>
        <Row>
          <Col>
            <Stack direction="vertical" gap={3}>
              <h6 className="text-center text-muted fw-medium m-0 p-0 lh-1">Registrant Scope:</h6>
              <InputGroup>
                <FactsListOptions registration={props.registration} />
                <FactsListSelector registration={props.registration} />
                </InputGroup>
              <Scope
                // scopeAttributes={application.dictionary.data?.scope_attributes.map((att: APIAttribute) => ({...att, values: att.values.filter((val) => props.registration.singletonScopes.includes(val.value))}))}
                scopeAttributes={application.dictionary.data?.scope_attributes ?? []}
                selectedScopeAttributes={props.registration.data?.scope_attributes ?? []}
                comparisonScopeAttributes={props.registration.policy.data?.scope_attributes ?? []}
                ruleRegistryScope={props.registration.data?.tlds ?? null}
                comparisonRegistryScope={props.registration.policy.data?.tlds ?? null}
                defaultDropdown={1}
                onAttributeChange={(newScopeAtts: APIRuleAttribute[]) => props.registration.updateData({ scope_attributes: newScopeAtts})}
                onRegistryListChange={(newTLDList: APIRegistryList | null) => props.registration.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null })}
                />
              </Stack>
            </Col>
          <Col>
            <Stack direction="vertical" gap={3}>
              <h6 className="text-center text-muted fw-medium m-0 p-0 lh-1">Policy Scope:</h6>
              <InputGroup>
                <PolicyListOptions registration={props.registration} />
                <PolicyListSelector registration={props.registration} />
                </InputGroup>
              <Scope
                scopeAttributes={application.dictionary.data?.scope_attributes ?? []}
                selectedScopeAttributes={props.registration.policy.data?.scope_attributes ?? []}
                ruleRegistryScope={props.registration.policy.data?.tlds ?? null}
                defaultDropdown={0}
                onAttributeChange={(newScopeAtts: APIRuleAttribute[]) => props.registration.policy.updateData({ scope_attributes: newScopeAtts })}
                onRegistryListChange={(newTLDList: APIRegistryList | null) => props.registration.policy.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null})}
                />
              </Stack>
            </Col>
          </Row>
        </Container>
  }

export default ScopeCalculator;

///////////////////////////////////////
// POLICY LIST OPTIONS (DROPDOWN)
///////////////////////////////////////

function PolicyListOptions(props: { registration: RegistrationStore; })
  {
  return (
    <DropdownButton variant="dark" title="Options" id="policy-scope-dropdown">
      <DropdownItem href={`/#/policy/${props.registration.policy.data?.id}`} disabled={(props.registration.policy.data?.id === -1)}>View in Policy Editor</DropdownItem>
      <DropdownItem onClick={props.registration.eventCopyDefaults} disabled={!props.registration.policy.data}>Copy Defaults</DropdownItem>
      </DropdownButton>
    );
  }

///////////////////////////////////////
// EDIT REGISTRANT FACT MODAL
///////////////////////////////////////

export function EditRegistrantFactModal(props: { show: boolean; registrantFacts: RegistrantFactsStore; handleClose: () => void; handleShow: () => void; handleSave: (name: string) => void; })
  {
  const [name, setName] = React.useState("");

  React.useEffect(() =>
    {
    setName(props.registrantFacts.data?.name ?? "");
    }, [props.registrantFacts.data]);

  return (
    <Modal show={props.show} onHide={props.handleClose} centered={true} autoFocus={true}>
      <Modal.Header closeButton><Modal.Title>Set Registrant Fact Name:</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
          </Form.Group>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>Close</Button>
        <Button variant="primary" onClick={() => props.handleSave(name)}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }

///////////////////////////////////////
// FACTS LIST OPTIONS (DROPDOWN)
///////////////////////////////////////

function FactsListOptions (props: {registration: RegistrationStore})
  {
  const [showEditFactModal, setShowEditFactModal] = React.useState(false);
  function handleClose ()
    {
    setShowEditFactModal(false);
    }

  function handleShow ()
    {
    setShowEditFactModal(true);
    }

  function onEditFactModal (name:string)
    {
    const hasChanged = Boolean(name !== props.registration.registrantFacts.data?.name);
    if (props.registration.registrantFacts.data && hasChanged) props.registration.registrantFacts.updateData({name: name ?? "Untitled Registrant Fact"});
    handleClose();
    }

  function eventAddNewRegistrantFact ()
    {
    newRegistrantFacts()
    .then((id: number) =>
      {
      props.registration.updateData({ registrant_facts_id: id });
      })
    .finally(() =>
      {
      setShowEditFactModal(true);
      });
    }

  function eventDeleteRegistrantFact ()
    {
    if (props.registration.registrantFacts.data?.id) deleteRegistrantFacts(props.registration.registrantFacts.data?.id).finally(() =>
      {
      props.registration.updateData({ registrant_facts_id: -1 });
      });
    }

  return (
    <React.Fragment>
      <DropdownButton variant="dark" title="Options" id="registrantFacts-dropdown">
        <Dropdown.Item onClick={eventAddNewRegistrantFact}><i className="bi bi-plus"></i> Create </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowEditFactModal(true)}>Edit</Dropdown.Item>
        <Dropdown.Item onClick={eventDeleteRegistrantFact}>Delete</Dropdown.Item>
        </DropdownButton>
      <EditRegistrantFactModal show={showEditFactModal} registrantFacts={props.registration.registrantFacts} handleClose={handleClose} handleShow={handleShow} handleSave={onEditFactModal} />
      </React.Fragment>
    );
  }

///////////////////////////////////////
// FACTS LIST (SELECT)
///////////////////////////////////////

function FactsListSelector (props: {registration: RegistrationStore})
  {
  const [factsID, setFactsID] = React.useState<number>(props.registration.data?.registrant_facts_id ?? -1);
  const registrantFactsList:RegistrantFactsListStore = useRegistrantFactsListStore();

  React.useEffect(() => props.registration.updateData({ registrant_facts_id: factsID }), [factsID]);

  React.useEffect(() => console.log(`Facts List: ${registrantFactsList.getByID(factsID)?.name}`), [props.registration.registrantFacts.data?.id]);

  function eventChangeFacts (e: React.ChangeEvent<HTMLSelectElement>)
    {
    setFactsID(parseInt(e.target.value ?? -1))
    }

  return ((!registrantFactsList.data)
    ? <Spinner animation="border" variant="secondary" size="sm" />
    : <Form.Select value={factsID} onChange={eventChangeFacts}>
        <option value={-1}>{"Choose a registrant fact..."}</option>
        {registrantFactsList.data?.map((d: NamedItem, i: number) => <option key={`registrantFact-${d.name}${i}`} value={d.id}>{d.name}</option>)}
        </Form.Select>
    );
  }

///////////////////////////////////////
// POLICY LIST (SELECT)
///////////////////////////////////////

function PolicyListSelector (props: {registration: RegistrationStore})
  {
  const [policyID, setPolicyID] = React.useState<number>(props.registration.data?.policy_id ?? -1);
  const policyList:PolicyListStore = usePolicyListStore();

  React.useEffect(() =>
    {
    const item = policyList.getByID(policyID);
    const exists = item ? policyList.data?.map((item: PolicyListItem) => item.id).includes(policyID) : false;
    console.log(`PolicyList = Policy ID: ${policyID}, ${policyID}, ${exists}`, item ?? policyList.data);
    if (!exists) props.registration.updateData({ policy_id: -1 });
    }, [policyID]);

  React.useEffect(() =>
    {
    props.registration.updateData({ policy_id: policyID });
    }, [policyID]);

  function eventChangePolicy (e: React.ChangeEvent<HTMLSelectElement>)
    {
    const input = parseInt(e.target.value);
    setPolicyID(input);
    }

  return ((!policyList.data)
    ? <Spinner animation="border" variant="secondary" size="sm" />
    : <Form.Select value={policyID} onChange={eventChangePolicy}>
        <option value={-1}>{"Choose a policy..."}</option>
        {policyList.data?.map((policy: PolicyListItem, i: number) => <option key={`policy-${policy.id}-${i}`} value={policy.id}>{policy.name} {`(v.${policy.version})`}</option>)}
        </Form.Select>
    );
  }

