/* NODE PACKAGES */
import React, {/*Profiler*/} from 'react';
import {Spinner, Button, Badge, Container, Row, Col, Stack} from 'react-bootstrap';

/* CUSTOM API */
import { NamedItem, APIDictionary, PolicyListItem,} from 'common/api/types';
import {DataDictionaryStore, usePolicyListStore, PolicyListStore, usePolicyStore, PolicyStore, useRegistrationStore, RegistrationStore, useRegistrantFactsStore, RegistrantFactsStore, useRegistrantFactsListStore, RegistrantFactsListStore, RegistryListStore, useRegistryListStore, useApplicationContext, AppContextType} from 'hooks';
/* CUSTOM COMPONENTS */
import RegistrationDataTable from 'pages/Registrations/modules/RegistrationDataTable';
import RegistrationDataPrinter from 'pages/Registrations/modules/RegistrationDataPrinter';
import ScopeCalculator from 'pages/Registrations/modules/ScopeCalculator';
import ColorKey from 'components/molecules/Sheet/ColorKey';
import FileMenu, {FileMenuDivider, FileMenuHelp, FileMenuSave, FileMenuDelete, FileMenuPrint} from 'components/molecules/Menu/File';
import {Page, Header, Main, Section, Footer, Print} from "components/atoms/Templates";

///////////////////////////////////////
// REGISTRATION EDITOR
///////////////////////////////////////

interface RegistrationEditorProps
  {
  registrationID: number;
  unsavedChanges: (flag: boolean) => void;
  }

function RegistrationEditor(props: RegistrationEditorProps)
  {
  // DATA/STATE MANGEMENT (CUSTOM HOOKS)
  const registration:RegistrationStore = useRegistrationStore({registrationID: props.registrationID});
  // const policy:PolicyStore = usePolicyStore({selectedPolicyID: registration.data?.policy_id ?? -1});
  // const registrantFacts:RegistrantFactsStore = useRegistrantFactsStore({registrantFactsID: registration.data?.registrant_facts_id ?? -1});
  const application: AppContextType = useApplicationContext()

  // React.useEffect(() =>
  //   {
  //   if (!registration.data?.name) return;
  //   const registrationName = (registrantFacts.data?.name || policy.data?.name) ? ((registrantFacts.data?.name ?? "*") + (" | ") + (policy.data?.name ?? "*")) : "Untitled Registration";
  //   console.log("RegistrationEditor:registrationName", registrantFacts.data?.name, policy.data?.name, registrationName);
  //   }, [registrantFacts.data?.name, policy.data?.name]);

  // RENDER
  return ((!registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{registration.title}</span>
          <div className="ms-auto"></div>
          <Button variant="outline-dark" className="m-0 p-2 lh-1 text-nowrap" href={`/#/registration_alpha/${registration?.data?.id}`}>{"Editor v.1"}</Button>
          <FileMenu dirtyFlag={application.unsavedChanges.current}>
            <FileMenuSave onClick={registration.eventSave} />
            <FileMenuDelete onClick={registration.eventDelete} />
            <FileMenuDivider />
            <FileMenuPrint />
            <FileMenuHelp href="https://eri-md.github.io/ERI-MD/#registration.md" />
            </FileMenu>
          </Header>
        <Main>
          <Section>
            {/* <Profiler id="ScopeCalculator" onRender={onRender}> */}
            <ScopeCalculator registration={registration} />
            {/* </Profiler> */}
            </Section>
          <Section>
            <Row className="mb-3">
              <Col className="d-flex justify-content-end align-items-end gap-3">
                <ColorKey />
                </Col>
              </Row>
            <Row>
              <Col>
                {/* <Profiler id="DataTable" onRender={onRender}> */}
                <RegistrationDataTable registration={registration} />
                {/* </Profiler> */}
                </Col>
              </Row>
            </Section>
          </Main>
        <Footer />
        <Print>
          <RegistrationDataPrinter registration={registration} />
          </Print>
        </Page>);

  // function onRender (id:any, phase:any, actualDuration:any, baseDuration:any, startTime:any, commitTime:any)
  //   {
  //   console.groupCollapsed("Profiler: ", id)
  //   console.log("phase: ", phase);
  //   console.log("actualDuration(s): ", actualDuration / 1000);
  //   console.log("baseDuration(s): ", baseDuration / 1000);
  //   console.log("startTime(timestamp): ", startTime);
  //   console.log("commitTime(timestamp): ", commitTime);
  //   console.log("time difference(s): ", (commitTime - startTime)/1000);
  //   console.groupEnd();
  //   }
  }

export default RegistrationEditor;


