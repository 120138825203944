/* NODE PACKAGES */
import React from 'react';
import _, { set } from 'lodash';
import { AxiosRequestConfig } from 'axios';
/* API */
import { APIDictionary, APIRegistrantFacts, APIRegistrantFactItem, APIRegistration, APIPolicy, APIElement, APIElementGroup, APIRule, RULE_ATTRIBUTES} from 'common/api/types';
import {getRuleForElement, getRegistrantFacts, saveRegistrantFacts} from 'common/api/requests';
/* HOOKS */
import {useAxios, DataDictionaryStore, RegistrationStore, useApplicationContext, AppContextType} from 'hooks';

// RegistrantFacts

export type RegistrantFactsStore =
  {
  data: APIRegistrantFacts | null;
  unsavedChanges: boolean;
  updateFacts: (factsID: number) => void;
  updateData: (newValues: Partial<APIRegistrantFacts>) => void;
  clearData: () => void;
  eventSave: () => void;
  eventCopyDefaults: (policy: APIPolicy | null) => void;
  getRegistrantFact: (elementID: number) => APIRegistrantFactItem | undefined;
  updateFact: (el: APIElement, newValue: Partial<APIRegistrantFactItem>) => void;
  };

interface RegistrantFactsProps
  {
  registrantFactsID: number;
  }

function useRegistrantFactsStore(props: RegistrantFactsProps)
  {
  const [response, error, loading, requestAPI] = useAxios();
  const [data, setData] = React.useState<APIRegistrantFacts | null>(null);
  const application = useApplicationContext();

  React.useEffect(() =>
    {
    setData(response?.registrant_facts ?? null);
    }, [response]);

  React.useEffect(() =>
    {
    application.unsavedChanges.current = true;
    }, [data]);

  function updateFacts (factsID: number = props.registrantFactsID ?? -1)
    {
    (factsID === -1) ? setData(null) : requestAPI({ method: 'GET' , url: `/api/registrant_facts/${factsID}`});
    }

  function updateData (newValues: Partial<APIRegistrantFacts>)
    {
    if (!data) return;
    const newRegistration = Object.assign({}, data, newValues);
    setData(newRegistration as APIRegistrantFacts);
    }

  function clearData ()
    {
    setData(null);
    }

  function eventSave ()
    {
    if (data) saveRegistrantFacts(data).finally(() => {application.unsavedChanges.current = false;});
    }

  function eventCopyDefaults (policy: APIPolicy | null)
    {
    const RULE_NULL = 15;

    let newFacts: APIRegistrantFactItem[] | undefined = application?.dictionary?.element_groups_flat?.map(([eg, el]: [APIElementGroup | null, APIElement]) =>
      {
      if (data && policy)
        {
        const rule: APIRule | undefined = getRuleForElement(policy, el.id);
        if (rule)
          {
          let V3RQ: number = rule.attributes?.filter(a => a.attribute_id === RULE_ATTRIBUTES.V3RQ)[0]?.value || RULE_NULL;
          let DG: number = rule.attributes?.filter(a => a.attribute_id === RULE_ATTRIBUTES.DG)[0]?.value || RULE_NULL;
          let currentValue: string = data.facts?.find(rf => rf.element_id === el.id)?.value ?? el.name;

          if (V3RQ !== RULE_NULL || DG !== RULE_NULL)
            {
            const newFactObject: APIRegistrantFacts = _.clone(data);
            newFactObject.facts = newFactObject.facts?.slice() || [];
            const newFact: APIRegistrantFactItem = {element_id: el.id, value: currentValue, V3RQ: String(V3RQ), DG: String(DG),};
            return newFact;
            }
          }
        }
      }).flat().filter((x: APIRegistrantFactItem | undefined): x is APIRegistrantFactItem => x !== undefined);

    if (data && newFacts) updateData({facts: newFacts});
    }

  function getRegistrantFact (elementID: number): APIRegistrantFactItem | undefined
    {
    return data?.facts?.find(rf => rf.element_id === elementID);
    }

  function updateFact (el: APIElement, newValues: Partial<APIRegistrantFactItem>)
    {
    if (!data) return;
    const existingFactIndex:number         = data.facts?.findIndex((rf:APIRegistrantFactItem) => rf.element_id === el.id) ?? -1;
    const newFacts:APIRegistrantFactItem[] = data.facts?.slice();
    const newFact: APIRegistrantFactItem   = (existingFactIndex !== -1) ? Object.assign({}, data.facts[existingFactIndex], newValues) : Object.assign({}, { element_id: el.id, value: el.name ?? "", V3RQ: "15", DG: "15" }, newValues);

    if (existingFactIndex !== -1)
      {
      newFacts[existingFactIndex] = newFact;
      }
    else
      {
      newFacts.push(newFact);
      }

    updateData({facts: newFacts});
    }

  return (
    {
    data: data ?? null,
    unsavedChanges: application.unsavedChanges.current,
    updateFacts,
    updateData,
    clearData,
    eventSave,
    eventCopyDefaults,
    getRegistrantFact,
    updateFact
    }) as RegistrantFactsStore;
  }

export default useRegistrantFactsStore;
